import React from 'react';

const FullScreenIcon: React.FC = (): JSX.Element => {
  return (
    <svg
      width='23'
      height='17'
      viewBox='0 0 23 17'
      xmlns='http://www.w3.org/2000/svg'
      fill='currentColor'
    >
      <path
        d='M18.1458 12.7292H15.125C14.953 12.7292 14.8094 12.7869 14.694 12.9023C14.5785 13.0177 14.5208 13.1614 14.5208 13.3334C14.5208 13.5053 14.5785 13.649 14.694 13.7644C14.8094 13.8798 14.953 13.9375 15.125 13.9375H18.3782C18.6586 13.9375 18.8914 13.845 19.0765 13.6598C19.2616 13.4747 19.3542 13.2419 19.3542 12.9615V9.70835C19.3542 9.53639 19.2965 9.39271 19.181 9.27731C19.0656 9.16189 18.922 9.10419 18.75 9.10419C18.578 9.10419 18.4344 9.16189 18.319 9.27731C18.2035 9.39271 18.1458 9.53639 18.1458 9.70835V12.7292ZM4.85417 4.27085H7.875C8.04697 4.27085 8.19065 4.21314 8.30604 4.09773C8.42146 3.98233 8.47917 3.83865 8.47917 3.66669C8.47917 3.49472 8.42146 3.35104 8.30604 3.23564C8.19065 3.12023 8.04697 3.06252 7.875 3.06252H4.6218C4.34141 3.06252 4.10865 3.15508 3.92351 3.3402C3.73839 3.52533 3.64583 3.7581 3.64583 4.03849V7.29169C3.64583 7.46365 3.70354 7.60733 3.81896 7.72273C3.93435 7.83815 4.07803 7.89585 4.25 7.89585C4.42197 7.89585 4.56565 7.83815 4.68104 7.72273C4.79646 7.60733 4.85417 7.46365 4.85417 7.29169V4.27085ZM2.57691 16.9584C2.02078 16.9584 1.55642 16.7721 1.18385 16.3995C0.811285 16.0269 0.625 15.5626 0.625 15.0064V1.9936C0.625 1.43746 0.811285 0.973111 1.18385 0.600541C1.55642 0.227972 2.02078 0.041687 2.57691 0.041687H20.4231C20.9792 0.041687 21.4436 0.227972 21.8161 0.600541C22.1887 0.973111 22.375 1.43746 22.375 1.9936V15.0064C22.375 15.5626 22.1887 16.0269 21.8161 16.3995C21.4436 16.7721 20.9792 16.9584 20.4231 16.9584H2.57691ZM2.57691 15.75H20.4231C20.609 15.75 20.7794 15.6726 20.9343 15.5177C21.0892 15.3627 21.1667 15.1923 21.1667 15.0064V1.9936C21.1667 1.80772 21.0892 1.63731 20.9343 1.48238C20.7794 1.32747 20.609 1.25002 20.4231 1.25002H2.57691C2.39103 1.25002 2.22062 1.32747 2.0657 1.48238C1.91079 1.63731 1.83333 1.80772 1.83333 1.9936V15.0064C1.83333 15.1923 1.91079 15.3627 2.0657 15.5177C2.22062 15.6726 2.39103 15.75 2.57691 15.75Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default FullScreenIcon;
