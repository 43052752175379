import React from 'react';
import * as Styled from './styles';
import { useAppDispatch, useAppSelector, useBrowserStore } from 'shared/hooks';
import { openSidebar } from 'services/store/reducers/sidebarReducer';
import {
  ModalLogin,
  ModalQuickGuide,
  ModalCreateAccount,
  ProjectLogo
} from 'shared/components';
import { showModal } from 'services/store/reducers/modalReducer';
import { useHistory } from 'react-router-dom';
import { clearViewerDataState } from 'services/store/reducers/viewerDataReducer';
import {
  BurgerMenuIcon,
  LoginIcon,
  NewModelIcon,
  QuickViewIcon,
  SignUpIcon
} from 'assets/dynamic-icons';
import Logo from 'assets/images/logo.png';
import { clearModelLocalStorage } from 'utils/storage-utils';
import { RootState } from 'services/store';
import { ScheduledMaintenancePanel } from './components/scheduled-maintenance-panel';
import { CustomTooltip } from 'shared/components';
import { NavButton } from './components/nav-button';

const Header = (): React.ReactElement => {
  const isScheduledMaintenance = false;
  const dispatch = useAppDispatch();
  const history = useHistory();
  const store = useAppSelector((state): RootState => state);
  const { isAuth } = store.auth;
  const { brandColors, brandLogoUrl } = store.branding.activeBranding;
  const { removeFilesFromBrowserStore } = useBrowserStore();
  const { headerTextColor } = brandColors;
  const quickViewTooltip = 'Use Quick View to view a model without auto-saving it to your account';
  const uploadModelTooltip = 'Use Upload for Sharing to add and save a new model to your account';

  const handleLogoClick = (): void => {
    dispatch(clearViewerDataState());
    removeFilesFromBrowserStore();
    clearModelLocalStorage();
    history.push('/');
  };

  const handleSidebarButtonClick = (): void => {
    dispatch(openSidebar());
  };

  const handleLoginButtonClick = (): void => {
    dispatch(showModal(<ModalLogin />));
  };

  const handleQuickGuideClick = (): void => {
    dispatch(showModal(<ModalQuickGuide />));
  };

  const handleQuickViewClick = (): void => {
    history.push('/quick-view');
    history.go(0);
  };

  const handleUploadClick = (): void => {
    if (isAuth) {
      history.push('/upload');
      history.go(0);
    } else {
      dispatch(showModal(<ModalLogin />));
    }
  };

  const handleSignupButtonClick = (): void => {
    dispatch(showModal(<ModalCreateAccount />));
  };

  return (
    <Styled.HeaderContainer>
      {isScheduledMaintenance && <ScheduledMaintenancePanel />}
      <Styled.HeaderContent $backgroundColor={headerTextColor}>
        {isAuth && (
          <Styled.MenuButton onClick={handleSidebarButtonClick}>
            <BurgerMenuIcon />
          </Styled.MenuButton>
        )}
        <Styled.ProjectLogoContainer $isAuth={isAuth}>
          <ProjectLogo />
        </Styled.ProjectLogoContainer>
        <Styled.LogoMobileView
          src={brandLogoUrl || Logo}
          onClick={handleLogoClick}
          $isAuth={isAuth}
          alt='glbee Free Online GLTF Viewer'
          id='app-logo-button'
        />
        <Styled.HeaderActions $isAuth={isAuth}>
          <CustomTooltip content={quickViewTooltip} placement='bottom'>
            <>
              <NavButton
                clickAction={handleQuickViewClick}
                name={'Quick View'}
                icon={<QuickViewIcon />}
              />
            </>
          </CustomTooltip>

          <div className='desktop-upload-button'>
            <CustomTooltip content={uploadModelTooltip} placement='bottom'>
              <>
                <NavButton
                  clickAction={handleUploadClick}
                  name={'Upload for Sharing'}
                  icon={<NewModelIcon />}
                />
              </>
            </CustomTooltip>
          </div>
          <div className='tablet-upload-button'>
            <CustomTooltip content={uploadModelTooltip} placement='bottom'>
              <>
                <NavButton
                  clickAction={handleUploadClick}
                  name={'Upload'}
                  icon={<NewModelIcon />}
                />
              </>
            </CustomTooltip>
          </div>

          {!isAuth && (
            <>
              <NavButton
                clickAction={handleSignupButtonClick}
                name={'Signup'}
                icon={<SignUpIcon />}
              />
              <NavButton
                clickAction={handleLoginButtonClick}
                name={'Login'}
                icon={<LoginIcon />}
              />
            </>
          )}

          {isAuth && (
            <Styled.GuideButton type='button' onClick={handleQuickGuideClick}>
              ?
            </Styled.GuideButton>
          )}
        </Styled.HeaderActions>
      </Styled.HeaderContent>
    </Styled.HeaderContainer>
  );
};

export default Header;
