import { COLORS } from 'shared/constants/colors';
import styled from 'styled-components';
import { DEVICE } from 'shared/constants/deviceSizes';
import { PLAN_CARD } from '../constants';
import { PlanContainer } from '../subscription-plan/styles';

export const SubscriptionsContainer = styled.div<{ $numPlans: number, $isModal?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  max-width: ${({ $numPlans, $isModal }): number => {
    const scaledWidth = PLAN_CARD.scaleValue(PLAN_CARD.width, $isModal);
    const totalWidth = scaledWidth * $numPlans;
    const padding = PLAN_CARD.maxGap * 2;
    return totalWidth + padding;
  }}px;
  container-type: inline-size;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 32px;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
`;

export const Title = styled.h1`
  font-size: 32px;
  font-weight: 400;
  color: ${COLORS.white};
  margin: 0;
  line-height: 110%;
  min-width: min-content;
  flex-basis: auto;
`;

export const BillingTypeSwitcher = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  flex-shrink: 0;
  
  // Right-align only when there's no Title sibling
  ${Title} + & {
    margin-left: 0;
    margin-top: 10px;
  }
  
  &:first-child {
    margin-left: auto;
  }
`;

export const BillingTypeText = styled.span`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  margin-right: 10px;
  margin-left: 2px;
  white-space: nowrap;
`;

export const BillingTypeButton = styled.button<{ $isActive: boolean }>`
  margin-left: 10px;
  width: 100px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ $isActive }): string => ($isActive ? COLORS.turbo : COLORS.doveGray)};
  font-weight: ${({ $isActive }): number => ($isActive ? 600 : 400)};
  font-size: 13px;
  line-height: 15px;
  color: ${COLORS.black};
  border-radius: 4px;
`;

export const PlansContainer = styled.div<{ $numPlans: number; $isModal?: boolean }>`
  display: grid;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  row-gap: 0;

  ${({ $numPlans, $isModal }) => {
    const cardWidth = PLAN_CARD.scaleValue(PLAN_CARD.width, $isModal);
    const minGap = PLAN_CARD.minGap;
    const maxGap = $isModal ? PLAN_CARD.minGap : PLAN_CARD.maxGap;
    const numColumns = Math.min(4, $numPlans);
    const containerThreshold2Column = PLAN_CARD.getColumnWidth(numColumns, $isModal);
    const containerThreshold1Column = PLAN_CARD.getColumnWidth(2, $isModal);

    return `
      grid-template-columns: repeat(${numColumns}, ${cardWidth}px);
      column-gap: clamp(${minGap}px, calc((100% - (${numColumns} * ${cardWidth}px)) / ${numColumns}), ${maxGap}px);

      @container (max-width: ${containerThreshold2Column}px) {
        grid-template-columns: repeat(2, ${cardWidth}px);
        max-width: ${cardWidth * 2 + maxGap}px;
        column-gap: clamp(${minGap}px, calc((100% - (2 * ${cardWidth}px)) / 2), ${maxGap}px);

        & > div:nth-child(n+3) ${PlanContainer} {
          margin-top: 0;
        }
      }

      @container (max-width: ${containerThreshold1Column}px) {
        grid-template-columns: ${cardWidth}px;
      }
    `;
  }}
`;

export const MostPopularLabel = styled.div<{ $isActive: boolean }>`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 26px;
  color: ${COLORS.black};
  background: ${COLORS.turbo};
  opacity: ${({ $isActive }): string => ($isActive ? '0.6' : '1')};
  font-size: 12px;
  font-weight: 700;
  border-radius: 4px 4px 0 0;
  padding-top: 2px;
  margin-bottom: -4px;

  @media ${DEVICE.mobile} {
    font-size: 8px;
    margin-bottom: -8px;
  }
`;

export const EnterprisePlanLink = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: flex-end;

  span {
    font-size: 13px;
    line-height: 15px;
  }

  span:first-child {
    margin-right: 5px;
  }

  span:last-child {
    color: ${COLORS.turbo};
    cursor: pointer;
  }
`;
