import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SetupIntent } from '@stripe/stripe-js';
import * as Styled from './styles';
import CheckYellow from 'assets/images/check-yellow.svg';
import {
  AuthState,
  CreateTeamState,
  ESnackbarStyle,
  PlanPrice,
  SubscriptionProduct
} from 'shared/types';
import { extractPlanData } from 'utils/subscription-plans-utils';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { openNotification } from 'utils/notification-utils';
import { closeConfirmModal } from 'services/store/reducers/modalReducer';
import { withElements } from 'shared/hocs/withElements';
import { AddressElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import {
  checkPromoCode,
  fetchTeamPlanPrice,
  payTeamSubscription
} from 'services/api/subscriptionsService';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { TEAM_CREATED } from 'shared/constants/notifications';
import { createTeam } from 'services/api/teamService';
import { clearCreateTeamData } from 'services/store/reducers/createTeamReducer';
import { fetchUserTeams } from 'services/store/reducers/teamsReducer';
import { throttle } from 'utils/delay-utils';
import Stripe from 'assets/images/stripe-label-white.svg';
import {
  clearTeamDataLocalStorage,
  getLocationStateFromSessionStorage,
  setTeamDataToLocalStorage
} from 'utils/storage-utils';
import { checkIsStripeTestCardError } from 'utils/error-utils';

type Props = {
  plan: SubscriptionProduct;
  isMonthlyType: boolean;
  setupIntentClientSecret?: string;
  isBusinessPayment?: boolean;
};

const ModalCreateTeamCheckout: React.FC<Props> = ({
  plan,
  isMonthlyType,
  setupIntentClientSecret,
  isBusinessPayment
}): JSX.Element => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const { isAuth, user } = useAppSelector((store): AuthState => store.auth);
  const { teamName, membersCount } = useAppSelector((store): CreateTeamState => store.createTeam);
  const [isMonth, setIsMonth] = useState(isMonthlyType);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [promoCodeInputValue, setPromoCodeInputValue] = useState<string>('');
  const promoCodeValue = useRef<string>('');
  const [isPromoCodeApplied, setIsPromoCodeApplied] = useState<boolean>(false);
  const [isPromoCodeFieldActive, setIsPromoCodeFieldActive] = useState<boolean>(false);
  const [isBusiness, setIsBusiness] = useState<boolean>(!!isBusinessPayment);
  const [setupIntent, setSetupIntent] = useState<SetupIntent | null>(null);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [tax, setTax] = useState<number>(0);
  const [isPriceCalculated, setIsPriceCalculated] = useState<boolean>(false);
  const isValidPromoCode = useRef<boolean>(false);
  const ONE_USD = 100;

  const priceObj = useMemo(
    (): PlanPrice | undefined =>
      plan.prices.find((i): boolean => i.recurring.interval === (isMonth ? 'month' : 'year')),
    [isMonth, plan]
  );

  const planPrice = ((priceObj?.unit_amount || 0) * membersCount) / ONE_USD;

  useEffect((): void => {
    if (!!setupIntentClientSecret && !!stripe) {
      (async (): Promise<void> => {
        const { setupIntent } = await stripe.retrieveSetupIntent(setupIntentClientSecret);
        if (!!setupIntent) setSetupIntent(setupIntent);
      })();
    }
  }, [setupIntentClientSecret, stripe]);

  const calculatePlanPrice = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    dispatch(startLoader());
    const promo = promoCodeValue.current;

    if (!!priceObj && !!setupIntent) {
      try {
        if (isPromoCodeApplied && !!promo && !isValidPromoCode.current) {
          const { valid } = (await checkPromoCode(promo)).data;
          isValidPromoCode.current = valid;
          valid
            ? openNotification(ESnackbarStyle.SUCCESS, 'Promo code has been successfully accepted')
            : openNotification(ESnackbarStyle.ERROR, 'Incorrect promo code');
        }

        const price = priceObj.id;
        const qty = membersCount;
        const payload =
          isPromoCodeApplied && isValidPromoCode.current
            ? { price, qty, setupIntent: setupIntent.id, promo }
            : { price, setupIntent: setupIntent.id, qty };
        const { amount_due, tax } = (await fetchTeamPlanPrice('new', payload)).data;
        setTax(tax / ONE_USD);
        setTotalPrice(amount_due / ONE_USD);
        setIsPriceCalculated(true);
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
      }
    }
    dispatch(stopLoader());
    setIsLoading(false);
  }, [setupIntent, dispatch, isPromoCodeApplied, membersCount, priceObj]);

  useEffect((): void => {
    calculatePlanPrice();
  }, [calculatePlanPrice]);

  const clearPromoCodeForm = (): void => {
    setPromoCodeInputValue('');
    promoCodeValue.current = '';
    isValidPromoCode.current = false;
    setIsPromoCodeApplied(false);
  };

  const handleApplyClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    if (isPromoCodeApplied) {
      clearPromoCodeForm();
    } else {
      setIsPromoCodeApplied(true);
    }
  };

  const handlePromoCodeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value.trim();
    if (isPromoCodeApplied && !value) {
      clearPromoCodeForm();
    } else {
      setPromoCodeInputValue(value);
      promoCodeValue.current = value;
    }
  };

  const handlePromoCodeButtonClick = (): void => {
    setIsPromoCodeFieldActive((prev): boolean => !prev);
  };

  const handleSaveCardButtonClick = async (): Promise<void> => {
    try {
      setIsLoading(true);
      dispatch(startLoader());
      if (!!elements && !!stripe) {
        const { current } = getLocationStateFromSessionStorage();
        setTeamDataToLocalStorage({ teamName, membersCount, isMonth, isBusiness, planId: plan.id });

        const { error, setupIntent } = await stripe.confirmSetup({
          elements,
          confirmParams: {
            return_url: `${process.env.REACT_APP_URL}${current || ''}`
          },
          redirect: 'if_required'
        });
        if (!!error) throw new Error(checkIsStripeTestCardError(error));
        setSetupIntent(setupIntent);
      }
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    } finally {
      clearTeamDataLocalStorage();
      dispatch(stopLoader());
      setIsLoading(false);
    }
  };

  const handlePayButtonClick = async (): Promise<void> => {
    if (!!stripe && !!elements && !!priceObj && !!setupIntent) {
      setIsLoading(true);
      dispatch(startLoader());
      try {
        const { id } = (await createTeam({ teamName: teamName.trim(), totalSeats: membersCount }))
          .data;
        const promo = promoCodeValue.current;
        const price = priceObj.id;
        const qty = membersCount;
        const payload =
          isPromoCodeApplied && isValidPromoCode.current
            ? { setupIntent: setupIntent.id, price, qty, promo }
            : { setupIntent: setupIntent.id, price, qty };
        const { payment_intent } = (await payTeamSubscription(id, payload)).data;
        const isFail = payment_intent?.status === 'requires_payment_method';
        const isRequiresAction =
          payment_intent?.status === 'requires_action' ||
          payment_intent?.status === 'requires_confirmation';
        if (isFail) throw new Error('Requires payment method');

        if (isRequiresAction && !!payment_intent?.client_secret && !!setupIntent) {
          const { error } = await stripe.confirmPayment({
            clientSecret: payment_intent.client_secret,
            confirmParams: {
              return_url: `${process.env.REACT_APP_URL}/workspace/${id}`
            },
            redirect: 'if_required'
          });

          if (!!error) throw new Error(checkIsStripeTestCardError(error));
        }

        await throttle(5000);
        await dispatch(fetchUserTeams());
        dispatch(clearCreateTeamData());
        history.push(`/workspace/${id}`);
        dispatch(closeConfirmModal());
        openNotification(ESnackbarStyle.SUCCESS, TEAM_CREATED);
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
      } finally {
        dispatch(stopLoader());
        setIsLoading(false);
      }
    }
  };

  const handleBusinessCheckboxClick = (): void => {
    if (!setupIntent) {
      setIsBusiness((prev): boolean => !prev);
    }
  };

  const { title, subtitle } = extractPlanData(plan);

  return (
    <Styled.ModalWindowContainer>
      <Styled.LeftSide>
        <Styled.BillingFrequencyBlock>
          <Styled.FieldLabel>Billing frequency</Styled.FieldLabel>
          <Styled.CheckboxField onClick={(): void => setIsMonth(false)}>
            <Styled.Checkbox $isActive={!isMonth}>
              {!isMonth && <img src={CheckYellow} alt='Check' />}
            </Styled.Checkbox>
            <span>Yearly</span>
          </Styled.CheckboxField>
          <Styled.CheckboxField onClick={(): void => setIsMonth(true)}>
            <Styled.Checkbox $isActive={isMonth}>
              {isMonth && <img src={CheckYellow} alt='Check' />}
            </Styled.Checkbox>
            <span>Monthly</span>
          </Styled.CheckboxField>
        </Styled.BillingFrequencyBlock>
        <Styled.PaymentInfoBlock>
          <Styled.FieldLabel>Payment Info</Styled.FieldLabel>
          <Styled.CheckboxField onClick={handleBusinessCheckboxClick} $isDisabled={!!setupIntent}>
            <Styled.Checkbox $isActive={isBusiness}>
              {isBusiness && <img src={CheckYellow} alt='Check' />}
            </Styled.Checkbox>
            <span>I'm a Business</span>
          </Styled.CheckboxField>

          {!setupIntent ? (
            <Styled.CardFormContainer>
              <Styled.CardForm>
                <PaymentElement
                  options={{ layout: 'tabs', wallets: { applePay: 'auto', googlePay: 'auto' } }}
                />
                {isBusiness && (
                  <AddressElement
                    options={{
                      mode: 'billing',
                      defaultValues: { name: `${user?.firstName} ${user?.lastName}` }
                    }}
                  />
                )}
              </Styled.CardForm>
              <Styled.CardFormFooter>
                <Styled.SaveCardButton onClick={handleSaveCardButtonClick} type='button'>
                  Save
                </Styled.SaveCardButton>
                <Styled.StripeLabel src={Stripe} />
              </Styled.CardFormFooter>
            </Styled.CardFormContainer>
          ) : (
            <Styled.CardSavedText>Card Saved</Styled.CardSavedText>
          )}
        </Styled.PaymentInfoBlock>
      </Styled.LeftSide>
      <Styled.RightSide>
        <Styled.RightSideHeader>
          <Styled.FieldLabel>Order summary</Styled.FieldLabel>
          {!!setupIntent && (
            <Styled.PromoCodeButton onClick={handlePromoCodeButtonClick} id='add-promo-code-button'>
              + Add Promo Code
            </Styled.PromoCodeButton>
          )}
        </Styled.RightSideHeader>
        {isPromoCodeFieldActive && (
          <Styled.PromoCodeForm>
            <Styled.PromoCodeLabel>Promo code:</Styled.PromoCodeLabel>
            <Styled.PromoCodeField>
              <Styled.PromoCodeInput value={promoCodeInputValue} onChange={handlePromoCodeChange} />
              <Styled.ApplyButton onClick={handleApplyClick}>
                {isPromoCodeApplied ? 'Clear' : 'Apply'}
              </Styled.ApplyButton>
            </Styled.PromoCodeField>
          </Styled.PromoCodeForm>
        )}
        <Styled.PayBlock>
          <Styled.AccountPlan>
            <div>
              <div>Account Plan:</div>
              <div>{`${title} ${subtitle} (${membersCount} Seats)`}</div>
            </div>
            <span>{`$${planPrice}`}</span>
          </Styled.AccountPlan>
          {isPriceCalculated && (
            <>
              <Styled.PriceField>
                <span>Tax</span>
                <span>{`$${tax}`}</span>
              </Styled.PriceField>
              <Styled.PriceField>
                <span>Total due today</span>
                <span>{`$${totalPrice}`}</span>
              </Styled.PriceField>
            </>
          )}
          <Styled.PayBlockFooter>
            <Styled.TermsConditions
              href={`${process.env.REACT_APP_URL}/terms-of-use?auth=${isAuth ? '1' : '0'}`}
              target='_blank'
              rel='noreferrer'
              id='term-of-use-button'
            >
              Terms of Use
            </Styled.TermsConditions>
            <Styled.PayButton
              onClick={handlePayButtonClick}
              disabled={isLoading || !setupIntent}
              id='pay-now-button'
            >
              Pay now
            </Styled.PayButton>
          </Styled.PayBlockFooter>
        </Styled.PayBlock>
      </Styled.RightSide>
    </Styled.ModalWindowContainer>
  );
};

export default withElements(ModalCreateTeamCheckout);
