export const PLAN_CARD = {
	minGap: 0,
	maxGap: 30,
	width: 320,
	scaleValue: (value: number, isModal?: boolean): number => value * (isModal ? 0.75 : 1),
	getColumnWidth: (count: number, isModal?: boolean): number => {
		const scaledWidth = PLAN_CARD.scaleValue(PLAN_CARD.width, isModal);
		const totalMinGap = PLAN_CARD.minGap * (count - 1);
		const padding = 2 * PLAN_CARD.maxGap;
		return count * scaledWidth + totalMinGap - padding;
	},
}
