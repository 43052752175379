import styled from 'styled-components';
import { DEVICE } from 'shared/constants/deviceSizes';
import { FONTS } from 'shared/constants/fonts';
import { COLORS } from 'shared/constants/colors';
import { DEFAULT_THEME_CSS_VARIABLES } from 'shared/constants/customization';

export const PageContent = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  font-family: ${FONTS.interRegular};
  color: ${COLORS.white};
`;

export const PageHeader = styled.header`
  display: flex;
  align-items: center;

  @media ${DEVICE.tablet} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const SearchField = styled.div`
  position: relative;
  height: 32px;
  width: 200px;
  display: flex;

  & img {
    position: absolute;
    right: 4px;
    top: 3px;
    width: 26px;
    height: 26px;
  }
`;

export const SearchInput = styled.input`
  height: 100%;
  width: 100%;
  padding: 4px 38px 4px 12px;
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.inputBackgroundColor};
  color: ${COLORS.white};
  border: none;
  border-radius: 4px;
  font-size: 14px;
  line-height: 16px;
`;

export const AddMembersButton = styled.button`
  margin-left: 20px;
  height: 32px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${COLORS.black};
  background: ${COLORS.turbo};
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  border-radius: 4px;
  transition: all 0.2s;

  &:hover {
    background: ${COLORS.corn};
  }

  @media ${DEVICE.tablet} {
    margin: 10px 0 0;
  }
`;

export const SeatsData = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: ${COLORS.white};
  margin-left: 20px;

  .data-value {
    color: ${COLORS.doveGray};
    font-style: italic;
    word-break: break-word;
  }

  @media ${DEVICE.tablet} {
    margin: 10px 0 0;
  }
`;

export const TableContainer = styled.section`
  margin-top: 40px;
  padding-bottom: 50px;

  .last-active {
    color: #f8e306;
  }

  .row-action {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  @media ${DEVICE.tablet} {
    .ant-table table {
      display: flex;
      flex-direction: column;
    }

    .ant-table thead {
      display: none;
    }

    .ant-table-tbody > tr {
      display: flex;
      flex-direction: column;

      &:not(:first-child) {
        margin-top: 40px;
      }
    }

    .ant-table .ant-table-tbody > tr > td {
      padding: 4px 8px;
    }

    .ant-table-tbody > tr > td > span,
    .ant-table-tbody > tr > td .cell-value {
      display: flex;
      word-break: break-all;

      &:before {
        content: attr(data-label);
        font-weight: bold;
        min-width: 150px;
        color: ${COLORS.white};
      }
    }
  }
`;

export const UserAvatar = styled.img`
  width: 36px;
  height: 36px;
`;

export const EmailField = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    cursor: pointer;
  }
`;

export const RowActionContainer = styled.div`
  position: relative;
`;

export const RowSelect = styled.div<{ $isActive: boolean }>`
  display: ${({ $isActive }): string => ($isActive ? 'block' : 'none')};
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translate(-100%, 100%);
  background: ${COLORS.mineShaft};
  border-radius: 5px;
  z-index: 10;
  min-width: 130px;

  &.permissions-select {
    transform: translate(-0%, 100%);
  }

  @media ${DEVICE.tablet} {
    &.permissions-select {
      transform: translate(0%, 100%);
      left: initial;
      right: 0;
    }

    &.actions-select {
      transform: translate(0%, 100%);
    }
  }
`;

export const RowSelectItem = styled.div`
  white-space: nowrap;
  color: ${COLORS.white};
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding: 0 10px;
`;

export const CheckIcon = styled.div<{ $isActive: boolean; $icon: string }>`
  background-image: ${({ $isActive, $icon }): string => ($isActive ? `url(${$icon})` : 'none')};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 12px;
  height: 12px;
`;

export const HintIcon = styled.div<{ $icon: string }>`
  background-image: ${({ $icon }): string => `url(${$icon})`};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 12px;
  height: 12px;
  margin-left: auto;
`;

export const SelectItemContainer = styled.div<{
  $isActive?: boolean;
  $checkHoverIcon?: string;
  $hintHoverIcon?: string;
}>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  cursor: pointer;

  &:first-child {
    border-radius: 4px 4px 0 0;
  }

  &:last-child {
    border-radius: 0 0 4px 4px;
  }

  &:hover {
    background: ${COLORS.turbo};

    ${RowSelectItem} {
      color: ${COLORS.black};
    }

    ${CheckIcon} {
      background-image: ${({ $isActive, $checkHoverIcon }): string =>
        $isActive ? `url(${$checkHoverIcon})` : 'none'};
    }

    ${HintIcon} {
      background-image: ${({ $hintHoverIcon }): string => `url(${$hintHoverIcon})`};
    }
  }

  &.remove-item {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding-left: 32px;
  }
`;

export const UserPermissionsContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: max-content;
`;

export const UserPermission = styled.div<{ $showPermissionsSelect: boolean }>`
  display: flex;
  align-items: center;
  cursor: ${({ $showPermissionsSelect }): string => ($showPermissionsSelect ? 'pointer' : 'initial')};

  img {
    margin: 3px 0 0 7px;
    width: 10px;
    height: 10px;
  }
`;
