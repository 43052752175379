import React, { useState } from 'react';
import * as Styled from './styles';
import { ArrowDownIcon, SettingsIcon } from 'assets/dynamic-icons';
import { useHandleClickOutside } from 'shared/hooks';
import { convertStringToButtonId } from 'utils/form-utils';

type TabData<T> = {
  id: T;
  title: string;
  icon: React.ReactElement;
  isAvailable: boolean;
};

type Props<T> = {
  activeOption?: TabData<T>;
  onChangeAction: (optionId: T) => void;
  options: TabData<T>[];
};

const SelectTab = <T,>({ activeOption, onChangeAction, options }: Props<T>): React.ReactElement => {
  const [isMenuActive, setIsMenuActive] = useState<boolean>(false);

  const handleOptionClick =
    (option: TabData<T>): (() => void) =>
    (): void => {
      setIsMenuActive(false);
      onChangeAction(option.id);
    };

  const toggleMenuActivity = (): void => {
    setIsMenuActive((state): boolean => !state);
  };

  const ref = useHandleClickOutside((): void => setIsMenuActive(false));

  return (
    <Styled.SelectContainer $isMenuActive={isMenuActive} ref={ref}>
      <Styled.MainField
        $isMenuActive={isMenuActive}
        onClick={toggleMenuActivity}
      >
        <Styled.OptionIconContainer>
          <SettingsIcon />
        </Styled.OptionIconContainer>
        <Styled.OptionTitle>{activeOption?.title || 'Settings'}</Styled.OptionTitle>
        <Styled.ArrowIconContainer $isMenuActive={isMenuActive}>
          <ArrowDownIcon />
        </Styled.ArrowIconContainer>
      </Styled.MainField>
      <Styled.SelectMenu $isMenuActive={isMenuActive}>
        {options.map(
          (option): React.ReactElement => (
            <Styled.OptionItem
              $isActive={activeOption?.title === option.title}
              onClick={handleOptionClick(option)}
              id={convertStringToButtonId(option.title)}
              key={option.title}
            >
              <span>{option.title}</span>
            </Styled.OptionItem>
          )
        )}
      </Styled.SelectMenu>
    </Styled.SelectContainer>
  );
};

export default SelectTab;
