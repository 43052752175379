import styled from 'styled-components';
import { DEVICE } from 'shared/constants/deviceSizes';
import { FONTS } from 'shared/constants/fonts';

const getPaddingTop = (
  isArMode: boolean,
  isZenMode: boolean,
  isEmbeddedModelMode: boolean,
  isScheduledMaintenance: boolean
): string => {
  switch (true) {
    case isZenMode || isEmbeddedModelMode:
      return '0';
    case isArMode:
      return '38px';
    case isScheduledMaintenance:
      return '140px';
    default:
      return '82px';
  }
};

const getPaddingLeft = (
  isAuth: boolean,
  isArMode: boolean,
  isPageWithScene: boolean,
  isZenMode: boolean,
  isEmbeddedModelMode: boolean
): string => {
  switch (true) {
    case !isAuth || isArMode || isZenMode || isEmbeddedModelMode:
      return '0';
    case isPageWithScene:
      return '60px';
    default:
      return '200px';
  }
};

export const RootContainer = styled.main<{
  $isScheduledMaintenance: boolean;
  $isAuth: boolean;
  $isArMode: boolean;
  $isPageWithScene: boolean;
  $isViewerPage: boolean;
  $isZenMode: boolean;
  $isEmbeddedModelMode: boolean;
}>`
  position: relative;
  width: 100%;
  min-height: ${({ $isArMode }): string => ($isArMode ? '100vh' : 'calc(100vh - 36px)')};
  overflow-y: ${({ $isArMode }): string => ($isArMode ? 'clip' : 'initial')};
  display: flex;
  flex-direction: column;
  font-family: ${FONTS.interRegular};
  padding-top: ${({ $isArMode, $isZenMode, $isEmbeddedModelMode, $isScheduledMaintenance }): string =>
    getPaddingTop($isArMode, $isZenMode, $isEmbeddedModelMode, $isScheduledMaintenance)};
  padding-left: ${({ $isAuth, $isArMode, $isPageWithScene, $isZenMode, $isEmbeddedModelMode }): string =>
    getPaddingLeft($isAuth, $isArMode, $isPageWithScene, $isZenMode, $isEmbeddedModelMode)};

  @media ${DEVICE.tabletLarge} {
    padding-left: 0;
  }

  @media ${DEVICE.tablet} {
    height: ${({ $isViewerPage }): string => ($isViewerPage ? 'var(--viewport-height)' : 'initial')};
    min-height: var(--viewport-height);
    padding-top: ${({ $isZenMode, $isEmbeddedModelMode }): string =>
      $isZenMode || $isEmbeddedModelMode ? '0' : '38px'};
  }
`;
