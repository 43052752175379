import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import * as Styled from './styles';
import Star from 'assets/images/star.svg';
import {
  SubscriptionsState,
  SubscriptionProduct,
  AuthState,
  EConfirmModalHeader,
  Model,
  EQuotaName,
  ESnackbarStyle,
  EPaymentType,
  CreateTeamState,
  TeamsState
} from 'shared/types';
import { extractPlanData, getSubscriptionLimits } from 'utils/subscription-plans-utils';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import {
  closeConfirmModal,
  closeModal,
  showConfirmModal,
  showModal
} from 'services/store/reducers/modalReducer';
import { convertStringToButtonId } from 'utils/form-utils';
import {
  ModalCheckout,
  ModalExceedLimits,
  ModalLogin,
  ModalDowngradePlan,
  ModalCreateTeamCheckout,
  ModalTeamCheckout
} from 'shared/components';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { getModelsOverSizeLimit } from 'services/api/modelsService';
import { getUserPlanLimit } from 'services/api/subscriptionsService';
import { openNotification } from 'utils/notification-utils';
import { EFeatureCategory } from 'shared/types/enums';
import { SPOTLIGHT_TITLE_OVERRIDES } from 'shared/constants/subscription-features';
import { getTeamModelsOverSizeLimit } from 'services/api/teamService';
import { MAX_TEAM_NAME_LENGTH } from 'shared/constants/limits';

type Props = {
  plan?: SubscriptionProduct;
  extractedPlanData: ReturnType<typeof extractPlanData>;
  isMonthlyType: boolean;
  seats?: number;
  isModal?: boolean;
  paymentType?: EPaymentType;
  featuresRef?: React.RefObject<HTMLDivElement>;
};

const SubscriptionPlan: React.FC<Props> = ({
  plan,
  extractedPlanData,
  isMonthlyType,
  seats = 1,
  isModal,
  paymentType,
  featuresRef
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { isAdmin, isMasterAdmin, isAuth } = useAppSelector((state): AuthState => state.auth);
  const { currentSubscriptionPlan, subscriptionPlans, planLevels } = useAppSelector(
    (store): SubscriptionsState => store.subscriptions
  );
  const { teamName } = useAppSelector((store): CreateTeamState => store.createTeam);
  const { teamPlan, activeTeam } = useAppSelector((store): TeamsState => store.teams);

  const isTeamPlan = paymentType === EPaymentType.CREATE_TEAM_PLAN || paymentType === EPaymentType.UPDATE_TEAM_PLAN;

  useEffect(() => {
    if (isAuth && !isAdmin && !isMasterAdmin && !isTeamPlan) {
      currentSubscriptionPlan ? dispatch(stopLoader()) : dispatch(startLoader());
    }
  }, [currentSubscriptionPlan, dispatch, isAuth, isAdmin, isMasterAdmin, isTeamPlan]);

  const {
    id,
    title,
    subtitle,
    monthPrice,
    yearPrice,
    categorizedFeatures,
    description,
    icon,
    isMostPopular,
    isCustomPlan
  } = extractedPlanData;

  const isCurrentPlanInterval = isTeamPlan
    ? teamPlan?.recurringInterval === (isMonthlyType ? 'month' : 'year')
    : currentSubscriptionPlan?.recurringInterval === (isMonthlyType ? 'month' : 'year');

  const isCurrentPlan = isTeamPlan
    ? id === teamPlan?.productId && isCurrentPlanInterval && !!plan
    : id === currentSubscriptionPlan?.productId && isCurrentPlanInterval && !isCustomPlan;

  const checkExceededLimits = async (
    plan: SubscriptionProduct
  ): Promise<{ modelsOverSizeLimit: Model[]; modelsOverLimit: number }> => {
    let modelsOverSizeLimit: Model[] = [];
    let modelsOverLimit: number = 0;
    dispatch(startLoader());
    
    try {
      const { modelSizePlanLimit, modelsPlanLimit } = getSubscriptionLimits(plan);
      
      if (isTeamPlan && activeTeam) {
        modelsOverSizeLimit = (await getTeamModelsOverSizeLimit(activeTeam.id, modelSizePlanLimit)).data;
        const { used } = (await getUserPlanLimit(EQuotaName.ACTIVE_MODELS_LIMIT, 'me', activeTeam.id)).data;
        const modelsLimit = modelsPlanLimit * (activeTeam.totalSeats || 1);
        modelsOverLimit = used - modelsLimit > 0 ? used - modelsLimit : 0;
      } else {
        modelsOverSizeLimit = (await getModelsOverSizeLimit(modelSizePlanLimit)).data;
        const { used } = (await getUserPlanLimit(EQuotaName.ACTIVE_MODELS_LIMIT)).data;
        modelsOverLimit = used - modelsPlanLimit > 0 ? used - modelsPlanLimit : 0;
      }
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    } finally {
      dispatch(stopLoader());
    }
    
    return { modelsOverSizeLimit, modelsOverLimit };
  };

  const handleSelectPlanAction = async (plan: SubscriptionProduct): Promise<void> => {
    const { modelsOverSizeLimit, modelsOverLimit } = await checkExceededLimits(plan);
    
    if (!!modelsOverSizeLimit.length || !!modelsOverLimit) {
      dispatch(
        showConfirmModal({
          header: null,
          content: (
            <ModalExceedLimits
              paymentType={isTeamPlan ? EPaymentType.UPDATE_TEAM_PLAN : EPaymentType.UPDATE_USER_PLAN}
              plan={plan}
              isMonthlyType={isMonthlyType}
              modelsOverLimit={modelsOverLimit}
              modelsOverSizeLimit={modelsOverSizeLimit}
              checkExceededLimits={checkExceededLimits}
            />
          )
        })
      );
    } else {
      dispatch(
        showConfirmModal({
          header: EConfirmModalHeader.CHECKOUT,
          content: isTeamPlan ? (
            <ModalTeamCheckout plan={plan} isMonthlyType={isMonthlyType} />
          ) : (
            <ModalCheckout plan={plan} isMonthlyType={isMonthlyType} />
          )
        })
      );
    }
  };

  const onSelectClick = async (): Promise<void> => {
    if (!isAuth && !isTeamPlan) {
      dispatch(showModal(<ModalLogin />));
      return;
    }

    if (isCustomPlan) {
      dispatch(closeModal());
      dispatch(closeConfirmModal());
      history.push('/contact-us');
      return;
    }

    if (!plan) return;

    if (paymentType === EPaymentType.CREATE_TEAM_PLAN) {
      if (!teamName || teamName.length > MAX_TEAM_NAME_LENGTH) return;
      
      dispatch(
        showConfirmModal({
          header: EConfirmModalHeader.CHECKOUT,
          content: <ModalCreateTeamCheckout plan={plan} isMonthlyType={isMonthlyType} />
        })
      );
      return;
    }

    const currentPlan = subscriptionPlans.find(
      (p): boolean => p.id === (isTeamPlan ? teamPlan?.productId : currentSubscriptionPlan?.productId)
    );

    if (!!currentPlan && !isCurrentPlan) {
      const currentPlanLevel = planLevels?.get(currentPlan) || 0;
      const selectedPlanLevel = planLevels?.get(plan) || 0;
      
      if (selectedPlanLevel < currentPlanLevel) {
        dispatch(
          showConfirmModal({
            header: 'Downgrade Plan',
            content: (
              <ModalDowngradePlan
                currentPlan={currentPlan}
                selectedPlan={plan}
                handleSelectPlanAction={handleSelectPlanAction}
              />
            )
          })
        );
      } else {
        await handleSelectPlanAction(plan);
      }
    }
  };

  const getSelectButtonText = (): string => {
    switch (true) {
      case isCustomPlan && isAdmin:
        return 'Selected';
      case isCurrentPlan:
        return 'Selected';
      case isCustomPlan:
        return 'Contact us';
      default:
        return 'Select';
    }
  };

  const handleScroll = useCallback((): void => {
    if (!featuresRef || !featuresRef.current) {
      window.open('/pricing', '_blank');
    } else {
      window.scrollTo({
        top: featuresRef.current.offsetTop,
        behavior: 'smooth',
      });
    }
  }, [featuresRef]);

  return (
    <Styled.PlanWrapper>
      {isMostPopular && (
        <Styled.MostPopularLabel $isModal={isModal}>
          <img src={Star} alt='Most Popular' />
          <span>MOST POPULAR</span>
        </Styled.MostPopularLabel>
      )}
      <Styled.PlanContainer $isMostPopular={isMostPopular} $isModal={isModal}>
        <Styled.PlanTitle $isModal={isModal}>{title}</Styled.PlanTitle>
        <Styled.PaymentDescription $isModal={isModal}>{description}</Styled.PaymentDescription>
        <Styled.PriceSection $isModal={isModal}>
          {isCustomPlan ? (
            <Styled.CustomPrice $isModal={isModal}>Custom Pricing</Styled.CustomPrice>
          ) : !monthPrice ? (
            <Styled.CustomPrice $isModal={isModal}>FREE</Styled.CustomPrice>
          ) : (
            <>
              <Styled.Currency>$</Styled.Currency>
              <Styled.Price $isModal={isModal}>
                {(isMonthlyType ? monthPrice : yearPrice) * seats}
              </Styled.Price>
              <Styled.Duration $isModal={isModal}>
                {`/ ${isMonthlyType ? 'month' : 'year'}`}
              </Styled.Duration>
            </>
          )}
        </Styled.PriceSection>
        <Styled.PlanDescription $isModal={isModal}>
          {categorizedFeatures[EFeatureCategory.SPOTLIGHT].map(
            ({ title, value }, index): JSX.Element => {
              const displayTitle = SPOTLIGHT_TITLE_OVERRIDES[title] || title;
              return (
                <Styled.PlanSpotlightFeature key={`${id}-${index}`} $isActive={value !== false}>
                  {typeof value !== 'boolean' ? `${value} ${displayTitle}` : displayTitle}
                </Styled.PlanSpotlightFeature>
              );
            }
          )}
        </Styled.PlanDescription>
        <Styled.SeeAllFeatures $isModal={isModal} onClick={handleScroll}>
          See All Features
        </Styled.SeeAllFeatures>
        <Styled.SelectButton
          onClick={onSelectClick}
          $isActive={isCurrentPlan || isMasterAdmin || isAdmin}
          disabled={isCurrentPlan || isMasterAdmin || isAdmin}
          $isModal={isModal}
          id={convertStringToButtonId(subtitle)}
        >
          {getSelectButtonText()}
        </Styled.SelectButton>
      </Styled.PlanContainer>
    </Styled.PlanWrapper>
  );
};

export default SubscriptionPlan;
