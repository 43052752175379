import type { IModelParserService, ITexture2DStats } from './interfaces/model-parse/model-stats';
import type { Model3DMetadataStats } from './interfaces/3d-models';

import { GLBParserService } from './glb-parser/glb-parser.service';
import { OBJParserService } from './obj-parser';
import { STLParserService } from './stl-parser';
import { FBXParserService } from './fbx-parser';
import { MAX_TEXTURES, MAX_VERTICES } from 'shared/constants/performance-model-warning';

const SUPPORTED_PARSERS = [GLBParserService, OBJParserService, STLParserService, FBXParserService];

export class ModelParserService {
  protected _parser: IModelParserService | null = null;

  /** 0 - none, 1 - lit, 2 - unlit, 3 - mixed */
  protected _unlitFlag: number = 0;
  protected _basePath: string = '';
  protected _format: string = '';

  public static dummyTextureStats(): ITexture2DStats {
    const dummyRecord = {
      totalCount: 0,
      png: {
        count: 0,
        maxSize: 0,
        minSize: 0
      },
      jpeg: {
        count: 0,
        maxSize: 0,
        minSize: 0
      }
    };

    return {
      totalCount: 0,
      '4096+': { ...dummyRecord },
      '4096': { ...dummyRecord },
      '2048': { ...dummyRecord },
      '1024': { ...dummyRecord },
      '512': { ...dummyRecord },
      '256': { ...dummyRecord },
      '128': { ...dummyRecord },
      NPOT: { ...dummyRecord }
    };
  }

  public async parse(ext: string, mainFile: Record<string, string>, files?: Record<string, string>): Promise<boolean> {
    this._parser = null;

    for (const ModelParser of SUPPORTED_PARSERS) {
      if (ModelParser.canHandleFileType(ext)) {
        this._parser = new ModelParser();
        await this._parser.parse(ext, mainFile, files);
        break;
      }
    }

    return this._parser != null;
  }

  public modelStats(modelSize?: number, opts?: { animations?: string[] }): Model3DMetadataStats {
    if (this._parser == null) {
      return ModelParserService.dummyModelStats(this._format, modelSize);
    }

    const textures = this._parser.textures;
    const fileSize = this._parser.fileSize;

    let animations = this._parser.animations;

    if (opts?.animations && Array.isArray(opts.animations)) {
      const _anim = opts.animations;
      const model_anim = animations?.animations || [];
      if (!model_anim || animations.totalCount !== model_anim.length) {
        animations = {
          totalCount: _anim.length,
          animations: _anim
        };
      }
    }

    return {
      format: this._parser.format,
      unlitFlag: this._parser.unlitFlag,
      fileSize: {
        ...fileSize,
        totalSize: fileSize.totalSize || modelSize || 0
      },
      geometry: {
        vertices: this._parser.vertexesCount,
        triangles: this._parser.trianglesCount,
        quads: this._parser.quadsCount,
        polygons: this._parser.polygonsCount
      },
      textures,
      resources: {
        meshes: this._parser.meshCount,
        textures: textures.totalCount,
        materials: this._parser.materialsCount,
        animations,
        scenes: this._parser.scenesCount
      },
    };
  }

  public static dummyModelStats(format: string, modelSize?: number): Model3DMetadataStats {
    return {
      format,
      unlitFlag: 1,
      geometry: {
        vertices: 0,
        triangles: 0,
        quads: 0,
        polygons: 0
      },
      textures: this.dummyTextureStats(),
      resources: {
        meshes: 0,
        textures: 0,
        materials: 0,
        animations: {
          totalCount: 0,
          animations: []
        },
        scenes: 0
      },
      fileSize: {
        totalSize: modelSize || 0,
        gltfSize: 0,
        binSize: 0
      },
    };
  }
}
