import { EEnvironmentPresets } from 'shared/enums/EEnvironmentPresets';
import { ELightPresets } from 'shared/enums/ELightsPresets';
import {
  Camera,
  CubemapOption,
  EnvironmentOption,
  LightingOption,
  ModelSettings
} from 'shared/types';
import FloatingGazebo from 'assets/images/settings-preview/environments/floating-gazebo.png';
import PrismStone from 'assets/images/settings-preview/environments/prism-stone.png';
import PrismLight from 'assets/images/settings-preview/environments/prism-light.png';
import SpaceshipTable from 'assets/images/settings-preview/environments/spaceship-table.png';
import WarehouseDark from 'assets/images/settings-preview/environments/warehouse-dark.png';
import WarehouseLight from 'assets/images/settings-preview/environments/warehouse-light.png';
import DefaultDark from 'assets/images/settings-preview/environments/default-dark.png';
import DefaultLight from 'assets/images/settings-preview/environments/default-light.png';
import MinimalDark from 'assets/images/settings-preview/environments/minimal-dark.png';
import MinimalLight from 'assets/images/settings-preview/environments/minimal-light.png';
import BasicWarehouse from 'assets/images/settings-preview/cubemaps/basic-warehouse.png';
import Warehouse from 'assets/images/settings-preview/cubemaps/warehouse.png';
import NoPreview from 'assets/images/no-preview.png';
import CityDay from 'assets/images/settings-preview/cubemaps/city-day.png';
import CityEvening from 'assets/images/settings-preview/cubemaps/city-evening.png';
import EmptyRoom from 'assets/images/settings-preview/cubemaps/empty-room.png';
import LightBox from 'assets/images/settings-preview/cubemaps/light-box.png';
import LightRoom from 'assets/images/settings-preview/cubemaps/light-room.png';
import WoodSurface from 'assets/images/settings-preview/cubemaps/wood-surface.png';
import ModelViewer from 'assets/images/settings-preview/lightings/model-viewer.png';
import RimLight from 'assets/images/settings-preview/lightings/rim-light.png';
import Dramatic from 'assets/images/settings-preview/lightings/dramatic.png';
import Product from 'assets/images/settings-preview/lightings/product.png';
import PoisonOrbit from 'assets/images/settings-preview/lightings/poison-orbit.png';
import { E3DModelFileTypes } from '../enums/E3DModelFileTypes';
import { DEFAULT_CUBEMAPS } from './cubemaps';
import { ECubemapPresets } from '../enums/ECubemapPresets';

export const LIGHTING_OPTIONS: LightingOption[] = [
  {
    title: 'Rim Light',
    value: {
      key: ELightPresets.RimLight,
      lightPreset: ELightPresets.RimLight
    },
    preview: RimLight,
    isAvailable: true
  },
  {
    title: 'Model Viewer',
    value: {
      key: ELightPresets.ModelViewer,
      lightPreset: ELightPresets.ModelViewer
    },
    preview: ModelViewer,
    isAvailable: true
  },
  {
    title: 'Dramatic',
    value: {
      key: ELightPresets.Dramatic,
      lightPreset: ELightPresets.Dramatic
    },
    preview: Dramatic,
    isAvailable: false
  },
  {
    title: 'Product',
    value: {
      key: ELightPresets.Product,
      lightPreset: ELightPresets.Product
    },
    preview: Product,
    isAvailable: false
  },
  {
    title: 'Poison Orbit',
    value: {
      key: ELightPresets.PoisonOrbit,
      lightPreset: ELightPresets.PoisonOrbit
    },
    preview: PoisonOrbit,
    isAvailable: false
  }
];

export const ENVIRONMENT_OPTIONS: EnvironmentOption[] = [
  {
    title: 'Default (Dark)',
    value: {
      key: EEnvironmentPresets.DefaultDark,
      envPreset: EEnvironmentPresets.DefaultDark
    },
    preview: DefaultDark,
    isAvailable: true
  },
  {
    title: 'Default (Light)',
    value: {
      key: EEnvironmentPresets.DefaultLight,
      envPreset: EEnvironmentPresets.DefaultLight
    },
    preview: DefaultLight,
    isAvailable: true
  },
  {
    title: 'Minimal (Dark)',
    value: {
      key: EEnvironmentPresets.MinimalDark,
      envPreset: EEnvironmentPresets.MinimalDark
    },
    preview: MinimalDark,
    isAvailable: true
  },
  {
    title: 'Minimal (Light)',
    value: {
      key: EEnvironmentPresets.MinimalLight,
      envPreset: EEnvironmentPresets.MinimalLight
    },
    preview: MinimalLight,
    isAvailable: true
  },
  {
    title: 'Floating Gazebo',
    value: {
      key: EEnvironmentPresets.Gazebo,
      envPreset: EEnvironmentPresets.Gazebo
    },
    preview: FloatingGazebo,
    isAvailable: false
  },
  {
    title: 'Prism - Stone',
    value: {
      key: EEnvironmentPresets.Prism,
      envPreset: EEnvironmentPresets.Prism
    },
    preview: PrismStone,
    isAvailable: false
  },
  {
    title: 'Prism - Light',
    value: {
      key: EEnvironmentPresets.PrismLight,
      envPreset: EEnvironmentPresets.PrismLight
    },
    preview: PrismLight,
    isAvailable: false
  },
  {
    title: 'Spaceship Table',
    value: {
      key: EEnvironmentPresets.SciFiRoomGlb,
      envPreset: EEnvironmentPresets.SciFiRoomGlb
    },
    preview: SpaceshipTable,
    isAvailable: false
  },
  {
    title: 'Warehouse - Dark',
    value: {
      key: EEnvironmentPresets.WarehouseGlbDark,
      envPreset: EEnvironmentPresets.WarehouseGlbDark
    },
    preview: WarehouseDark,
    isAvailable: false
  },
  {
    title: 'Warehouse - Light',
    value: {
      key: EEnvironmentPresets.WarehouseGlbLight,
      envPreset: EEnvironmentPresets.WarehouseGlbLight
    },
    preview: WarehouseLight,
    isAvailable: false
  },
  {
    title: 'No environment',
    value: {
      key: EEnvironmentPresets.NoEnvironment,
      envPreset: EEnvironmentPresets.NoEnvironment
    },
    preview: NoPreview,
    isAvailable: true
  }
];

export const CUBEMAP_OPTIONS: CubemapOption[] = [
  {
    title: 'Neutral',
    value: DEFAULT_CUBEMAPS[ECubemapPresets.Neutral],
    preview: NoPreview,
    isAvailable: true
  },
  {
    title: 'Basic warehouse',
    value: DEFAULT_CUBEMAPS[ECubemapPresets.BasicWarehouse],
    preview: BasicWarehouse,
    isAvailable: true
  },
  {
    title: 'City day',
    value: DEFAULT_CUBEMAPS[ECubemapPresets.CityDay],
    preview: CityDay,
    isAvailable: true
  },
  {
    title: 'City evening',
    value: DEFAULT_CUBEMAPS[ECubemapPresets.CityEvening],
    preview: CityEvening,
    isAvailable: false
  },
  {
    title: 'Empty room',
    value: DEFAULT_CUBEMAPS[ECubemapPresets.EmptyRoom],
    preview: EmptyRoom,
    isAvailable: false
  },
  {
    title: 'Light box',
    value: DEFAULT_CUBEMAPS[ECubemapPresets.LightBox],
    preview: LightBox,
    isAvailable: false
  },
  {
    title: 'Light room',
    value: DEFAULT_CUBEMAPS[ECubemapPresets.LightRoom],
    preview: LightRoom,
    isAvailable: false
  },
  {
    title: 'Warehouse',
    value: DEFAULT_CUBEMAPS[ECubemapPresets.Warehouse],
    preview: Warehouse,
    isAvailable: false
  },
  {
    title: 'Wood surface',
    value: DEFAULT_CUBEMAPS[ECubemapPresets.WoodSurface],
    preview: WoodSurface,
    isAvailable: false
  }
];

export const INITIAL_CAMERA: Camera = {
  position: { x: 1, y: 1, z: 1 },
  rotation: { x: Math.PI / 2, y: Math.PI / 2, z: 0 },
  target: { x: 0, y: 0, z: 0 },
  fixed: false
};

export const INITIAL_MODEL_SETTINGS: ModelSettings = {
  camera: INITIAL_CAMERA,
  lighting: LIGHTING_OPTIONS[0].value,
  environment: ENVIRONMENT_OPTIONS[0].value,
  cubemap: CUBEMAP_OPTIONS[0].value,
  scale: { x: 1, y: 1, z: 1 },
  offset: { x: 0, y: 0, z: 0 },
  rotation: { x: 0, y: 0, z: 0 },
  scaleFactor: { x: 1, y: 1, z: 1 },
  autoRotate: false,
  enableShadows: false,
  wireframe: false,
  showDimensions: false,
  arScaleInMeters: false,
  playAnimation: false,
  animation: -1
};

export const FILE_TYPES: Record<string, E3DModelFileTypes> = {
  '.gltf': E3DModelFileTypes.gltf,
  '.glb': E3DModelFileTypes.glb,
  '.obj': E3DModelFileTypes.obj,
  '.fbx': E3DModelFileTypes.fbx,
  '.stl': E3DModelFileTypes.stl,
  '.usdz': E3DModelFileTypes.usdz,
  '.usd': E3DModelFileTypes.usd,
  '.usda': E3DModelFileTypes.usda,
  '.usdc': E3DModelFileTypes.usdc,
};

export const ANONYMOUS_MODEL_ID = -1;
export const SAMPLE_MODEL_ID = '-1';
export const QUICK_VIEW_MODEL_ID = '-2';
