import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as Styled from './styles';
import * as THREE from 'three';
import { CommentThreadBubble } from '../comment-thread-bubble';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { IViewerCommentFromServer } from 'shared/interfaces';
import { useAppSelector } from 'shared/hooks';
import { RootState } from 'services/store';
import { checkIfPointInCameraFrustum, findObjectByPath } from 'utils/scenes-utils';
import { Note } from 'shared/types';
import { CAMERA_MAX_DISTANCE } from 'shared/constants/scene-settings-constants';
import { MainScene } from 'shared/webgl';
import { CameraController } from 'shared/webgl';
import { getReplyFromThread } from 'utils/comments-utils';

interface Props {
  mainScene: MainScene;
  cameraController: CameraController;
  commentThread: IViewerCommentFromServer;
  isNewComment?: boolean;
  indexedMap: THREE.Object3D[];
  getThreadElementIdByThreadId: (id: Note['threadId']) => string;
  updateIndexedMap: () => void;
  createScreenshot: (editor?: HTMLDivElement) => Promise<string>;
}

const globalState: { isIndexedMapReady: boolean } = {
  isIndexedMapReady: false
};

export const CommentCSS2DObjectWrapper: React.FC<Props> = ({
  mainScene,
  cameraController,
  commentThread,
  isNewComment,
  indexedMap,
  getThreadElementIdByThreadId,
  updateIndexedMap,
  createScreenshot
}): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  const editorWrapper = useMemo((): THREE.Object3D => new THREE.Object3D(), []);
  const store = useAppSelector((store): RootState => store);

  const { isCommentsVisible, activeCommentId } = store.comments;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect((): void => {
    const selectedReply = getReplyFromThread(commentThread, activeCommentId);

    if (commentThread.id === activeCommentId || !!selectedReply) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [activeCommentId]);

  useEffect((): (() => void) => {
    const css2DObject = new CSS2DObject(ref.current!);
    editorWrapper.add(css2DObject);

    return (): void => {
      editorWrapper.removeFromParent();
      editorWrapper.clear();
      css2DObject.removeFromParent();
      css2DObject.clear();
    };
  }, [commentThread.params]);

  useEffect((): void => {
    globalState.isIndexedMapReady = !!indexedMap.length;
  }, [indexedMap]);

  useEffect((): void => {
    if (!editorWrapper.parent) {
      find(editorWrapper);
    }
  }, [indexedMap.length, commentThread.params]);

  useEffect((): void => {
    if (commentThread.id !== activeCommentId && isNewComment && ref.current) {
      editorWrapper.removeFromParent();
      editorWrapper.clear();
    }
  }, [activeCommentId, ref, isNewComment, commentThread.id]);

  useEffect((): void => {
    const selectedReply = getReplyFromThread(commentThread, activeCommentId);

    if ((!isNewComment && commentThread.id === activeCommentId) || !!selectedReply) {
      zoomOutToShowComment();
    }
  }, [isNewComment, activeCommentId, commentThread]);

  const getMeshBoneByFace = (mesh: THREE.SkinnedMesh, face: THREE.Face): THREE.Bone | undefined => {
    const getIndexes = (
      vertexIndices: number[]
    ): { boneIndexes: number[]; boneWeights: number[] } => {
      const boneIndexes: number[] = [];
      const boneWeights: number[] = [];

      vertexIndices.forEach((vertexIndex): void => {
        const skinIndexes = new THREE.Vector4().fromBufferAttribute(
          mesh.geometry.attributes.skinIndex as THREE.BufferAttribute,
          vertexIndex
        );
        const skinWeights = new THREE.Vector4().fromBufferAttribute(
          mesh.geometry.attributes.skinWeight as THREE.BufferAttribute,
          vertexIndex
        );

        boneIndexes.push(...skinIndexes.toArray());
        boneWeights.push(...skinWeights.toArray());
      });

      return { boneIndexes, boneWeights };
    };

    const vertexIndexes = [face.a, face.b, face.c];
    const { boneIndexes, boneWeights } = getIndexes(vertexIndexes);
    const max = Math.max(...boneWeights);
    const boneIndex = boneIndexes[boneWeights.indexOf(max)];

    return mesh.skeleton.bones[boneIndex];
  };

  const find = (editorWrapper: THREE.Object3D): void => {
    if (globalState.isIndexedMapReady) {
      let parent: THREE.Object3D | undefined;

      if (commentThread.params.path) {
        parent = findObjectByPath(
          commentThread.params.path,
          commentThread.params.parent_id,
          indexedMap,
          updateIndexedMap
        );
      }

      if (!parent) {
        parent = indexedMap[commentThread.params.parent_id];
      }

      editorWrapper.name = 'comment';
      editorWrapper.traverse((obj): void => {
        obj.name = 'comment';
      });
      editorWrapper.position.set(
        commentThread.params.position.x,
        commentThread.params.position.y,
        commentThread.params.position.z
      );

      if (parent) {
        if (parent instanceof THREE.SkinnedMesh && commentThread.params.face) {
          const bone = getMeshBoneByFace(parent, commentThread.params.face);

          parent.add(editorWrapper);

          if (bone) {
            bone.attach(editorWrapper);
          }
        } else {
          parent.add(editorWrapper);
        }

        return;
      }
    } else {
      requestAnimationFrame((): void => {
        find(editorWrapper);
      });
    }
  };

  const zoomOutToShowComment = (onComplete?: () => void): void => {
    const direction = new THREE.Vector3();
    const target = mainScene.orbitControls.target;
    const speed = 10;

    const zoom = (): void => {
      mainScene.orbitControls.update();

      const isObjectInFrustum = checkIfPointInCameraFrustum(
        editorWrapper.getWorldPosition(new THREE.Vector3()),
        mainScene.camera
      );
      const isDistanceLimitExceeded = mainScene.orbitControls.getDistance() >= CAMERA_MAX_DISTANCE;

      if (isObjectInFrustum || isDistanceLimitExceeded) {
        if (onComplete) {
          onComplete();
        }

        return;
      }

      const prevPosition = mainScene.camera.position.clone();

      direction.subVectors(prevPosition, target).multiplyScalar(0.01 * speed);

      const newPosition = mainScene.camera.position.clone().add(direction);

      mainScene.camera.position.set(newPosition.x, newPosition.y, newPosition.z);
      mainScene.orbitControls.update();
      requestAnimationFrame(zoom);
    };

    zoom();
  };

  const getZIndex = useCallback((): number => {
    return Math.max(
      commentThread.commentNum!,
      ...(commentThread.comments
        ? commentThread.comments.map((comment): number => comment.commentNum!)
        : [])
    );
  }, [commentThread]);

  return (
    <Styled.Editor ref={ref} $zIndex={isOpen ? 1000 : getZIndex()}>
      {isCommentsVisible && (
        <CommentThreadBubble
          mainScene={mainScene}
          cameraController={cameraController}
          commentThread={commentThread}
          isNewComment={isNewComment}
          threadElementIdByThreadId={getThreadElementIdByThreadId}
          updateIndexedMap={updateIndexedMap}
          createScreenshot={createScreenshot}
        />
      )}
    </Styled.Editor>
  );
};
