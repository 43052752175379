import React, { ChangeEvent, useState } from 'react';
import * as Styled from './styles';
import { getFieldError } from 'utils/form-utils';
import { FormikContextType, getIn } from 'formik';
import HiddenEye from 'assets/images/hide-eye.svg';
import Eye from 'assets/images/eye.svg';

type Props<T> = {
  name: string;
  type?: string;
  placeholder?: string;
  formik: FormikContextType<T>;
  isPassword?: boolean;
  isDisabled?: boolean;
  isAutofillDisabled?: boolean;
};

const FormField = <T,>({
  formik,
  type,
  name,
  placeholder,
  isPassword,
  isDisabled,
  isAutofillDisabled
}: Props<T>): JSX.Element => {
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const { setFieldValue, values, handleBlur, isSubmitting } = formik;
  const inputValue = getIn(values, name);

  const togglePasswordVisibility = (): void => {
    setIsPasswordVisible((prevState): boolean => !prevState);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setFieldValue(name, value);
  };

  const getInputType = (): string => {
    if (isPassword) {
      return isPasswordVisible ? 'text' : 'password';
    } else {
      return type || 'text';
    }
  };

  return (
    <Styled.FormField>
      <Styled.FieldInput
        type={getInputType()}
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleBlur}
        name={name}
        placeholder={placeholder || ''}
        $isPassword={isPassword}
        $isEmptyValue={!inputValue}
        disabled={isSubmitting || isDisabled}
        autoComplete={isAutofillDisabled ? 'new-password' : ''}
      />
      {isPassword && !!inputValue && (
        <img
          src={isPasswordVisible ? HiddenEye : Eye}
          onClick={togglePasswordVisibility}
          alt='Eye'
        />
      )}
      {getFieldError(formik, name)}
    </Styled.FormField>
  );
};

export default FormField;
