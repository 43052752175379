import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';

export const TabContent = styled.div`
  display: flex;
  flex-direction: column;
  color: ${COLORS.white};
`;

export const FormContainer = styled.div`
  background: ${COLORS.mineShaft};
  border-radius: 7px;
  padding: 25px;
  max-width: 620px;
  width: 100%;

  @media${DEVICE.tablet} {
    width: 100%;
    max-width: initial;
  }

  @media${DEVICE.mobile} {
    padding: 25px 10px;
  }
`;

export const ViewerSettingsForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const FormHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const FormHeaderLeftSide = styled.div`
  display: flex;
  align-items: center;
`;

export const FormContentTitle = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;

  @media${DEVICE.mobile} {
    font-size: 18px;
    line-height: 20px;
  }
`;

export const FormHeaderRightSide = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;

  @media${DEVICE.mobile} {
    font-size: 14px;
    line-height: 16px;
  }
`;

export const FormHeaderRightSideArrow = styled.img<{
  $isClicked: boolean;
}>`
  margin-left: 6px;
  ${({ $isClicked }): string =>
    $isClicked
      ? `
  -webkit-animation:spin 0.5s linear 1 reverse;
  -moz-animation:spin 0.5s linear 1 reverse;
  animation:spin 0.5s linear 1 reverse;

  @-moz-keyframes spin {
    100% { -moz-transform: rotate(360deg); }
  }
  @-webkit-keyframes spin {
    100% { -webkit-transform: rotate(360deg); }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform:rotate(360deg);
    }
  }
  `
      : ''}}
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FormContentSubTitle = styled.div`
  display: flex;
  align-self: flex-start;
  margin-bottom: 16px;
  font-style: italic;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;

  @media${DEVICE.mobile} {
    font-size: 13px;
    line-height: 14px;
  }
`;

export const FormContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: min-content;
`;

export const FormKeybindingTable = styled.div`
  display: grid;
  grid-template-columns: auto repeat(2, 126px);
  grid-auto-rows: 40px;
  gap: 15px 37px;

  &:not(:last-child) {
    margin-bottom: 28px;
  }

  @media ${DEVICE.mobile} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const TableColumnTitle = styled.div<{
  $isSecondary?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: end;
  text-align: center;
  font-family: ${({ $isSecondary }): string => (!$isSecondary ? 'Inter Medium' : 'Inter Regular')}, sans-serif;
  font-weight: ${({ $isSecondary }): string => (!$isSecondary ? '500' : '400')};
  font-size: 15px;
  line-height: 17px;
`;

export const TableRowTitle = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  text-align: right;
  margin-right: -4px;
  font-weight: 700;
  font-size: 13px;
  line-height: 14px;
  white-space: nowrap;
`;

export const TableRowItem = styled.div<{
  $isActive: boolean;
}>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: ${({ $isActive }): string => ($isActive ? '#727272' : '#000000')};
  border: 1px solid
  ${({ $isActive }): string => ($isActive ? COLORS.turbo : 'rgba(255, 255, 255, 0.3)')};
  border-radius: 4px;
  font-style: ${({ $isActive }): string => ($isActive ? 'italic' : 'normal')};
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;

  ${({ $isActive }): string =>
    !$isActive
      ? `
        &:hover * {
          visibility: visible;
        }
  `
      : ``}

  &:hover {
    border: 1px solid ${COLORS.turbo};
  }
`;

export const TableItemToolsContainer = styled.div`
  position: absolute;
  display: flex;
  right: 13px;
  padding-left: 5px;
  gap: 6px;
  background-color: rgba(0, 0, 0, 0.8);
  visibility: hidden;
`;

export const TableItemToolsItem = styled.img`
  cursor: pointer;
`;

export const FormSubmitButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 173px;
  height: 40px;
  align-self: end;
  font-weight: 500;
  font-size: 12.5px;
  line-height: 15px;
  color: ${COLORS.codGray};
  background: ${COLORS.turbo};
  border-radius: 2px;
`;

export const FormToast = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 46px;
  margin: -14px 0 9px 0;
  padding: 13px 34px;
  font-style: italic;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  background: linear-gradient(92.44deg, rgba(10, 10, 10, 0.7) 8%, #111111 95.55%);
  border-radius: 4px;

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 2px;
    height: calc(100% - 8px);
    left: 4px;
    top: 4px;
    background: ${COLORS.turbo};
    border-radius: 2px;
  }
`;

export const Hint = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  min-width: 16px;
  width: 16px;
  min-height: 16px;
  height: 16px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.51);
  font-size: 12px;
  color: ${COLORS.white};
  text-decoration: none;
`;
