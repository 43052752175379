import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';
import { DEFAULT_THEME_CSS_VARIABLES } from 'shared/constants/customization';

export const ModalWindowContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 640px;
  color: ${COLORS.white};
  position: relative;
  padding: 40px;
  padding-bottom: 0px;
  border-radius: 7px;
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.modalBackgroundColor};

  @media ${DEVICE.mobile} {
    padding: 20px;
  }
`;

export const ModalTitle = styled.h1`
  font-weight: 600;
  font-size: 28px;
  line-height: 30px;
  color: ${COLORS.white};
  margin-bottom: 20px;

  @media${DEVICE.tablet} {
    font-size: 30px;
    line-height: 32px;
  }

  @media ${DEVICE.mobile} {
    font-size: 24px;
    line-height: 26px;
  }
`;

export const ModalText = styled.div`
  width: 100%;
  font-size: 22px;
  margin-bottom: 8px;

  span {
    color: ${COLORS.turbo};
  }
`;

export const ModalFooter = styled.div`
  padding-right: 20px;
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
`;

export const LogoCube = styled.img`
  width: 120px;
  height: 120px;
  transform: rotate(90deg);
`;

export const ModalList = styled.div`
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 20px;
  font-size: 18px;
  padding: 10px 0;
`;