import React, { useEffect, useRef, useState } from 'react';
import * as Styled from './styles';
import { Table, TablePaginationConfig, Tooltip } from 'antd';
import moment from 'moment';
import {
  useAppDispatch,
  useAppSelector,
  useCopyToClipboard,
  useDebounce,
  useHandleClickOutside,
  useTeamMembersData
} from 'shared/hooks';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import Search from 'assets/images/search.svg';
import Arrow from 'assets/images/arrow-down-white.svg';
import TooltipIcon from 'assets/images/hint-white.svg';
import TooltipIconBlack from 'assets/images/hint-black.svg';
import Checked from 'assets/images/check.svg';
import CheckedBlack from 'assets/images/check-black.svg';
import GlbeeAvatar from 'assets/images/glbee-avatar.svg';
import CopyEmail from 'assets/images/copy.svg';
import Remove from 'assets/images/remove.svg';
import { AuthState, ESnackbarStyle, ETeamRole, TeamsState } from 'shared/types';
import { PERMISSIONS } from './constants';
import { TEAM_ROLE_GRADES } from 'shared/constants/team-roles';
import { showModal } from 'services/store/reducers/modalReducer';
import { ModalAddNewMembers } from 'shared/components';
import { deleteMember, updateMemberPermission } from 'services/api/teamService';
import { openNotification } from 'utils/notification-utils';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { capitalize } from 'utils/form-utils';
import { clearActiveTeam, fetchTeam, fetchUserTeams } from 'services/store/reducers/teamsReducer';
import { useHistory } from 'react-router-dom';

type TableTeammateItem = {
  key: number;
  memberId: number;
  name: string;
  email: string;
  teamPermissions: {
    rowId: number;
    permissionsLevel: ETeamRole;
    memberId: number;
  };
  lastActive: string;
  removeAction: {
    memberId: number;
    userId?: number;
    permissionsLevel: ETeamRole;
  };
};

const MembersTab = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { activeTeam, teamRole } = useAppSelector((store): TeamsState => store.teams);
  const { user } = useAppSelector((store): AuthState => store.auth);
  const [tableTeamData, setTableTeamData] = useState<TableTeammateItem[]>([]);
  const [activePermissionsSelectId, setActivePermissionsSelectId] = useState<number | null>(null);
  const isManagePermission =
    teamRole === ETeamRole.OWNER || teamRole === ETeamRole.ADMIN || teamRole === ETeamRole.MANAGER;
  const tableRef = useRef<HTMLDivElement | null>(null);
  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const debouncedSearchValue = useDebounce(searchInputValue, 1000);
  const { copyToClipboard } = useCopyToClipboard();
  const {
    fetchTeamMembers,
    teamMembers,
    isTeamDataLoading,
    currentPage,
    totalItems,
    setCurrentPage,
    setSearchValue
  } = useTeamMembersData();

  useEffect((): void => {
    if (!!activeTeam) {
      fetchTeamMembers(activeTeam.id);
    }
  }, [activeTeam, fetchTeamMembers]);

  useEffect((): void => {
    setSearchValue(debouncedSearchValue);
    setCurrentPage(1);
  }, [debouncedSearchValue, setSearchValue, setCurrentPage]);

  useEffect((): void => {
    const tableTeamData = teamMembers.map(
      ({ id, user, role, lastActiveAt, invite }): TableTeammateItem => ({
        key: id,
        memberId: id,
        name: !!user ? `${user.firstName} ${user.lastName}` : '',
        email: user?.email || invite?.userEmail || '',
        teamPermissions: { rowId: id, permissionsLevel: role, memberId: id },
        lastActive: !!lastActiveAt ? moment(lastActiveAt).fromNow() : 'Invite Sent',
        removeAction: { memberId: id, permissionsLevel: role, userId: user?.id }
      })
    );
    setTableTeamData(tableTeamData);
  }, [teamMembers]);

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchInputValue(event.target.value);
  };

  const handleClickOutside = (): void => {
    setActivePermissionsSelectId(null);
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<TableTeammateItem> | SorterResult<TableTeammateItem>[]
  ): void => {
    if (!!pagination.current) {
      setCurrentPage(pagination.current);
      tableRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const handleCopyEmailClick =
    (email: string): (() => Promise<void>) =>
    async (): Promise<void> => {
      await copyToClipboard(email, 'Email has been successfully copied!');
    };

  const handlePermissionsLevelClick =
    (memberId: number, permissionsLevel: ETeamRole): (() => Promise<void>) =>
    async (): Promise<void> => {
      if (!!activeTeam) {
        try {
          dispatch(startLoader());
          await updateMemberPermission(activeTeam.id, memberId, permissionsLevel);
          await fetchTeamMembers(activeTeam.id);
        } catch (e) {
          openNotification(ESnackbarStyle.ERROR, e?.message);
        } finally {
          dispatch(stopLoader());
          handleClickOutside();
        }
      }
    };

  const handlePermissionsSelectClick =
    (rowId: number): ((event: React.MouseEvent<HTMLElement>) => void) =>
    (event): void => {
      event.stopPropagation();
      setActivePermissionsSelectId(rowId);
    };

  const handleAddNewMembersClick = (): void => {
    if (!!activeTeam) {
      dispatch(
        showModal(<ModalAddNewMembers teamId={activeTeam.id} fetchTeamMembers={fetchTeamMembers} />)
      );
    }
  };

  const handleRemoveButtonClick =
    (memberId: number, userId?: number): (() => Promise<void>) =>
    async (): Promise<void> => {
      if (!!activeTeam) {
        try {
          dispatch(startLoader());
          await deleteMember(activeTeam.id, memberId);
          await fetchTeamMembers(activeTeam.id);
          await dispatch(fetchTeam({ teamId: activeTeam.id }));
          if (userId === user?.id) {
            await dispatch(fetchUserTeams());
            history.push('/my-workspace');
            dispatch(clearActiveTeam());
          }
        } catch (e) {
          openNotification(ESnackbarStyle.ERROR, e?.message);
        } finally {
          dispatch(stopLoader());
        }
      }
    };

  const checkPermissionsVisibility = (permissionsLevel: ETeamRole): boolean =>
    permissionsLevel !== ETeamRole.OWNER &&
    TEAM_ROLE_GRADES[teamRole] < TEAM_ROLE_GRADES[permissionsLevel] &&
    isManagePermission;

  const checkRemoveButtonVisibility = (permissionsLevel: ETeamRole, userId?: number): boolean =>
    permissionsLevel !== ETeamRole.OWNER &&
    (userId === user?.id ||
      (TEAM_ROLE_GRADES[teamRole] < TEAM_ROLE_GRADES[permissionsLevel] && isManagePermission));

  const rowSelectRef = useHandleClickOutside(handleClickOutside);

  const TOOLTIP_STYLES = {
    backdropFilter: 'blur(7.5px)',
    borderRadius: '7px',
    padding: '6px 10px',
    fontSize: '10px'
  };

  const TABLE_COLUMNS = [
    {
      key: 'avatar',
      title: '',
      dataIndex: 'avatar',
      sortKey: 'avatar',
      width: 48,
      render: (): React.ReactElement => <Styled.UserAvatar src={GlbeeAvatar} alt='User Avatar' />
    },
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      sortKey: 'name',
      render: (name: string): React.ReactElement => <span data-label={'Name'}>{name}</span>
    },
    {
      key: 'email',
      title: 'Email',
      dataIndex: 'email',
      sortKey: 'email',
      render: (email: string): React.ReactElement => (
        <Styled.EmailField className='cell-value' data-label={'Email'}>
          <img src={CopyEmail} onClick={handleCopyEmailClick(email)} alt='Copy email' />
          <span>{email}</span>
        </Styled.EmailField>
      )
    },
    {
      key: 'teamPermissions',
      title: 'Team Permissions',
      dataIndex: 'teamPermissions',
      sortKey: 'teamPermissions',
      render: ({
        rowId,
        permissionsLevel,
        memberId
      }: {
        rowId: number;
        memberId: number;
        permissionsLevel: ETeamRole;
      }): React.ReactElement | null => {
        const showPermissionsSelect = checkPermissionsVisibility(permissionsLevel);
        return (
          <Styled.UserPermissionsContainer>
            {showPermissionsSelect && (
              <Styled.RowSelect
                $isActive={activePermissionsSelectId === rowId}
                className='permissions-select'
              >
                {PERMISSIONS[teamRole].map(
                  ({ title, value, tooltip }): React.ReactElement => (
                    <Styled.SelectItemContainer
                      $isActive={value === permissionsLevel}
                      $checkHoverIcon={CheckedBlack}
                      $hintHoverIcon={TooltipIconBlack}
                      onClick={handlePermissionsLevelClick(memberId, value)}
                      key={title}
                    >
                      <Styled.CheckIcon $isActive={value === permissionsLevel} $icon={Checked} />
                      <Styled.RowSelectItem>{title}</Styled.RowSelectItem>
                      <Tooltip
                        color={'rgba(83, 83, 83, 0.7)'}
                        title={tooltip}
                        overlayInnerStyle={TOOLTIP_STYLES}
                      >
                        <Styled.HintIcon $icon={TooltipIcon} />
                      </Tooltip>
                    </Styled.SelectItemContainer>
                  )
                )}
              </Styled.RowSelect>
            )}

            <Styled.UserPermission
              className='cell-value'
              data-label={'Team Permissions'}
              ref={rowSelectRef}
              onClick={handlePermissionsSelectClick(rowId)}
              $showPermissionsSelect={showPermissionsSelect}
            >
              <span>{capitalize(permissionsLevel)}</span>
              {showPermissionsSelect && <img src={Arrow} alt='ThemeSettings' />}
            </Styled.UserPermission>
          </Styled.UserPermissionsContainer>
        );
      }
    },
    {
      title: 'Last Active',
      dataIndex: 'lastActive',
      key: 'lastActive',
      sortKey: 'lastActive',
      render: (lastActive: string): React.ReactElement => (
        <span className='last-active' data-label={'Last Active'}>
          {lastActive}
        </span>
      )
    },
    {
      title: '',
      dataIndex: 'removeAction',
      key: 'removeAction',
      render: ({
        memberId,
        userId,
        permissionsLevel
      }: {
        memberId: number;
        userId?: number;
        permissionsLevel: ETeamRole;
      }): React.ReactElement | null => (
        <Styled.RowActionContainer>
          {checkRemoveButtonVisibility(permissionsLevel, userId) && (
            <img
              className='row-action'
              src={Remove}
              onClick={handleRemoveButtonClick(memberId, userId)}
              alt='Remove member'
            />
          )}
        </Styled.RowActionContainer>
      )
    }
  ];

  return (
    <Styled.PageContent>
      <Styled.PageHeader>
        <Styled.SearchField>
          <Styled.SearchInput
            value={searchInputValue}
            onChange={handleSearchInputChange}
            placeholder='Search'
          />
          <img src={Search} alt='Search' />
        </Styled.SearchField>
        {isManagePermission && (
          <Styled.AddMembersButton
            onClick={handleAddNewMembersClick}
            disabled={activeTeam?.seatsCount === activeTeam?.totalSeats}
          >
            Add New Members
          </Styled.AddMembersButton>
        )}
        {(teamRole === ETeamRole.OWNER ||
          teamRole === ETeamRole.ADMIN ||
          teamRole === ETeamRole.MANAGER) && (
          <Styled.SeatsData>
            <span>Seats:</span>
            <span className='data-value'>
              {`${activeTeam?.seatsCount || 0}/${activeTeam?.totalSeats || 0}`}
            </span>
          </Styled.SeatsData>
        )}
      </Styled.PageHeader>
      <Styled.TableContainer ref={tableRef}>
        <Table
          dataSource={tableTeamData}
          columns={TABLE_COLUMNS}
          size={'middle'}
          pagination={{
            current: currentPage,
            pageSize: 10,
            total: totalItems,
            hideOnSinglePage: true,
            showSizeChanger: false,
            position: ['bottomCenter']
          }}
          loading={isTeamDataLoading}
          onChange={handleTableChange}
        />
      </Styled.TableContainer>
    </Styled.PageContent>
  );
};

export default MembersTab;
