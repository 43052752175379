import { COLORS } from 'shared/constants/colors';
import styled from 'styled-components';
import { DEVICE } from 'shared/constants/deviceSizes';

export const CommentsBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  background: ${COLORS.deepBlack};
  overflow: hidden;
  color: ${COLORS.white};

  @media ${DEVICE.tabletLarge} {
    background: rgba(83, 83, 83, 0.4);
    backdrop-filter: blur(6px);
  }
`;

export const CommentsBarHeader = styled.div`
  width: 100%;
  height: 35px;
  min-height: 35px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NotificationsButton = styled.button`
  display: flex;
  align-items: center;
  height: 20px;
  background-color: var(--button-background-color);
  color: ${COLORS.black};
  font-size: 10px;
  line-height: 8px;
  font-weight: 600;
  padding: 0 7px;
  border-radius: 2px;
  opacity: 0.9;

  &:hover {
    opacity: 1;
  }

  img {
    width: 9px;
    height: 10px;
    margin-right: 6px;
  }
`;

export const SwitcherContainer = styled.div<{ $isActive: boolean }>`
  width: 76px;
  height: 20px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  background: #a3a3a3;
  transition: all 0.3s;
  position: relative;
  margin-left: auto;

  & > .switcher-wheel {
    position: absolute;
    top: 1px;
    width: 50%;
    height: 18px;
    background: var(--button-background-color);
    border-radius: 9px;
    transition: all 0.3s;
    transform: translateX(${({ $isActive }): string => ($isActive ? '1px' : 'calc(100% - 1px)')});
  }

  span {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 10px;
    line-height: 11px;
    color: ${COLORS.black};
    z-index: 1;
  }
`;

export const CommentsList = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
`;

export const CommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${COLORS.white};
  padding: 15px 10px;
  cursor: pointer;

  &:first-child {
    border-top: 1px solid ${COLORS.white};
  }

  &:hover {
    background: rgba(214, 215, 227, 0.08);
  }
`;

export const CommentDate = styled.div`
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;

  span:last-child {
    margin-left: 20px;
  }
`;

export const CommentHeader = styled.div`
  display: flex;
  align-items: baseline;
  margin-top: 10px;

  img {
    width: 12px;
    height: 12px;
  }
`;

export const CommentOwner = styled.div`
  margin-left: 10px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media${DEVICE.tabletLarge} {
    max-width: 180px;
  }
`;

export const CommentCreateAt = styled.div`
  margin-left: auto;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  white-space: nowrap;
  padding-left: 5px;
`;

export const CommentText = styled.div`
  margin-top: 10px;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;

  .mention {
    color: var(--theme-secondary-color);
  }
`;

export const NoCommentsText = styled.div`
  text-align: center;
  padding: 14px 10px;
  font-size: 12px;
`;

export const LoaderContainer = styled.div<{ $isCommentsLoading: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ $isCommentsLoading }): string => ($isCommentsLoading ? '60px' : '0px')};
  width: 100%;
  transition: all 0.5s;

  .spinner {
    display: ${({ $isCommentsLoading }): string => ($isCommentsLoading ? 'block' : 'none')};
    width: 30px;
    height: 30px;
  }
`;

export const CommentNotificationsBarContainer = styled.div`
  position: absolute;
  transform: translateX(calc(-100% - 8px));
  width: 190px;
  z-index: 1015;
  box-shadow: rgb(0 0 0 / 70%) -5px 10px 10px, rgb(0 0 0 / 30%) 0 1px 18px,
    rgb(0 0 0 / 60%) 0 30px 15px;

  @media ${DEVICE.tabletLarge} {
    transform: translate(0, 35px);
  }
`;
