import { COLORS } from 'shared/constants/colors';
import { FONTS } from 'shared/constants/fonts';
import styled from 'styled-components';

export const SelectContainer = styled.div<{ $isMenuActive: boolean }>`
  color: ${COLORS.white};
  font-family: ${FONTS.interRegular};
  font-size: 14px;
  line-height: 17px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const MainField = styled.div<{ $isMenuActive: boolean }>`
  position: relative;
  width: 100%;
  height: 42px;
  background: ${COLORS.deepBlack};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  transition: border-radius 0.1s ease-out 0.2s;
  border-bottom-left-radius: ${({ $isMenuActive }): string => ($isMenuActive ? '0' : '4px')};
  border-bottom-right-radius: ${({ $isMenuActive }): string => ($isMenuActive ? '0' : '4px')};

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    transform: scaleX(${({ $isMenuActive }): string => ($isMenuActive ? '1' : '0')});
    transition: all 0.2s ease-out;
    border-bottom: 1px solid ${COLORS.white};
  }

  & div {
    width: 100%;
    padding: 0 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & img {
    transform: ${({ $isMenuActive }): string => ($isMenuActive ? 'rotate(180deg)' : '0')};
    transition: all 0.3s;
    margin-right: 10px;
  }

  &:hover div {
    color: var(--theme-secondary-color);
  }
`;

export const SelectMenu = styled.div<{ $isMenuActive: boolean }>`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  max-height: ${({ $isMenuActive }): string => ($isMenuActive ? '200px' : '0px')};
  background: ${COLORS.deepBlack};
  position: absolute;
  z-index: 11;
  margin-top: 42px;
  transition: max-height 0.3s;
  border-radius: 0 0 4px 4px;
  box-shadow: rgb(0 0 0 / 70%) -5px 10px 10px, rgb(0 0 0 / 30%) 0 1px 18px,
    rgb(0 0 0 / 60%) 0 30px 15px;

  &::-webkit-scrollbar {
    width: 4px;
    height: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--theme-secondary-color);
  }
`;

export const OptionTitle = styled.span<{ $isAvailable: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  max-width: 98%;
  text-align: left;
  margin-left: 10px;
  color: ${({ $isAvailable }): string => ($isAvailable ? COLORS.white : COLORS.doveGray)};
`;

export const OptionItem = styled.div<{ $isActive: boolean; $isAvailable: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  height: 42px;
  min-height: 42px;
  padding: 0 28px 0 2px;
  cursor: pointer;
  background: ${({ $isActive }): string => ($isActive ? 'rgba(255, 255, 255, 0.1)' : 'transparent')};

  &:hover {
    background: var(--theme-secondary-color);

    ${OptionTitle} {
      color: var(--button-text-color);
    }
  }
`;

export const PreviewImageContainer = styled.div<{ $isNoEnvironment: boolean }>`
  height: 36px;
  width: 48px;
  min-width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ $isNoEnvironment }): string =>
    $isNoEnvironment ? COLORS.deepBlack : 'initial'};
`;

export const NoPreview = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.deepBlack};
  border-radius: 2px;

  img {
    width: 24px;
    height: 24px;
  }
`;

export const PreviewImage = styled.img<{ $isLighting: boolean; $isNoEnvironment: boolean }>`
  height: ${({ $isNoEnvironment }): string => ($isNoEnvironment ? '24px' : '100%')};
  width: ${({ $isNoEnvironment }): string => ($isNoEnvironment ? '24px' : '100%')};
  max-height: 36px;
  min-width: 48px;
  object-fit: ${({ $isLighting, $isNoEnvironment }): string =>
    $isLighting || $isNoEnvironment ? 'contain' : 'cover'};
  border-radius: 2px;
`;

export const Lock = styled.div`
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  right: 4px;
  transform: translateY(-50%);
  background: ${COLORS.turbo};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;

  img {
    width: 12px;
    height: 12px;
  }
`;
