import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import * as Styled from './styles';
import Plus from 'assets/images/plus-icon.svg';
import SecureIcon from 'assets/images/ip-secure-icon.svg';
import YellowCar from 'assets/images/yellow-car.png';
import YellowBee from 'assets/images/yellow-bee.png';
import ExampleRoom from 'assets/images/example-room.png';
import Hint from 'assets/images/hint.svg';
import { QuickViewIcon, NewModelIcon } from 'assets/dynamic-icons';
import { useAppDispatch, useAppSelector, useUploadFile } from 'shared/hooks';
import { AuthState, EModelType, SampleModel, TeamsState } from 'shared/types';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { showModal } from 'services/store/reducers/modalReducer';
import { ModelsSecure, CustomTooltip } from 'shared/components';

type Props = {
  loadSampleModel: (sampleName: SampleModel) => Promise<void>;
};

const ModelUploader: React.FC<Props> = ({ loadSampleModel }): JSX.Element => {
  const location = useLocation();
  const { isAuth } = useAppSelector((state): AuthState => state.auth);
  const { activeTeam } = useAppSelector((state): TeamsState => state.teams);
  const dispatch = useAppDispatch();
  const [isUploadMode, setIsUploadMode] = useState(location.pathname === '/upload');
  const { uploadModelToSpace, uploadModelToViewer } = useUploadFile();
  const TOOLTIP = {
    sampleModels:
      'Don’t have a 3D model right now? You can try the platform using our sample models. Simply click on any of these models to get started.',
    uploadMode: 'Use Upload to add and save a new model to your account',
    quickViewMode: 'Use Quick View to view a model without auto-saving it to your account'
  };

  useEffect((): void => {
    setIsUploadMode(location.pathname === '/upload');
  }, [location]);

  const handleSampleModelClick =
    (sampleName: SampleModel): ((event: React.MouseEvent<HTMLElement>) => void) =>
    async (event): Promise<void> => {
      event.stopPropagation();
      await loadSampleModel(sampleName);
    };

  const onDrop = async (files: FileWithPath[]): Promise<void> => {
    switch (true) {
      case !!activeTeam:
        await uploadModelToSpace(files, {
          spaceTitle: activeTeam!.teamName,
          spaceId: activeTeam!.id
        });
        break;
      case isUploadMode:
        isAuth
          ? await uploadModelToSpace(files)
          : await uploadModelToViewer(files, EModelType.ANONYMOUS);
        break;
      case !isUploadMode:
        await uploadModelToViewer(files, EModelType.QUICK_VIEW);
        break;
    }
  };

  const handleSecureIconClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    dispatch(showModal(<ModelsSecure />));
  };

  const toggleUploaderMode =
    (isUploadMode: boolean): ((event: React.MouseEvent<HTMLElement>) => void) =>
    (event): void => {
      event.stopPropagation();
      setIsUploadMode(isUploadMode);
    };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Styled.ModelUploaderWrapper>
      <Styled.ModelUploaderContainer {...getRootProps()}>
        <input {...getInputProps()} />
        <Styled.UploadField $isDragActive={isDragActive}>
          <Styled.UploadFieldContent>
            <Styled.PlusIcon src={Plus} alt='Upload' draggable={false} />
            <Styled.UploadDescription>
              <span>{`Drag and drop .GLB, .GLTF, .FBX, .OBJ or .STL file to ${
                isUploadMode ? 'upload' : 'quick view'
              }`}</span>
              <span>{`or select a file to ${isUploadMode ? 'upload' : 'quick view'}`}</span>
            </Styled.UploadDescription>
            <Styled.SampleModelsBlock>
              <Styled.SampleModelsTitle>
                <span>Sample Models</span>
                <CustomTooltip content={TOOLTIP.sampleModels}>
                  <img src={Hint} alt='Share link hint' />
                </CustomTooltip>
              </Styled.SampleModelsTitle>
              <Styled.SampleModels>
                <Styled.SampleModel
                  src={YellowCar}
                  className='yellow-car'
                  onClick={handleSampleModelClick(SampleModel.CAR)}
                  draggable={false}
                />
                <div className='dot' />
                <Styled.SampleModel
                  src={YellowBee}
                  className='yellow-bee'
                  onClick={handleSampleModelClick(SampleModel.BEE)}
                  draggable={false}
                />
                <div className='dot' />
                <Styled.SampleModel
                  src={ExampleRoom}
                  className='example-room'
                  onClick={handleSampleModelClick(SampleModel.VIKING_SCENE)}
                  draggable={false}
                />
              </Styled.SampleModels>
            </Styled.SampleModelsBlock>

            <Styled.UploaderModeSwitcher>
              <Styled.SwitcherWheel $isUploadMode={isUploadMode} />
              <CustomTooltip content={TOOLTIP.quickViewMode} placement={'bottom'}>
                <Styled.UploaderMode onClick={toggleUploaderMode(false)}>
                  <Styled.UploaderModeIconWrapper $isActive={!isUploadMode}>
                    <QuickViewIcon />
                  </Styled.UploaderModeIconWrapper>
                  <Styled.UploaderModeTitle>Quick View</Styled.UploaderModeTitle>
                </Styled.UploaderMode>
              </CustomTooltip>
              <CustomTooltip content={TOOLTIP.uploadMode} placement={'bottom'}>
                <Styled.UploaderMode onClick={toggleUploaderMode(true)}>
                  <Styled.UploaderModeIconWrapper $isActive={isUploadMode}>
                    <NewModelIcon />
                  </Styled.UploaderModeIconWrapper>
                  <Styled.UploaderModeTitle>Upload</Styled.UploaderModeTitle>
                </Styled.UploaderMode>
              </CustomTooltip>
            </Styled.UploaderModeSwitcher>
          </Styled.UploadFieldContent>
          <Styled.SecureIcon src={SecureIcon} onClick={handleSecureIconClick} draggable={false} />
        </Styled.UploadField>
      </Styled.ModelUploaderContainer>
    </Styled.ModelUploaderWrapper>
  );
};

export default ModelUploader;
