import { COLORS } from 'shared/constants/colors';
import { FONTS } from 'shared/constants/fonts';
import styled from 'styled-components';
import { PLAN_CARD } from '../constants';

export const PlanIcon = styled.img<{ $isModal?: boolean }>`
  height: ${({ $isModal }): string => ($isModal ? '40px' : '70px')};
  margin-top: ${({ $isModal }): string => ($isModal ? '20px' : '30px')};
`;

export const PlanWrapper = styled.div`
  width: 100%;
  height: max-content;
  padding: 10px;
`;

export const MostPopularLabel = styled.div<{ $isModal?: boolean }>`
  height: ${({ $isModal }): number => $isModal ? 36 : 45}px;  // PLAN_CARD.scale() does not scale the value correctly
  width: 100%;
  background: ${COLORS.turbo};
  margin-bottom: -10px;
  padding-bottom: 10px;
  border-radius: 6px 6px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 10px;
    height: 10px;
    margin-right: 6px;
  }

  span {
    margin-top: 3px;
    font-weight: 600;
    font-size: 13px;
    line-height: 14px;
    color: ${COLORS.black};
  }
`;

export const PlanContainer = styled.div<{ $isMostPopular: boolean; $isModal?: boolean }>`
  width: 100%;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(352.13deg, #1b1a1a 3.17%, #464646 103.41%);
  border-radius: 6px;
  color: ${COLORS.white};
  position: relative;
  margin-top: ${({ $isMostPopular, $isModal }): number =>
    $isMostPopular ? 0 : PLAN_CARD.scaleValue(35, $isModal)}px;

  @container (max-width: ${({ $isModal }): number => PLAN_CARD.getColumnWidth(2, $isModal)}px) {
    margin-top: 10px;
  }
`;

export const PlanIconContainer = styled.div<{ $isModal?: boolean }>`
  height: ${({ $isModal }): number => PLAN_CARD.scaleValue(60, $isModal)}px;
`;

export const PlanIconShadow = styled.div<{ $isModal?: boolean }>`
  position: absolute;
  top: ${({ $isModal }): number => PLAN_CARD.scaleValue(15, $isModal)}px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 80px;
  background: rgba(117, 117, 117, 0.59);
  opacity: 0.8;
  filter: blur(60px);
  border-radius: 50%;
`;

export const PlanTitle = styled.span<{ $isModal?: boolean }>`
  margin-bottom: 10px;
  font-size: ${({ $isModal }): number => PLAN_CARD.scaleValue(32, $isModal)}px;
  line-height: 100%;
`;

export const PaymentDescription = styled.span<{ $isModal?: boolean }>`
  font-weight: 200;
  text-align: center;
  font-size: ${({ $isModal }): number => PLAN_CARD.scaleValue(16, $isModal)}px;
  line-height: 130%;
  width: 85%;
`;

export const PriceSection = styled.div<{ $isModal?: boolean }>`
  margin-top: 30px;
  height: ${({ $isModal }): number => PLAN_CARD.scaleValue(32, $isModal)}px;
  color: ${COLORS.turbo};
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const CustomPrice = styled.span<{ $isModal?: boolean }>`
  font-weight: 400;
  font-size: ${({ $isModal }): number => PLAN_CARD.scaleValue(32, $isModal)}px;
  text-align: center;
  align-self: center;
`;

export const Currency = styled.span`
  margin-top: -10px;
  font-size: 16px;
`;

export const Price = styled.span<{ $isModal?: boolean }>`
  padding: 5px;  
  font-weight: 400;
  font-size: ${({ $isModal }): number => PLAN_CARD.scaleValue(32, $isModal)}px;
`;

export const Duration = styled.span<{ $isModal?: boolean }>`
  font-size: ${({ $isModal }): number => PLAN_CARD.scaleValue(16, $isModal)}px;
`;

export const PlanDescription = styled.div<{ $isModal?: boolean }>`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  font-size: ${({ $isModal }): number => PLAN_CARD.scaleValue(16, $isModal)}px;
  font-weight: 200;
  line-height: 180%;
`;

export const PlanSpotlightFeature = styled.span<{ $isActive?: boolean }>`
  color: ${({ $isActive }): string => ($isActive ? COLORS.white : COLORS.doveGray)};
`;

export const SeeAllFeatures = styled.span<{ $isModal?: boolean }>`
  margin-top: 30px;
  font-weight: 600;
  text-decoration: underline;
  font-size: ${({ $isModal }): number => PLAN_CARD.scaleValue(16, $isModal)}px;
  cursor: pointer;
`;

export const SelectButton = styled.button<{ $isActive: boolean; $isModal?: boolean }>`
  margin-top: ${({ $isModal }): number => PLAN_CARD.scaleValue(30, $isModal)}px;
  width: ${({ $isModal }): number => PLAN_CARD.scaleValue(160, $isModal)}px;
  height: ${({ $isModal }): number => PLAN_CARD.scaleValue(40, $isModal)}px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 4px;
  font-size: ${({ $isModal }): number => PLAN_CARD.scaleValue(16, $isModal)}px;
  line-height: ${({ $isModal }): number => PLAN_CARD.scaleValue(15, $isModal)}px;
  background: ${({ $isActive }): string => ($isActive ? COLORS.doveGray : COLORS.turbo)};
  font-family: ${FONTS.interRegular};
  color: ${COLORS.black};
  cursor: ${({ $isActive }): string => ($isActive ? 'initial' : 'pointer')};

  &:hover {
    background: ${({ $isActive }): string => ($isActive ? COLORS.turbo : COLORS.gorse)};
  }
`;
