import { COLORS } from 'shared/constants/colors';
import styled from 'styled-components';

export const SwitcherContainer = styled.div<{
  $isActive: boolean;
  $isDisabled: boolean;
  $isSmall: boolean;
  $withoutCustomStyle: boolean;
}>`
  width: ${({ $isSmall }): string => ($isSmall ? '32px' : '60px')};
  height: ${({ $isSmall }): string => ($isSmall ? '18px' : '30px')};
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background: ${({ $isDisabled, $isActive, $withoutCustomStyle }): string =>
    $isDisabled
      ? COLORS.doveGray
      : $isActive
      ? $withoutCustomStyle
        ? COLORS.corn
        : 'var(--theme-secondary-color)'
      : COLORS.codGray};
  transition: all 0.3s;

  .switcher-wheel {
    width: ${({ $isSmall }): string => ($isSmall ? '14px' : '24px')};
    height: ${({ $isSmall }): string => ($isSmall ? '14px' : '24px')};
    background: ${COLORS.galleryGradient};
    border-radius: 50%;
    transition: all 0.3s;
    transform: translateX(
      ${({ $isActive, $isSmall }): string =>
        $isSmall ? ($isActive ? '16px' : '2px') : $isActive ? '32px' : '2px'}
    );
  }
`;
