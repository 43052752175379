import React from 'react';
import * as Styled from './styles';
import LogoCubeIcon from 'assets/images/logo-cube.png';
import { MAX_VERTICES, MAX_TEXTURES } from 'shared/constants/performance-model-warning';
import { formatNumberToNumberWithCommas } from 'utils/form-utils';

const ModalPerformanceWarning: React.FC = () => {
  return (
    <Styled.ModalWindowContainer>
      <Styled.ModalTitle>Uh oh!</Styled.ModalTitle>
      <Styled.ModalText>Your model is <span>too resource-intensive</span> for the Browser. For optimal performance, <span>we suggest less than</span>:</Styled.ModalText>
      <Styled.ModalList>
        <Styled.ModalText>{formatNumberToNumberWithCommas(MAX_VERTICES)} vertices</Styled.ModalText>
        <Styled.ModalText>{formatNumberToNumberWithCommas(MAX_TEXTURES['2048'])} textures with 2k resolution</Styled.ModalText>
        <Styled.ModalText>{formatNumberToNumberWithCommas(MAX_TEXTURES['4096'])} textures with 4k resolution</Styled.ModalText>
        <Styled.ModalText>{formatNumberToNumberWithCommas(MAX_TEXTURES['4096+'])} textures exceeding 4k resolution</Styled.ModalText>
      </Styled.ModalList>

      <Styled.ModalFooter>
        <Styled.LogoCube src={LogoCubeIcon} />
      </Styled.ModalFooter>
    </Styled.ModalWindowContainer>
  );
};

export default ModalPerformanceWarning;
