import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import * as Styled from './styles';
import IPSecureIcon from 'assets/images/ip-secure-icon.svg';
import Plus from 'assets/images/plus-icon-black.svg';
import { ContentHeader } from 'shared/styles';
import { BillingTab, CustomizationTab, MyModelsTab, SubscriptionTab, UsageTab } from './tabs';
import {
  ModalCheckout,
  ModelsSecure,
  NavigationPrevent,
  SearchSelect
} from 'shared/components';
import {
  useAppDispatch,
  useAppSelector,
  useMyWorkspaceData,
  useScreenDragEvent,
  useUploadFile
} from 'shared/hooks';
import { stopLoader } from 'services/store/reducers/loaderReducer';
import { usePageTopPositionContext } from 'shared/providers';
import {
  AuthState,
  EConfirmModalHeader,
  EFeatureId,
  ESnackbarStyle,
  MyWorkspacePageTab,
  SubscriptionsState
} from 'shared/types';
import { FileWithPath, useDropzone } from 'react-dropzone';
import ExclamationCircleFilled from '@ant-design/icons/lib/icons/ExclamationCircleFilled';
import { showConfirmModal, showModal } from 'services/store/reducers/modalReducer';
import { openNotification } from 'utils/notification-utils';
import { useHistory, useLocation } from 'react-router-dom';
import { setupUserPaymentMethod } from 'services/api/subscriptionsService';
import { setCurrentUserCards } from 'services/store/reducers/subscriptionsReducer';
import {
  BillingIcon,
  CustomizationIcon,
  SubscriptionIcon,
  TeamIcon,
  UsageIcon
} from 'assets/dynamic-icons';

type TabData = {
  id: MyWorkspacePageTab;
  title: string;
  icon: React.ReactElement;
  className?: string;
  isAvailable: boolean;
};

const MyWorkspacePage = (): React.ReactElement => {
  const location = useLocation();
  const params = useMemo(
    (): URLSearchParams => new URLSearchParams(location.search),
    [location.search]
  );
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { features } = useAppSelector((store): AuthState => store.auth);
  const { subscriptionPlans } = useAppSelector((store): SubscriptionsState => store.subscriptions);
  const [activeTab, setActiveTab] = useState<MyWorkspacePageTab>(MyWorkspacePageTab.MY_MODELS);
  const { isDragActive } = useScreenDragEvent();
  const { uploadDroppedModelsToSpace } = useUploadFile();
  const {
    fetchModel,
    modelsData,
    setSearch,
    isSearchMode,
    setIsSearchMode,
    handlePageChange,
    resetModelList,
    updateModelList
  } = useMyWorkspaceData();

  const { pageTop } = usePageTopPositionContext();

  useLayoutEffect((): void => {
    pageTop?.current?.scrollIntoView({ behavior: 'auto' });
  }, [pageTop, activeTab]);

  useEffect(
    (): (() => void) => (): void => {
      dispatch(stopLoader());
    },
    [dispatch]
  );

  const clearParams = useCallback(
    (paramItems: string[]): void => {
      paramItems.forEach((item): void => params.delete(item));
      history.replace({ search: params.toString() });
    },
    [history, params]
  );

  useEffect((): void => {
    const paymentRedirectStatus = params.get('redirect_status');
    const setupIntent = params.get('setup_intent');
    const isCheckout = params.get('checkout');
    const planId = params.get('plan');
    const isMonthlyType = !!+(params.get('monthly') || '');
    if (!!paymentRedirectStatus) {
      clearParams([
        'redirect_status',
        'setup_intent',
        'setup_intent_client_secret',
        'checkout',
        'plan',
        'monthly'
      ]);
      if (paymentRedirectStatus === 'failed') {
        openNotification(ESnackbarStyle.ERROR, 'Payment failed');
        return;
      }
      if (paymentRedirectStatus === 'succeeded' && !!setupIntent) {
        (async (): Promise<void> => {
          const newUserCard = (await setupUserPaymentMethod(setupIntent)).data;
          dispatch(setCurrentUserCards([newUserCard]));
          const plan = subscriptionPlans.find(({ id }): boolean => id === planId);
          if (isCheckout && !!plan) {
            setActiveTab(MyWorkspacePageTab.SUBSCRIPTION);
            dispatch(
              showConfirmModal({
                header: EConfirmModalHeader.CHECKOUT,
                content: <ModalCheckout plan={plan} isMonthlyType={isMonthlyType} />
              })
            );
          }
        })();
        openNotification(ESnackbarStyle.SUCCESS, 'Your card has been successfully added');
      }
    }
  }, [clearParams, dispatch, params, subscriptionPlans]);

  const handleMainTabClick =
    (tabId: MyWorkspacePageTab): (() => void) =>
    (): void => {
      setActiveTab(tabId);
    };
  
  const switchPage = async (): Promise<void> => {
    await handlePageChange();
  };

  const MAIN_TABS: TabData[] = [
    {
      id: MyWorkspacePageTab.MY_MODELS,
      title: 'Models',
      icon: <TeamIcon />,
      className: 'models-tab',
      isAvailable: true
    },
    {
      id: MyWorkspacePageTab.SUBSCRIPTION,
      title: 'Subscription',
      icon: <SubscriptionIcon />,
      className: 'subscription-tab',
      isAvailable: true
    },
    {
      id: MyWorkspacePageTab.CUSTOMIZATION,
      title: 'Customization',
      icon: <CustomizationIcon />,
      className: 'customization-tab',
      isAvailable: !!features.find((item): boolean => item.id === EFeatureId.CUSTOM_UI_BRANDING)
        ?.enabled
    },
    {
      id: MyWorkspacePageTab.BILLING,
      title: 'Billing',
      icon: <BillingIcon />,
      isAvailable: true
    },
    {
      id: MyWorkspacePageTab.USAGE,
      title: 'Usage',
      icon: <UsageIcon />,
      className: 'usage-tab',
      isAvailable: true
    }
  ];

  const TAB_CONTENT: Record<MyWorkspacePageTab, React.ReactElement> = {
    [MyWorkspacePageTab.MY_MODELS]: (
      <MyModelsTab
        updateModelList={updateModelList}
        resetModelList={resetModelList}
        modelsData={modelsData}
        switchPage={switchPage}
      />
    ),
    [MyWorkspacePageTab.USAGE]: <UsageTab />,
    [MyWorkspacePageTab.CUSTOMIZATION]: <CustomizationTab />,
    [MyWorkspacePageTab.SUBSCRIPTION]: <SubscriptionTab />,
    [MyWorkspacePageTab.BILLING]: <BillingTab />
  };

  const AVAILABLE_TABS = MAIN_TABS.filter(({ isAvailable }): boolean => isAvailable);

  const handleSearchInputChange = useCallback(
    (value: string): void => {
      setSearch(value);
    },
    [setSearch]
  );

  const handleSearchListItemClick = async (modelId: string): Promise<void> => {
    await fetchModel(modelId);
  };

  const handleSecureIconClick = (): void => {
    dispatch(showModal(<ModelsSecure />));
  };

  const onDrop = async (files: FileWithPath[]): Promise<void> => {
    await uploadDroppedModelsToSpace(files);
    await resetModelList();
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop, noClick: true });

  return (
    <NavigationPrevent>
      <Styled.PageContainer>
        <Styled.UploadModelOverlay
          {...getRootProps()}
          $isDragActive={
            isDragActive && (activeTab !== MyWorkspacePageTab.MY_MODELS || !!modelsData.length)
          }
        >
          <input {...getInputProps()} id='my-space-drag-drop-input' />
          <Styled.PlusIcon src={Plus} alt='Upload model' />
          <Styled.UploadDescription>
            Release the file to upload it to this workspace
          </Styled.UploadDescription>
        </Styled.UploadModelOverlay>
        <Styled.PageContent>
          <ContentHeader>
            <Styled.HeaderContent>
              <Styled.HeaderTopContent>
                <Styled.WorkspaceName>My Workspace</Styled.WorkspaceName>
              </Styled.HeaderTopContent>
              <Styled.HeaderBottomContent>
                <Styled.TabsContainer>
                  {AVAILABLE_TABS.map(
                    ({ id, icon, title, className }): JSX.Element => (
                      <Styled.PageTab
                        className={className}
                        $isActiveTab={id === activeTab}
                        onClick={handleMainTabClick(id)}
                        key={id}
                      >
                        <Styled.TabIconContainer className={className}>
                          {icon}
                        </Styled.TabIconContainer>
                        <span>{title}</span>
                      </Styled.PageTab>
                    )
                  )}
                </Styled.TabsContainer>
                {activeTab === MyWorkspacePageTab.MY_MODELS && (
                  <Styled.SearchSelectContainer>
                    <SearchSelect
                      inputAction={handleSearchInputChange}
                      listAction={handleSearchListItemClick}
                      options={isSearchMode ? modelsData.slice(0, 5) : []}
                      setIsSearchMode={setIsSearchMode}
                    />
                  </Styled.SearchSelectContainer>
                )}
              </Styled.HeaderBottomContent>
            </Styled.HeaderContent>
          </ContentHeader>
          {TAB_CONTENT[activeTab]}
        </Styled.PageContent>
        {(activeTab !== MyWorkspacePageTab.MY_MODELS || !!modelsData.length) && (
          <Styled.PageFooter>
            <Styled.DragDropMessageContainer>
              <ExclamationCircleFilled className='info-icon' />
              <Styled.DragDropMessage>
                Drag and drop model files anywhere into the window to save it to this workspace
              </Styled.DragDropMessage>
              <Styled.SecureIcon
                src={IPSecureIcon}
                onClick={handleSecureIconClick}
                alt='Security'
              />
            </Styled.DragDropMessageContainer>
          </Styled.PageFooter>
        )}
      </Styled.PageContainer>
    </NavigationPrevent>
  );
};

export default MyWorkspacePage;
