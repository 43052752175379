import React, { useEffect, useMemo } from 'react';
import * as Styled from './styles';
import { EConfirmModalHeader, EModelAccessLevel, ESnackbarStyle, EModelType } from 'shared/types';
import Revoke from 'assets/images/revoke.svg';
import CopyLink from 'assets/images/copy-link.svg';
import Checked from 'assets/images/check.svg';
import { useFormik } from 'formik';
import { validation } from 'services/validation';
import { getFieldError } from 'utils/form-utils';
import { useAppDispatch, useAppSelector, useCopyToClipboard } from 'shared/hooks';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { inviteUser } from 'services/api/permissionsService';
import {
  INVITE_SUCCESSFULLY_SENT,
  RESTRICT_FEATURE,
  UPDATE_YOUR_ACCOUNT
} from 'shared/constants/notifications';
import ShareTooltip from 'assets/images/hint.svg';
import { UserAccess } from './components/user-access';
import { MODEL_ACCESSES } from './constants';
import {
  changePublicAccessLevel,
  fetchModelPermissions
} from 'services/store/reducers/modelPermissionsReducer';
import { closeModal, showConfirmModal } from 'services/store/reducers/modalReducer';
import { RootState } from 'services/store';
import { ModalRevokeAccess, CustomTooltip } from 'shared/components';
import { openNotification } from 'utils/notification-utils';
import { openAlert } from 'utils/alert-utils';

type AccessItem = {
  title: string;
  access: EModelAccessLevel;
  isAvailable: boolean;
};

const ModalSharingPermissions = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const controller = useMemo((): AbortController => new AbortController(), []);
  const signal: AbortSignal = controller.signal;
  const store = useAppSelector((store): RootState => store);
  const { model, modelType } = store.viewerData;
  const { user } = store.auth;
  const { publicPermissions, permissions, isPublishedModel, teamPermissions } =
    store.modelPermissions;
  const { hasEditAccess, hasArViewAccess, hasCommentAccess } = store.modelFeatureAccess;
  const { copyToClipboard } = useCopyToClipboard();
  const isModelOwner = model?.ownerId === user?.id;
  const isTeamModel = modelType === EModelType.TEAM;

  useEffect(
    (): (() => void) => (): void => {
      controller.abort();
    },
    [controller]
  );

  useEffect((): void => {
    if (!hasEditAccess) {
      dispatch(closeModal());
    }
  }, [dispatch, hasEditAccess]);

  const initialValues: { email: string } = {
    email: ''
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async ({ email }): Promise<void> => {
      if (!!model) {
        dispatch(startLoader());
        try {
          await inviteUser(model.id, { email, accessLevel: EModelAccessLevel.VIEW_ACCESS });
          await dispatch(fetchModelPermissions({ model, signal }));
          formik.resetForm();
          openNotification(ESnackbarStyle.SUCCESS, INVITE_SUCCESSFULLY_SENT);
        } catch (e) {
          openNotification(ESnackbarStyle.ERROR, e?.message);
        } finally {
          dispatch(stopLoader());
        }
      }
    },
    validationSchema: validation.EMAIL_FORM
  });

  const handleEnterClick = async (event: React.KeyboardEvent<HTMLInputElement>): Promise<void> => {
    if (event.key === 'Enter') formik.handleSubmit();
  };

  const handleAddUserButtonClick = async (): Promise<void> => {
    formik.handleSubmit();
  };

  const handleCopyLinkButtonClick = async (): Promise<void> => {
    await copyToClipboard(model!.shortLinkUrl);
  };

  const handleRevokeAllButtonClick = async (): Promise<void> => {
    dispatch(
      showConfirmModal({
        header: null,
        content: <ModalRevokeAccess model={model!} />
      })
    );
  };

  const handlePublicAccessChange =
    ({ access, isAvailable }: AccessItem): (() => Promise<void>) =>
    async (): Promise<void> => {
      if (!isAvailable) {
        isModelOwner
          ? openNotification(ESnackbarStyle.HOLD_UP, UPDATE_YOUR_ACCOUNT)
          : openAlert(RESTRICT_FEATURE);
        return;
      }

      const isViewAccess = access === EModelAccessLevel.VIEW_ACCESS;
      const hasThisAccess = publicPermissions.includes(access);
      let resultAccessLevel: EModelAccessLevel[];
      if (hasThisAccess) {
        resultAccessLevel = isViewAccess
          ? []
          : publicPermissions.filter((level): boolean => level !== access);
      } else {
        resultAccessLevel = isViewAccess
          ? [EModelAccessLevel.VIEW_ACCESS]
          : Array.from(new Set([...publicPermissions, EModelAccessLevel.VIEW_ACCESS, access]));
      }
      await dispatch(changePublicAccessLevel({ accessLevel: resultAccessLevel, model: model! }));
    };

  const getTitleTooltip = (): JSX.Element => (
    <div>
      Share, invite, or revoke access to your model publicly or on an individual basis. Granularly
      adjust if others can <b>view, comment</b> and <b>edit</b> settings on your model.
    </div>
  );

  const getPublicTooltip = (): JSX.Element => (
    <div>
      Set the global sharing persmissions for your <i>public</i> sharing link. Any settings changed
      to <i>public</i> affects how anyone with the sharing link interacts with your model.
    </div>
  );

  const getPrivateTooltip = (): JSX.Element => (
    <div>
      Set the <b>private</b> sharing permissions for individual users that are added to your model.
      You can enable additional permissions for specific users or even disable public settings so
      that only specific people can access your model.
    </div>
  );

  const modelAccesses = MODEL_ACCESSES.map((item): AccessItem => {
    if (item.access === EModelAccessLevel.VIEW_AR_ACCESS) {
      return { ...item, isAvailable: hasArViewAccess };
    }
    if (item.access === EModelAccessLevel.COMMENT_ACCESS) {
      return { ...item, isAvailable: hasCommentAccess };
    }
    return item;
  });

  return (
    <Styled.ModalWindowContainer>
      <Styled.ModalHeader>
        <Styled.ModalTitle>
          <h1>Edit Sharing Permissions</h1>
          <CustomTooltip content={getTitleTooltip()}>
            <img src={ShareTooltip} alt='Sharing Permissions Tooltip' />
          </CustomTooltip>
        </Styled.ModalTitle>
        <Styled.ModalSubTitle>
          Set the public sharing link settings, or keep the model private and add specific people
          into your model
        </Styled.ModalSubTitle>
      </Styled.ModalHeader>
      <Styled.AddPeopleFieldContainer>
        <Styled.FieldLabel>Add people</Styled.FieldLabel>
        <Styled.AddPeopleField>
          <Styled.InputContainer>
            <Styled.AddPeopleInput
              value={formik.values.email}
              name='email'
              onChange={formik.handleChange}
              onKeyUp={handleEnterClick}
              onBlur={formik.handleBlur}
              type='email'
              placeholder='Enter email address'
            />
            <Styled.InputFooter>
              {getFieldError(formik, 'email') || (
                <>
                  {!!formik.values.email.length && (
                    <span className='hint'>Press enter to submit</span>
                  )}
                </>
              )}
            </Styled.InputFooter>
          </Styled.InputContainer>
          <Styled.AddUserButton
            onClick={handleAddUserButtonClick}
            disabled={!formik.values.email.length}
          >
            Add User
          </Styled.AddUserButton>
        </Styled.AddPeopleField>
      </Styled.AddPeopleFieldContainer>

      <Styled.PermissionsTableContainer>
        <Styled.PermissionsTable>
          <Styled.TableHeader>
            <tr>
              <th />
              {modelAccesses.map(
                ({ title, isAvailable }): JSX.Element => (
                  <Styled.ColumnTitle $isAvailable={isAvailable} key={title}>
                    {title}
                  </Styled.ColumnTitle>
                )
              )}
            </tr>
          </Styled.TableHeader>
          <Styled.TableBody className='public-table-body'>
            <tr>
              <td className='public'>
                <span>Public Permissions</span>
                <CustomTooltip content={getPublicTooltip()}>
                  <img src={ShareTooltip} alt='Public Access Tooltip' />
                </CustomTooltip>
              </td>
              {modelAccesses.map(
                (item): JSX.Element => (
                  <td key={item.title}>
                    <Styled.AccessCheckbox
                      onClick={handlePublicAccessChange(item)}
                      $isAvailable={item.isAvailable}
                    >
                      {publicPermissions.includes(item.access) && (
                        <img src={Checked} alt='Checked' />
                      )}
                    </Styled.AccessCheckbox>
                    <Styled.AccessTitle $isAvailable={item.isAvailable}>
                      {item.title}
                    </Styled.AccessTitle>
                  </td>
                )
              )}
            </tr>
          </Styled.TableBody>
        </Styled.PermissionsTable>
        <Styled.PermissionsTable>
          <Styled.TableHeader>
            <tr>
              <th>
                <span>Private Permissions</span>
                <CustomTooltip content={getPrivateTooltip()}>
                  <img src={ShareTooltip} alt='Private Access Tooltip' />
                </CustomTooltip>
              </th>
              {modelAccesses.map(
                ({ title, isAvailable }): JSX.Element => (
                  <Styled.ColumnTitle $isAvailable={isAvailable} key={title}>
                    {title}
                  </Styled.ColumnTitle>
                )
              )}
            </tr>
          </Styled.TableHeader>
          <Styled.TableBody>
            {permissions.map(
              (permission): JSX.Element => (
                <UserAccess
                  model={model!}
                  permission={permission}
                  isArAvailable={hasArViewAccess}
                  isCommentsAvailable={hasCommentAccess}
                  key={permission.id}
                />
              )
            )}
          </Styled.TableBody>
        </Styled.PermissionsTable>
      </Styled.PermissionsTableContainer>

      {isTeamModel && (
        <Styled.ModalBottomTeamMessage>
          Shared with everyone from {teamPermissions?.team.teamName || 'team'}.
        </Styled.ModalBottomTeamMessage>
      )}

      <Styled.ModalFooter>
        {!!permissions.length && (
          <Styled.FooterButton onClick={handleRevokeAllButtonClick}>
            <img src={Revoke} alt='Revoke all access' />
            <span id='revoke-all-access-button'>Revoke all access</span>
          </Styled.FooterButton>
        )}
        {isPublishedModel && (
          <Styled.FooterButton className='copy-link' onClick={handleCopyLinkButtonClick}>
            <img src={CopyLink} alt='Copy link' />
            <span>Copy link</span>
          </Styled.FooterButton>
        )}
      </Styled.ModalFooter>
    </Styled.ModalWindowContainer>
  );
};

export default ModalSharingPermissions;
