import HivePlanIcon from 'assets/images/plan-icon-4.png';
import { EFeatureCategory } from '../types/enums';

export const CUSTOM_PLAN = {
  icon: HivePlanIcon,
  title: 'Hive',
  subtitle: 'Enterprise Plan',
  description: 'For enterprise-scale hosting and collaboration',
  monthPrice: 0,
  yearPrice: 0,
  isMostPopular: false,
  categorizedFeatures: {
    [EFeatureCategory.SPOTLIGHT]: [
      { title: 'Models Max', value: 'Custom' },
      { title: 'Max Model Size', value: '100MB' },
      { title: 'Augmented Reality Viewing', value: true },
      { title: '3D Comments', value: true },
      { title: 'Custom Branding', value: true },
    ],
    [EFeatureCategory.HOSTING]: [
      { title: 'Maximum Models on Account', value: 'Custom' },
      { title: 'Maximum Size of Each Model', value: '100MB' },
      { title: 'Embed Models on Your Website', value: true },
      { title: 'Embedded Models Custom Buttons', value: true },
      { title: 'Embedded Models Allow List', value: true }
    ],
    [EFeatureCategory.VIEWING]: [
      { title: 'Breakdown of Materials', value: true },
      { title: 'Breakdown of Geometry', value: true },
      { title: 'Breakdown of Textures', value: true },
      { title: 'View Animations on Models', value: true },
      { title: 'View Your Models in 3D', value: true },
      { title: 'Augmented Reality Viewing', value: true },
      { title: 'All Cubemap Options', value: true },
      { title: 'All Lighting Options', value: true },
      { title: 'All Environment Options', value: true }
    ],
    [EFeatureCategory.SHARING]: [
      { title: '3D Views Per Month', value: 'Custom' },
      { title: 'AR Views Per Month', value: 'Unlimited' },
      { title: 'Access Controls', value: true },
      { title: 'Shortlink Creation', value: true },
      { title: 'QR Code Generation', value: true },
      { title: 'Download Models', value: true },
      { title: '3D Comments', value: true },
      { title: 'Custom Branding', value: true },
      { title: 'Custom 3D Environments', value: true }
    ]
  }
};
