import React, { useState } from 'react';
import { BrandOption } from 'shared/types';
import * as Styled from './styles';
import ArrowDownIcon from 'assets/images/arrow-down.svg';
import { useHandleClickOutside } from 'shared/hooks';
import { convertStringToButtonId } from 'utils/form-utils';

type Props = {
  activeOption: BrandOption;
  onChangeOption: (value: BrandOption) => void;
  options: BrandOption[];
};

const Select: React.FC<Props> = ({ activeOption, onChangeOption, options }): JSX.Element => {
  const [isMenuActive, setIsMenuActive] = useState<boolean>(false);

  const handleOptionClick =
    (option: BrandOption): (() => void) =>
    (): void => {
      setIsMenuActive(false);
      onChangeOption(option);
    };

  const toggleMenuActivity = (): void => {
    setIsMenuActive((state): boolean => !state);
  };

  const ref = useHandleClickOutside((): void => setIsMenuActive(false));

  return (
    <Styled.SelectContainer $isMenuActive={isMenuActive} ref={ref}>
      <Styled.MainField
        id='branding-select'
        $isMenuActive={isMenuActive}
        onClick={toggleMenuActivity}
      >
        <div>{activeOption.title}</div>
        <img src={ArrowDownIcon} alt='Arrow' />
      </Styled.MainField>
      <Styled.SelectMenu $isMenuActive={isMenuActive}>
        {options.map(
          (option): JSX.Element => (
            <Styled.OptionItem
              $isActive={activeOption.title === option.title}
              onClick={handleOptionClick(option)}
              id={convertStringToButtonId(option.title)}
              key={option.title}
            >
              <span>{option.title}</span>
            </Styled.OptionItem>
          )
        )}
      </Styled.SelectMenu>
    </Styled.SelectContainer>
  );
};

export default Select;
