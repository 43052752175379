import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import * as Styled from './styles';
import { PageContainer, PageTitle } from 'shared/styles';
import { AuthState, UserTableStatItem } from 'shared/types';
import moment from 'moment';
import { Table, TablePaginationConfig } from 'antd';
import { TABLE_COLUMNS } from './constants';
import { useAdminData, useAppDispatch, useAppSelector, useDebounce } from 'shared/hooks';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { convertBytesToMegabytes } from 'utils/model-utils';
import { useHistory } from 'react-router-dom';
import { showConfirmModal } from 'services/store/reducers/modalReducer';
import { ModalResetViews } from 'shared/components';

const AdminPanelPage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const userStatisticsRef = useRef<HTMLElement | null>(null);
  const { isMasterAdmin } = useAppSelector((state): AuthState => state.auth);
  const [tableUsersData, setTableUsersData] = useState<UserTableStatItem[]>([]);
  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const debouncedSearchValue = useDebounce(searchInputValue, 1000);
  const {
    fetchPlatformSummary,
    fetchUserStatistics,
    userStatistics,
    isStatisticsLoading,
    setSearchValue,
    setCurrentPage,
    currentPage,
    totalItems,
    platformSummary
  } = useAdminData();

  useLayoutEffect((): void => {
    if (!isMasterAdmin) {
      history.push('/');
    }
  }, [history, isMasterAdmin]);

  useEffect((): void => {
    if (isMasterAdmin) {
      fetchUserStatistics();
    }
  }, [fetchUserStatistics, isMasterAdmin]);

  useEffect((): void => {
    if (isMasterAdmin) {
      fetchPlatformSummary();
    }
  }, [fetchPlatformSummary, isMasterAdmin]);

  useEffect((): void => {
    setSearchValue(debouncedSearchValue);
    setCurrentPage(1);
  }, [debouncedSearchValue, setSearchValue, setCurrentPage]);

  console.log(userStatistics, platformSummary);

  useEffect((): void => {
    const tableUsersData: UserTableStatItem[] = userStatistics.map(
      ({
        userId,
        name,
        email,
        status,
        createdAt,
        uploads,
        plan,
        lastActivity,
        billing
      }): UserTableStatItem => ({
        key: userId,
        name,
        email,
        uploads,
        plan: !!plan && !!billing ? `${plan}, ${billing}` : '-',
        createdAt: moment(createdAt).format('DD.MM.YYYY'),
        lastActivity: moment(lastActivity).format('DD.MM.YYYY'),
        status,
        resetViews: { userId }
      })
    );
    setTableUsersData(tableUsersData);
  }, [userStatistics]);

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchInputValue(event.target.value);
  };

  const handleResetViewsClick =
    (userId: number): (() => Promise<void>) =>
    async (): Promise<void> => {
      dispatch(showConfirmModal({ content: <ModalResetViews userId={userId} />, header: null }));
    };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<UserTableStatItem> | SorterResult<UserTableStatItem>[]
  ): void => {
    if (!!pagination.current) {
      setCurrentPage(pagination.current);
      userStatisticsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const { users, totalUploadedModels, totalModelsSize, totalPlan } = platformSummary;

  const tableColumns = [
    ...TABLE_COLUMNS,
    {
      title: '',
      dataIndex: 'resetViews',
      key: 'resetViews',
      render: ({ userId }: { userId: number }): JSX.Element => (
        <Styled.ResetViewsButton onClick={handleResetViewsClick(userId)}>
          Reset views
        </Styled.ResetViewsButton>
      )
    }
  ];

  const tableColumnsPlatform = ['Type', 'Platform total', 'Average per model*'].map((type) => ({
    title: type,
    dataIndex: type.replaceAll(' ', '').toLowerCase(),
    key: type.replaceAll(' ', '').toLowerCase(),
    render: (name: string): JSX.Element => <span data-label={'Name'}>{name}</span>
  }));

  console.log(tableColumnsPlatform);

  const platformSummaryData = React.useMemo(
    () => [
      {
        type: 'Public views',
        platformtotal: platformSummary.publicViews.totalViews,
        'averagepermodel*': Math.round(platformSummary.publicViews.averagePerModel * 100) / 100
      },
      {
        type: 'AR views',
        platformtotal: platformSummary.arViews.totalViews,
        'averagepermodel*': Math.round(platformSummary.arViews.averagePerModel * 100) / 100
      },
      {
        type: 'Embedded views',
        platformtotal: platformSummary.embeddedViews.totalViews,
        'averagepermodel*': Math.round(platformSummary.embeddedViews.averagePerModel * 100) / 100
      }
    ],
    [platformSummary]
  );

  return (
    <PageContainer>
      <PageTitle>Admin Panel</PageTitle>
      <Styled.PageContent>
        <Styled.PlatformSummaryContainer>
          <Styled.SectionTitle>Platform Summary</Styled.SectionTitle>
          <Styled.PlatformSummary>
            <Styled.SummaryItem>Users</Styled.SummaryItem>
            <ul>
              <li>
                Active:<span className='item-value'>{users.active}</span>
              </li>
              <li>
                Created:<span className='item-value'>{users.created}</span>
              </li>
              <li>
                Total:<span className='item-value'>{users.total}</span>
              </li>
              <li>
                Deleted:<span className='item-value'>{users.deleted}</span>
              </li>
            </ul>
            <Styled.SummaryItem>
              Total uploaded models: <span className='item-value'>{totalUploadedModels}</span>
            </Styled.SummaryItem>
            <Styled.SummaryItem>
              Total models size:
              <span className='item-value'>{convertBytesToMegabytes(+totalModelsSize)}Mb</span>
            </Styled.SummaryItem>
            <Styled.SummaryItem>
              Total subscriptions:
              <span className='item-value'>{totalPlan.totalSubscriptions}</span>
            </Styled.SummaryItem>
            <Styled.SummaryItem>Billing plans:</Styled.SummaryItem>
            <ul>
              {totalPlan.billingPlans.map(
                ({ name, count }): JSX.Element => (
                  <li key={name}>
                    {name}:<span className='item-value'>{count}</span>
                  </li>
                )
              )}
            </ul>
          </Styled.PlatformSummary>
            <Table
              columns={tableColumnsPlatform}
              dataSource={platformSummaryData}
              loading={isStatisticsLoading}
              size={'middle'}
            />
        </Styled.PlatformSummaryContainer>
        <Styled.UserStatisticsContainer ref={userStatisticsRef}>
          <Styled.SectionTitle>User Statistics</Styled.SectionTitle>
          <Styled.SearchField>
            <Styled.SearchInput
              value={searchInputValue}
              onChange={handleSearchInputChange}
              placeholder='Search'
            />
          </Styled.SearchField>
          <Styled.TableContainer>
            <Table
              columns={tableColumns}
              dataSource={tableUsersData}
              loading={isStatisticsLoading}
              pagination={{
                current: currentPage,
                pageSize: 10,
                total: totalItems,
                hideOnSinglePage: true,
                showSizeChanger: false,
                position: ['bottomCenter']
              }}
              size={'middle'}
              onChange={handleTableChange}
            />
          </Styled.TableContainer>
        </Styled.UserStatisticsContainer>
      </Styled.PageContent>
    </PageContainer>
  );
};

export default AdminPanelPage;
