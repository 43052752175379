import React, { useEffect, useState } from 'react';
import * as Styled from './styles';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { EQuotaName, ESnackbarStyle, TeamsState } from 'shared/types';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { openNotification } from 'utils/notification-utils';
import { getTeamMemberLimits } from 'services/api/subscriptionsService';
import { convertBytesToMegabytes } from 'utils/model-utils';

type Limit = {
  title: string;
  limit: number;
  used: number;
};

const UsageTab = (): JSX.Element => {
  const { activeTeam } = useAppSelector((store): TeamsState => store.teams);
  const [limits, setLimits] = useState<[string, Limit][]>([]);
  const [modelSizeLimit, setModelSizeLimit] = useState<Limit | null>(null);
  const dispatch = useAppDispatch();

  useEffect((): void => {
    const getCurrentUserLimits = async (): Promise<void> => {
      if (!!activeTeam) {
        dispatch(startLoader());
        try {
          const response = (await getTeamMemberLimits(activeTeam.id)).data;
          const limits = Object.entries(response);
          setLimits(
            limits.filter(([limitType]): boolean => limitType !== EQuotaName.MODEL_SIZE_LIMIT)
          );
          setModelSizeLimit(response[EQuotaName.MODEL_SIZE_LIMIT]);
        } catch (e) {
          openNotification(ESnackbarStyle.ERROR, e?.message);
        } finally {
          dispatch(stopLoader());
        }
      }
    };
    getCurrentUserLimits();
  }, [activeTeam, dispatch]);

  const getChartValue = (limit: number, currentValue: number): string => {
    const onePercent = limit / 100;
    return (currentValue / onePercent).toFixed(2);
  };

  const getUsageValue = (limitType: EQuotaName, limit: number, used: number): string => {
    const isUnlimited = limit === -1;
    if (isUnlimited) return 'unlimited';

    switch (limitType) {
      case EQuotaName.ACTIVE_MODELS_LIMIT:
        return `${used} of ${limit} models`;
      case EQuotaName.MODEL_VIEWS_LIMIT:
        return `${used} of ${limit} monthly views used`;
      case EQuotaName.MONTHLY_AR_VIEWS_LIMIT:
        return `${used} of ${limit} monthly AR views used`;
      default:
        return `${used} of ${limit} used`;
    }
  };

  return (
    <Styled.TabContent>
      {!!limits.length && (
        <Styled.UsageChartContainer>
          <Styled.ChartTitle>Account limits</Styled.ChartTitle>
          <Styled.ChartContent>
            {limits.map(
              ([limitType, { title, used, limit }]): JSX.Element => (
                <Styled.ChartField key={limitType}>
                  <Styled.FieldHeader>
                    <Styled.FieldTitle>{title}</Styled.FieldTitle>
                    <Styled.LimitValues>
                      {getUsageValue(limitType as EQuotaName, limit, used)}
                    </Styled.LimitValues>
                  </Styled.FieldHeader>
                  <Styled.ChartLineWrapper>
                    <Styled.ChartLine $chartValue={getChartValue(limit, used)} />
                  </Styled.ChartLineWrapper>
                </Styled.ChartField>
              )
            )}
            <Styled.ChartField>
              <Styled.FieldHeader>
                <Styled.FieldTitle className='model-size'>
                  {modelSizeLimit?.title}
                </Styled.FieldTitle>
                <Styled.LimitValues>
                  {`${convertBytesToMegabytes(modelSizeLimit?.limit || 0)}MB`}
                </Styled.LimitValues>
              </Styled.FieldHeader>
            </Styled.ChartField>
          </Styled.ChartContent>
        </Styled.UsageChartContainer>
      )}
    </Styled.TabContent>
  );
};

export default UsageTab;
