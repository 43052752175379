import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';
import { DEFAULT_THEME_CSS_VARIABLES } from 'shared/constants/customization';

export const ModalWindowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1150px;
  max-height: 90vh;
  width: 90vw;
  color: ${COLORS.white};
  position: relative;
  overflow-x: hidden;
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.modalBackgroundColor};

  @media${DEVICE.tablet} {
    flex-direction: column;
    width: 75vw;
  }

  @media ${DEVICE.mobile} {
    width: 95vw;
  }
`;

export const ModalWindowContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 50px 40px;

  @media ${DEVICE.tablet} {
    padding: 30px 20px;
  }
`;

export const ModalTitle = styled.h1`
  font-size: 24px;
  line-height: 26px;
  margin-bottom: 0;
  font-weight: 600;
  color: ${COLORS.white};
`;

export const ModalSubtitle = styled.h6`
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
`;

export const PermissionsAlert = styled.div`
  margin-top: 0;
  margin-bottom: 30px;
  display: flex;
  align-items: center;

  .info-icon {
    color: ${COLORS.turbo};
    font-size: 24px;
  }

  .permissions-alert {
    margin-left: 14px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: ${COLORS.turbo};
  }
`;

export const MainContent = styled.main`
  display: flex;

  @media ${DEVICE.tabletLarge} {
    flex-direction: column;
  }
`;

export const LeftSection = styled.section`
  width: 65%;
  display: flex;
  flex-direction: column;
  padding-right: 20px;

  @media ${DEVICE.tabletLarge} {
    width: 100%;
    padding: 0;
  }
`;

export const CodeBlockContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BlockTabs = styled.div`
  display: flex;
`;

export const BlockTab = styled.div<{ $isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 30px;
  border-radius: 4px 4px 0 0;
  background: ${({ $isActive }): string => ($isActive ? COLORS.turbo : COLORS.doveGray)};
  color: ${({ $isActive }): string => ($isActive ? COLORS.black : COLORS.white)};
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  cursor: pointer;

  &:not(:first-child) {
    margin-left: 6px;
  }
`;

export const CopyButton = styled.button`
  position: absolute;
  right: 20px;
  top: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.turbo};
  border-radius: 2px;
  opacity: 0;
  transition: all 0.3s;

  img {
    width: 17px;
    height: 17px;
  }

  &:hover {
    background: ${COLORS.gorse};
  }
`;

export const CodeBlock = styled.div`
  position: relative;
  width: 100%;

  &:hover ${CopyButton} {
    opacity: 1;
  }
`;

export const CodeBlockTextarea = styled.textarea`
  width: 100%;
  height: 100px;
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.inputBackgroundColor};
  border-radius: 0 4px 4px 4px;
  padding: 20px;
  overflow-y: scroll;
  border: none;
  resize: none;
  font-family: 'FiraCode Regular', sans-serif;
  font-size: 16px;
  line-height: 21px;

  ::-webkit-scrollbar {
    width: 14px;
  }

  ::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    background-clip: padding-box;
    border-radius: 20px;
    background-color: rgba(28, 27, 31, 1);
  }
`;

export const SceneArea = styled.div`
  width: 100%;
  height: 400px;
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PreviewSceneContainer = styled.div<{ $sceneWidth: number; $sceneHeight: number }>`
  position: relative;
  width: ${({ $sceneWidth }): string => `${$sceneWidth}px`};
  height: ${({ $sceneHeight }): string => `${$sceneHeight}px`};
  max-width: 480px;
  max-height: 360px;
  transition: all 0.6s;
  overflow: hidden;
`;

export const PreviewScene = styled.div<{ $isTransparent: boolean; $sceneHeight: number }>`
  position: absolute;
  width: 100%;
  height: ${({ $sceneHeight }): string => `${$sceneHeight}px`};
  border-radius: 4px;
  border: 2px solid
    ${({ $isTransparent }): string =>
      $isTransparent ? 'transparent' : 'var(--theme-secondary-color)'};
  overflow: hidden;
`;

export const CubeOCContainer = styled.div<{ $isShow: boolean }>`
  position: absolute;
  bottom: 0;
  left: 18px;
  width: 80px;
  height: 80px;
  transform: translateY(-10px);
  transition: all 0.5s;
  z-index: 10;
  cursor: grab;
  pointer-events: none;
  display: ${({ $isShow }): string => ($isShow ? 'block' : 'none')};
`;

export const PreviewLogo = styled.img<{ $sceneScale: number }>`
  position: absolute;
  top: 8px;
  left: 10px;
  max-width: ${({ $sceneScale }): number => 52 * $sceneScale}px;
  max-height: ${({ $sceneScale }): number => 37 * $sceneScale}px;
  z-index: 1;
  object-fit: contain;
`;

export const ModelLinkContainer = styled.div<{ $sceneScale: number }>`
  position: absolute;
  right: 8px;
  top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ $sceneScale }): number => 22 * $sceneScale}px;
  height: ${({ $sceneScale }): number => 22 * $sceneScale}px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 2px;
  z-index: 1;

  img {
    width: ${({ $sceneScale }): number => 15 * $sceneScale}px;
    height: ${({ $sceneScale }): number => 15 * $sceneScale}px;
  }
`;

export const PreviewFooterContainer = styled.div`
  position: absolute;
  bottom: 8px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const PreviewFooter = styled.footer<{ $isColumn: boolean }>`
  display: flex;
  flex-wrap: ${({ $isColumn }): string => ($isColumn ? 'wrap' : 'initial')};
  justify-content: center;
  background-color: ${({ $isColumn }): string => ($isColumn ? 'initial' : 'rgba(0, 0, 0, 0.7)')};
  backdrop-filter: ${({ $isColumn }): string => ($isColumn ? 'initial' : 'blur(8.5px)')};
  overflow: hidden;
  border-radius: ${({ $isColumn }): string => ($isColumn ? '0px' : '8px')};
  z-index: 1;
`;

export const PreviewButton = styled.button<{ $isColumn: boolean; $sceneScale: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  height: ${({ $sceneScale }): number => 40 * $sceneScale}px;
  padding: 0 ${({ $sceneScale }): number => 10 * $sceneScale}px;
  cursor: initial;
  color: var(--text-color);
  background-color: ${({ $isColumn }): string => ($isColumn ? 'rgba(0, 0, 0, 0.7)' : 'initial')};
  backdrop-filter: ${({ $isColumn }): string => ($isColumn ? 'blur(8.5px)' : 'initial')};
  border-radius: ${({ $sceneScale }): number => 8 * $sceneScale}px;
  margin-top: 2px;

  &:not(:first-child) {
    margin-left: ${({ $isColumn }): string => ($isColumn ? '2px' : '0')};
  }

  span {
    font-size: ${({ $sceneScale }): number => 12 * $sceneScale}px;
    line-height: ${({ $sceneScale }): number => 12 * $sceneScale}px;
    font-weight: 500;
    margin-left: ${({ $sceneScale }): number => 6 * $sceneScale}px;
  }
`;

export const IconContainer = styled.div<{ $sceneScale: number }>`
  display: flex;
  width: ${({ $sceneScale }): number => 18 * $sceneScale}px;
  height: ${({ $sceneScale }): number => 18 * $sceneScale}px;
  margin-bottom: ${({ $sceneScale }): number => 3 * $sceneScale}px;

  &.ar {
    width: ${({ $sceneScale }): number => 20 * $sceneScale}px;
    height: ${({ $sceneScale }): number => 20 * $sceneScale}px;
  }

  &.recenter {
    margin-bottom: 0;
  }
`;

export const RightSection = styled.section`
  width: 35%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;

  @media ${DEVICE.tabletLarge} {
    width: 100%;
  }
`;

export const TabContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 522px;
  background: rgba(83, 83, 83, 0.4);
  border-radius: 0 4px 4px 4px;
  padding: 10px 20px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 14px;
  }

  ::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    background-clip: padding-box;
    border-radius: 20px;
    background-color: rgba(28, 27, 31, 1);
  }
`;

export const ResolutionBlock = styled.div`
  padding-top: 14px;
  border-bottom: 2px solid ${COLORS.deepBlack};
`;

export const SettingsBlock = styled.div`
  padding: 14px 0;

  &:not(:last-child) {
    border-bottom: 2px solid ${COLORS.deepBlack};
  }
`;

export const FullWidthSwitcher = styled.div`
  position: relative;
  width: 100%;
  height: 34px;
  background: #a3a3a3;
  border-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;

  @media ${DEVICE.tabletLarge} {
    max-width: 300px;
  }
`;

export const SwitcherWheel = styled.div<{ $isLeftModeActive: boolean }>`
  position: absolute;
  top: 2px;
  transform: translateX(
    ${({ $isLeftModeActive }): string => ($isLeftModeActive ? '2px' : 'calc(100% - 2px)')}
  );
  transition: all 0.3s;
  width: 50%;
  height: 30px;
  border-radius: 6px;
  background: ${COLORS.turbo};
`;

export const SwitcherMode = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

export const SwitcherModeTitle = styled.span`
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: ${COLORS.black};
`;

export const LockIcon = styled.div`
  width: 12px;
  height: 12px;
  background: ${COLORS.turbo};
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(5px, -50%);

  img {
    width: 10px;
    height: 10px;
  }
`;

export const ResolutionField = styled.div<{ $isFixedSize: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 10px;
  padding: 0 10%;
  opacity: ${({ $isFixedSize }): string => ($isFixedSize ? '1' : '0.5')};
  pointer-events: ${({ $isFixedSize }): string => ($isFixedSize ? 'initial' : 'none')};

  @media ${DEVICE.tabletLarge} {
    width: 100%;
    max-width: 300px;
    padding: 0 20px;
  }
`;

export const ResolutionInput = styled.input<{ $isError: boolean }>`
  width: 57px;
  height: 32px;
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.inputBackgroundSecondaryColor};
  border: ${({ $isError }): string => ($isError ? `1px solid ${COLORS.red}` : 'none')};
  border-radius: 4px;
  color: ${COLORS.white};
  text-align: center;
`;

export const ErrorMessageContainer = styled.div`
  min-height: 14px;
  width: 100%;
`;

export const SwitcherField = styled.div`
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-top: 14px;
  }
`;

export const SwitcherName = styled.span<{ $isDisabled?: boolean }>`
  margin-left: 10px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  opacity: ${({ $isDisabled }): string => ($isDisabled ? '0.5' : '1')};
`;

export const BlockTitle = styled.div`
  display: flex;
  align-items: center;

  span {
    font-size: 15px;
    line-height: 17px;
    font-weight: 400;
  }
`;

export const CustomButtonsList = styled.div<{ $isActive: boolean }>`
  display: flex;
  flex-direction: column;
  opacity: ${({ $isActive }): string => ($isActive ? '1' : '0.5')};
  pointer-events: ${({ $isActive }): string => ($isActive ? 'initial' : 'none')};
`;

export const CustomButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

export const CustomButtonNameInput = styled.input`
  width: 40%;
  height: 32px;
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.inputBackgroundSecondaryColor};
  border: none;
  border-radius: 4px;
  color: ${COLORS.white};
  padding: 0 10px;
`;

export const CustomButtonLinkInput = styled.input<{ $isValid: boolean }>`
  width: 55%;
  height: 32px;
  background: ${DEFAULT_THEME_CSS_VARIABLES.inputBackgroundSecondaryColor};
  border: ${({ $isValid }): string => ($isValid ? 'none' : `1px solid ${COLORS.red}`)};
  border-radius: 4px;
  color: ${COLORS.white};
  padding: 0 10px;
`;

export const LimitNotification = styled.div`
  font-size: 15px;
  line-height: 17px;
  font-weight: 400;
  text-align: center;
`;

export const DomainList = styled.div<{ $isActive: boolean }>`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  opacity: ${({ $isActive }): string => ($isActive ? '1' : '0.5')};
  pointer-events: ${({ $isActive }): string => ($isActive ? 'initial' : 'none')};
`;

export const DomainRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const DomainInput = styled.input`
  width: 100%;
  height: 32px;
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.inputBackgroundSecondaryColor};
  border: none;
  border-radius: 4px;
  color: darkgrey;
  padding: 0 10px;
  margin-right: 10px;

  &:not(.new-domain) {
    cursor: initial;
  }

  &.new-domain {
    color: ${COLORS.white};
  }
`;

export const RemoveDomainButton = styled.button`
  width: 22px;
  height: 22px;
  transform: rotate(45deg);

  img {
    width: 100%;
    height: 100%;
  }
`;

export const AddDomainRowContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AddDomainRow = styled.form`
  display: flex;
  align-items: center;
`;

export const AddDomainButton = styled.button`
  width: 22px;
  height: 22px;
  transition: all 0.1s;

  img {
    width: 100%;
    height: 100%;
  }

  &:hover {
    transform: scale(1.1);
  }
`;
