import { EFeatureCategory, EFeatureId, EQuotaName } from "shared/types/enums";

export const SPOTLIGHT_TITLE_OVERRIDES: Partial<Record<string, string>> = {
  [EQuotaName.ACTIVE_MODELS_LIMIT]: "Models Max",
  [EQuotaName.MODEL_SIZE_LIMIT]: "Max Model Size",
};

export const CATEGORY_FEATURES: Record<EFeatureCategory, (EFeatureId | EQuotaName)[]> = {
  [EFeatureCategory.SPOTLIGHT]: [
    EQuotaName.ACTIVE_MODELS_LIMIT,
    EQuotaName.MODEL_SIZE_LIMIT,
    EFeatureId.WEB_AR_VIEWING_MODE,
    EFeatureId.MAKE_3D_NOTES,
    EFeatureId.CUSTOM_UI_BRANDING,
  ],
  [EFeatureCategory.HOSTING]: [
    EQuotaName.ACTIVE_MODELS_LIMIT,
    EQuotaName.MODEL_SIZE_LIMIT,
    EFeatureId.IFRAME_EMBEDDED_MODEL,
    EFeatureId.IFRAME_CUSTOM_BUTTONS,
    EFeatureId.DOMAINS_WHITELIST
  ],
  [EFeatureCategory.VIEWING]: [
    EFeatureId.MATERIAL_BREAKDOWN,
    EFeatureId.GEOMETRY_BREAKDOWN,
    EFeatureId.TEXTURE_BREAKDOWN,
    EFeatureId.MODEL_ANIMATIONS,
    EFeatureId.INSTANT_3D_VIEWING,
    EFeatureId.WEB_AR_VIEWING_MODE,
    EFeatureId.CUBEMAP_OPTIONS,
    EFeatureId.LIGHT_OPTIONS,
    EFeatureId.ENVIRONMENT_OPTIONS
  ],
  [EFeatureCategory.SHARING]: [
    EQuotaName.MODEL_VIEWS_LIMIT,
    EQuotaName.MONTHLY_AR_VIEWS_LIMIT,
    EFeatureId.ACCESS_CONTROLS,
    EFeatureId.MAKE_SHORT_LINKS,
    EFeatureId.QR_CODE_GENERATION,
    EFeatureId.MODEL_DOWNLOADS,
    EFeatureId.MAKE_3D_NOTES,
    EFeatureId.CUSTOM_UI_BRANDING,
    EFeatureId.CUSTOM_3D_ENVIRONMENT
  ],
};