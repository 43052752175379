import styled from 'styled-components';
import { DEVICE } from 'shared/constants/deviceSizes';

export const NavButtonContainer = styled.button`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--text-color);
  margin-right: 20px;
  white-space: nowrap;

  span {
    margin-left: 10px;
  }

  &:hover {
    color: var(--theme-secondary-color);
  }

  @media ${DEVICE.tablet} {
    font-size: 12px;

    &:not(:last-child) {
      margin-right: 14px;
    }

    &:last-child {
      margin-right: 14px;
    }
  }
`;

export const IconWrapper = styled.div`
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
  fill: var(--button-background-color);
  color: var(--button-text-color);

  @media ${DEVICE.tablet} {
    width: 26px;
    height: 26px;
  }
`;
