import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEVICE, DEVICE_SIZES } from 'shared/constants/deviceSizes';

export const FooterContainer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 50px;
  background: var(--footer-background-color);
  border-top: 1px solid var(--theme-secondary-color);
  padding-bottom: 7px;

  &:after {
    content: '';
    position: absolute;
    top: 5px;
    right: -9px;
    background: var(--footer-background-color);
    height: 20px;
    width: 20px;
    transform: rotate(-40deg);
    border-right: 1px solid var(--theme-secondary-color);
  }

  &:before {
    content: '';
    position: absolute;
    top: 5px;
    left: -9px;
    background: var(--footer-background-color);
    height: 20px;
    width: 20px;
    transform: rotate(40deg);
    border-left: 1px solid var(--theme-secondary-color);
  }

  @media ${DEVICE.tabletLarge} {
    width: 100vw;
    height: 50px;

    &:before,
    &:after {
      display: none;
    }
  }
`;

export const IconContainer = styled.div`
  display: flex;
  margin-bottom: 3px;
  width: 18px;
  height: 18px;

  &.ar {
    width: 20px;
    height: 20px;
  }
`;

export const ControlButton = styled.button<{ $isActive?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100px;
  height: 30px;
  background: var(--footer-background-color);
  cursor: pointer;
  white-space: nowrap;
  user-select: none;
  color: var(--text-color);

  &.zen,
  &.share,
  &.screenshot {
    color: ${({ $isActive }): string =>
      $isActive ? 'var(--theme-secondary-color)' : 'var(--text-color)'};
  }

  & > span {
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
  }

  &:disabled {
    background: transparent;
    opacity: 1;

    & > span,
    .ar-icon,
    .share-icon {
      opacity: 0.5;
    }
  }

  @media ${DEVICE.tabletLarge} {
    background: transparent;
    width: 25%;
    height: 100%;
  }

  @media (min-width: ${DEVICE_SIZES.tabletLarge}px) {
    &.ar,
    &.share {
      display: none;
    }

    &:hover {
      color: var(--theme-secondary-color);
    }
  }

  @media ${DEVICE.mobile} {
    & > span {
      font-weight: 500;
      font-size: 10px;
      line-height: 11px;
    }
  }

  &.zen, &.fullscreen {
    @media ${DEVICE.tabletLarge} {
      display: none;
    }
  }
`;

export const Lock = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  background: ${COLORS.turbo};
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(100%, -100%);

  img {
    width: 10px;
    height: 10px;
  }
`;

export const EmbeddedSceneFooterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 20px;
`;

export const EmbeddedSceneFooter = styled.footer<{ $isColumn: boolean }>`
  display: flex;
  flex-wrap: ${({ $isColumn }): string => ($isColumn ? 'wrap' : 'initial')};
  justify-content: center;
  align-items: center;
  background-color: ${({ $isColumn }): string => ($isColumn ? 'initial' : 'rgba(0, 0, 0, 0.7)')};
  backdrop-filter: ${({ $isColumn }): string => ($isColumn ? 'initial' : 'blur(8.5px)')};
  overflow: hidden;
  border-radius: ${({ $isColumn }): string => ($isColumn ? '0px' : '8px')};
  z-index: 1;
`;

export const FooterButton = styled.button<{ $isColumn: boolean; $isDefaultButton: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  height: 40px;
  padding: 0 10px;
  cursor: pointer;
  color: var(--text-color);
  background-color: ${({ $isColumn }): string => ($isColumn ? 'rgba(0, 0, 0, 0.7)' : 'initial')};
  backdrop-filter: ${({ $isColumn }): string => ($isColumn ? 'blur(8.5px)' : 'initial')};
  border-radius: 8px;
  margin-top: 2px;

  &:not(:first-child) {
    margin-left: ${({ $isColumn }): string => ($isColumn ? '2px' : '0')};
  }

  &:hover {
    color: var(--theme-secondary-color);
  }

  span {
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
    margin-left: 6px;
  }

  &:disabled {
    background-color: ${({ $isColumn }): string => ($isColumn ? 'rgba(0, 0, 0, 0.3)' : 'initial')};
  }

  @media (max-width: 360px) {
    span {
      display: ${({ $isDefaultButton }): string => ($isDefaultButton ? 'none' : 'initial')};
    }
  }
`;

export const LinkWarningContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  font-size: 13px;
  line-height: 14px;
  padding: 0 20px;

  .warning,
  .timer-warning {
    color: ${COLORS.white};
  }

  .link {
    color: ${COLORS.turbo};
    cursor: pointer;
  }

  .link:hover {
    text-decoration: underline;
  }
`;

export const LinkWarningButtons = styled.div`
  margin-top: 30px;
  width: 70%;
  display: flex;
  justify-content: center;
  gap: 20px;
`;

export const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  padding: 5px 0;
  border-radius: 3px;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  transition: all 0.2s;

  &.cancel-button {
    background: #484848;
    color: ${COLORS.white};
  }

  &.open-button {
    background: ${COLORS.turbo};
    color: ${COLORS.black};
  }

  &:hover {
    background: ${COLORS.gorse};
    color: ${COLORS.black};
  }
`;
