import { AxiosResponse } from 'axios';
import ApiService from './api';
import { Model } from 'shared/types';

type Pagination = {
  currentPage: number;
  pageSize: number;
  totalItemsCount: number;
  totalPagesCount: number;
};

type Response = {
  items: Model[];
  pagination: Pagination;
};

export const getModelsOverSizeLimit = async (modelSize: number): Promise<AxiosResponse<Model[]>> =>
  await ApiService.get<Model[]>(
    `/users/me/models?limit=300&orderBy=-createdAt&filter.modelSize.operator=>&filter.modelSize.value=${modelSize}`
  );

export const getUserModels = async (
  userId: number,
  page: number,
  limit: number,
  search?: string
): Promise<AxiosResponse<Response>> => {
  const searchQueryParams = !!search ? `&search=${search}` : '';
  return await ApiService.get<Response>(
    `users/${userId}/models?page=${page}&limit=${limit}${searchQueryParams}&withRelated[]=content&withRelated[]=owner&orderBy=-createdAt`
  );
};

export const getRecentUploadedModels = async (
  userId: number,
  limit: number,
  search?: string
): Promise<AxiosResponse<Model[]>> => {
  const searchQueryParams = !!search ? `&search=${search}` : '';
  return await ApiService.get<Model[]>(
    `/users/${userId}/recent-uploads?limit=${limit}${searchQueryParams}&withRelated[]=content&withRelated[]=owner&withRelated[]=metadata`
  );
};

export const getRecentViewedModels = async (
  userId: number,
  search?: string
): Promise<AxiosResponse<Model[]>> => {
  const searchQueryParams = !!search ? `search=${search}` : '';
  return await ApiService.get<Model[]>(
    `/users/${userId}/recent-models?${searchQueryParams}&withRelated[]=content&withRelated[]=owner&withRelated[]=metadata`
  );
};

export const getSharedWithMeModels = async (
  page: number,
  limit: number,
  search?: string
): Promise<AxiosResponse<Response>> => {
  const searchQueryParams = !!search ? `&search=${search}` : '';
  return await ApiService.get<Response>(
    `users/me/models?showSharedWithMe=true&page=${page}&limit=${limit}${searchQueryParams}&withRelated[]=content&withRelated[]=owner&orderBy=-createdAt&withRelated[]=metadata`
  );
};
