import React, { useState } from 'react';
import * as Styled from './styles';
import { FormFieldLabel, ModalForm, ModalSubtitle, ModalTitle, SubmitButton } from 'shared/styles';
import {
  FormField,
  ModalCreateAccount,
  ModalForgotPassword,
  ModalEmailNotVerified
} from 'shared/components';
import Check from 'assets/images/check.svg';
import { useAppDispatch, useAppSelector, useBrowserStore, useUploadFile } from 'shared/hooks';
import { openNotification } from 'utils/notification-utils';
import { useFormik } from 'formik';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { login } from 'services/api/authService';
import { clearUserData, loginUser, logoutUser } from 'services/store/reducers/authReducer';
import { ESnackbarStyle, EModelType, ViewerDataState } from 'shared/types';
import { closeModal, showModal } from 'services/store/reducers/modalReducer';
import { validation } from 'services/validation';
import {
  getInviteFromLocalStorage,
  getModelDataLocalStorage,
  getTeamInviteFromLocalStorage,
  removeInviteFromLocalStorage,
  removeTeamInviteFromLocalStorage
} from 'utils/storage-utils';
import { acceptInvite } from 'services/api/permissionsService';
import { useHistory } from 'react-router-dom';

type InitialValues = {
  email: string;
  password: string;
};

type Props = {
  email?: string;
};

const LoginForm: React.FC<Props> = ({ email }): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const invite = getInviteFromLocalStorage();
  const teamInviteLink = getTeamInviteFromLocalStorage();
  const { model } = useAppSelector((store): ViewerDataState => store.viewerData);
  const { getFilesFromBrowserStore } = useBrowserStore();
  const { uploadModelToSpace } = useUploadFile();
  const [isRememberMe, setIsRememberMe] = useState<boolean>(true);
  const initialValues: InitialValues = {
    email: email || '',
    password: ''
  };

  const checkForAnonymousModel = async (): Promise<void> => {
    const { lsModelType } = getModelDataLocalStorage();
    if (!!model && lsModelType === EModelType.ANONYMOUS) {
      return new Promise((resolve): void => {
        getFilesFromBrowserStore(async (files: File[] | null): Promise<void> => {
          if (!!files) {
            await uploadModelToSpace(files);
            resolve();
          }
        });
      });
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (values): Promise<void> => {
      dispatch(startLoader());
      try {
        dispatch(logoutUser());
        const { data } = await login(values);
        if (data.status === 'created') {
          dispatch(showModal(<ModalEmailNotVerified email={values.email} />));
          return
        }
        isRememberMe
          ? localStorage.setItem('token', data.token)
          : sessionStorage.setItem('token', data.token);
        await dispatch(loginUser({ user: data }));
        if (!!invite) {
          removeInviteFromLocalStorage();
          await acceptInvite(invite.modelId, invite.inviteCode);
          history.push(`/${invite.modelId}`);
        }
        if (!!teamInviteLink) {
          removeTeamInviteFromLocalStorage();
          history.push(`${teamInviteLink}`);
        }

        if (!invite && !teamInviteLink) {
          await checkForAnonymousModel();
        }

        dispatch(closeModal());
      } catch (e) {
        dispatch(clearUserData());
        openNotification(ESnackbarStyle.ERROR, e?.message);
      } finally {
        dispatch(stopLoader());
      }
    },
    validationSchema: validation.LOG_IN
  });

  const handleRememberMeClick = (): void => {
    setIsRememberMe((prev): boolean => !prev);
  };

  const handleCreateAccountClick = (): void => {
    dispatch(showModal(<ModalCreateAccount />));
  };

  const handleForgotPasswordClick = (): void => {
    dispatch(showModal(<ModalForgotPassword />));
  };

  return (
    <Styled.FormContainer>
      <ModalTitle>Login</ModalTitle>
      <ModalSubtitle>Login or sign up for a free account to share your work</ModalSubtitle>
      <ModalForm onSubmit={formik.handleSubmit}>
        <FormFieldLabel>Email*</FormFieldLabel>
        <FormField formik={formik} name={'email'} type={'email'} />
        <FormFieldLabel>Password*</FormFieldLabel>
        <FormField formik={formik} name={'password'} isPassword />
        <Styled.FormFooter>
          <Styled.RememberMeContainer onClick={handleRememberMeClick}>
            <Styled.Checkbox>{isRememberMe && <img src={Check} alt='Check' />}</Styled.Checkbox>
            <span>Remember me</span>
          </Styled.RememberMeContainer>
          <Styled.ForgotPassword onClick={handleForgotPasswordClick} id='forgot-password-button'>
            Forgot Password?
          </Styled.ForgotPassword>
        </Styled.FormFooter>
        <SubmitButton type='submit' disabled={formik.isSubmitting} id='login-form-login-button'>
          Login
        </SubmitButton>
      </ModalForm>
      <Styled.NotRegisteredBlock>
        <span>Not registered yet?</span>
        <span
          className='create-account'
          onClick={handleCreateAccountClick}
          id='login-form-create-account-button'
        >
          Create an Account
        </span>
      </Styled.NotRegisteredBlock>
    </Styled.FormContainer>
  );
};

export default LoginForm;
