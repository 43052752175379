import React, {ChangeEvent, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import * as Styled from './styles';
import Lock from 'assets/images/lock.png';
import Unlock from 'assets/images/unlock.png';
import {DEVICE_SIZES} from 'shared/constants/deviceSizes';
import {useAppSelector} from 'shared/hooks';
import {ErrorMessage} from 'shared/styles';
import {TRANSFORM_FIELD_TYPES} from './constants';
import {AxisValues, ETransformFiledType, EModelType, ViewerDataState} from 'shared/types';
import {INITIAL_MODEL_SETTINGS} from 'shared/constants/model-settings';

type Axis = 'x' | 'y' | 'z';

type InputAxisValues = {
  x: string;
  y: string;
  z: string;
};

type Props = {
  action: (fieldType: ETransformFiledType, values: AxisValues) => void;
  initialValues: AxisValues;
  fieldType: ETransformFiledType;
};

export const TransformField: React.FC<Props> = ({
  action,
  initialValues,
  fieldType
}): JSX.Element => {
  const { modelType } = useAppSelector((store): ViewerDataState => store.viewerData);
  const AXES: Axis[] = ['x', 'y', 'z'];
  const DEVICE_WIDTH: number = document.body.offsetWidth;
  const mouseStartX = useRef<number>(0);
  const { minValue, maxValue, defaultValue, isLockedByDefault, regex, fractionalRegex } =
    TRANSFORM_FIELD_TYPES[fieldType];
  const INITIAL_AXIS_VALUES = useMemo(
    (): InputAxisValues =>
      Object.entries(initialValues || INITIAL_MODEL_SETTINGS[fieldType]).reduce(
        (acc: InputAxisValues, [axis, value]): InputAxisValues => ({
          ...acc,
          [axis]: value.toString()
        }),
        { x: '0', y: '0', z: '0' }
      ),
    [fieldType, initialValues]
  );
  const [axisValues, setAxisValues] = useState<InputAxisValues>(INITIAL_AXIS_VALUES);
  const currentEntityValues = useRef<InputAxisValues>(INITIAL_AXIS_VALUES);
  const [activeEntityAxis, setActiveEntityAxis] = useState<Axis | null>(null);
  const [isEntityLocked, setIsEntityLocked] = useState<boolean>(isLockedByDefault);
  const [isBigger, setIsBigger] = useState<boolean>(false);
  const [isSmaller, setIsSmaller] = useState<boolean>(false);
  const isMoveAction = useRef<boolean>(false);
  const isInitial = useRef<boolean>(true);
  const isOnBlur = useRef<boolean>(false);

  useEffect((): void => {
    setAxisValues(INITIAL_AXIS_VALUES);
    currentEntityValues.current = INITIAL_AXIS_VALUES;
  }, [INITIAL_AXIS_VALUES]);

  useEffect((): void => {
    const isQuickView = modelType === EModelType.QUICK_VIEW;
    if (isQuickView) {
      const axisValue = defaultValue.toString();
      const initialInputValues: InputAxisValues = { x: axisValue, y: axisValue, z: axisValue };
      setAxisValues(initialInputValues);
      currentEntityValues.current = initialInputValues;
    }
  }, [defaultValue, modelType]);

  const getStepValue = useCallback((): number => {
    switch (true) {
      case DEVICE_WIDTH <= DEVICE_SIZES.mobile:
        return 0.05;
      case DEVICE_WIDTH <= DEVICE_SIZES.tablet:
        return 0.04;
      case DEVICE_WIDTH <= DEVICE_SIZES.tabletLarge:
        return 0.03;
      case DEVICE_WIDTH <= DEVICE_SIZES.desktop:
        return 0.02;
      default:
        return 0.01;
    }
  }, [DEVICE_WIDTH]);

  const STEP_VALUE: number = getStepValue();

  const convertAxisValue = useCallback(
    (value: string): number => {
      switch (true) {
        case value === '-':
          return defaultValue;
        case +value > maxValue:
          return maxValue;
        case +value < minValue:
          return minValue;
        default:
          return +value;
      }
    },
    [defaultValue, maxValue, minValue]
  );

  useEffect((): void => {
    currentEntityValues.current = axisValues;
    const isBigger = Object.values(axisValues).some((value): boolean => +value > maxValue);
    const isSmaller = Object.values(axisValues).some((value): boolean => +value < minValue);
    setIsBigger(isBigger);
    setIsSmaller(isSmaller);
  }, [axisValues, maxValue, minValue]);

  useEffect((): void => {
    if (!isInitial.current && !isOnBlur.current) {
      const { x, y, z } = axisValues;
      const convertedAxisValues: AxisValues = {
        x: convertAxisValue(x),
        y: convertAxisValue(y),
        z: convertAxisValue(z)
      };
      action(fieldType, convertedAxisValues);
    }
    isInitial.current = false;
    isOnBlur.current = false;
  }, [action, convertAxisValue, axisValues, fieldType]);

  const handleInputChange =
    (axis: Axis): ((event: ChangeEvent<HTMLInputElement>) => void) =>
    (event): void => {
      const { value } = event.target;
      const isValidValue =
        !value ||
        (value.includes('.') && value.length > 1 ? fractionalRegex.test(value) : regex.test(value));
      if (isValidValue) {
        isEntityLocked
          ? setAxisValues({ x: value, y: value, z: value })
          : setAxisValues((prev): InputAxisValues => ({ ...prev, [axis]: value }));
      }
    };

  const handleInputBlur = useCallback((): void => {
    isOnBlur.current = true;
    const { x, y, z } = axisValues;
    const convertedAxisValues: InputAxisValues = {
      x: convertAxisValue(x).toString(),
      y: convertAxisValue(y).toString(),
      z: convertAxisValue(z).toString()
    };
    setAxisValues(convertedAxisValues);
  }, [axisValues, convertAxisValue]);

  const handleAxisClick = useCallback(
    (axis: Axis, eventType: 'click' | 'touch'): (() => void) =>
      (): void => {
        const isClick = eventType === 'click';

        const handleMoveAction = (event: MouseEvent | TouchEvent): void => {
          document.body.style.cursor = 'ew-resize';
          setActiveEntityAxis(axis);
          isMoveAction.current = true;
          const clientX = isClick
            ? (event as MouseEvent).clientX
            : Math.round((event as TouchEvent).touches[0].clientX);
          if (!mouseStartX.current) {
            mouseStartX.current = clientX;
          }
          const cursorValue: number = clientX - mouseStartX.current;
          const entityValue: number = cursorValue * STEP_VALUE;
          const entity = +(+axisValues[axis] + entityValue).toFixed(3);
          if (entity >= minValue && entity <= maxValue) {
            setAxisValues(
              (prev): InputAxisValues =>
                isEntityLocked
                  ? { x: entity.toString(), y: entity.toString(), z: entity.toString() }
                  : { ...prev, [axis]: entity.toString() }
            );
          }
        };

        const handleUpAction = (): void => {
          if (isMoveAction.current) {
            const { x, y, z } = currentEntityValues.current;
            setAxisValues({ x, y, z });
            setActiveEntityAxis(null);
            mouseStartX.current = 0;
            isMoveAction.current = false;
            document.body.style.cursor = 'initial';
          }
          document.removeEventListener(isClick ? 'mousemove' : 'touchmove', handleMoveAction);
          document.addEventListener(isClick ? 'mouseup' : 'touchend', handleUpAction);
        };

        document.addEventListener(isClick ? 'mousemove' : 'touchmove', handleMoveAction);
        document.addEventListener(isClick ? 'mouseup' : 'touchend', handleUpAction);
      },
    [STEP_VALUE, axisValues, isEntityLocked, maxValue, minValue]
  );

  const handleLockClick = useCallback((): void => {
    setIsEntityLocked((prev): boolean => !prev);
  }, []);

  return (
    <Styled.EntitySettingsContainer id={`${fieldType}-field`}>
      <Styled.EntityContent>
        <Styled.EntityFields $isResizeMode={!!activeEntityAxis}>
          <Styled.LockIcon
            src={isEntityLocked ? Lock : Unlock}
            onClick={handleLockClick}
            alt='Lock'
          />
          {AXES.map(
            (axis): JSX.Element => (
              <Styled.EntityField key={axis}>
                <Styled.FieldLabel
                  onTouchStart={handleAxisClick(axis, 'touch')}
                  onMouseDown={handleAxisClick(axis, 'click')}
                  $isActive={activeEntityAxis === axis}
                >
                  {axis.toUpperCase()}
                </Styled.FieldLabel>
                <Styled.FieldInput
                  value={axisValues[axis]}
                  onChange={handleInputChange(axis)}
                  onBlur={handleInputBlur}
                  id={`${fieldType}-${axis}-axis-input`}
                />
              </Styled.EntityField>
            )
          )}
        </Styled.EntityFields>
      </Styled.EntityContent>
      {isBigger && <ErrorMessage>{`${maxValue} is max value`}</ErrorMessage>}
      {isSmaller && <ErrorMessage>{`${minValue} is min value`}</ErrorMessage>}
    </Styled.EntitySettingsContainer>
  );
};

export default TransformField;
