import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';

export const ScreenshotMenuContainer = styled.div`
  position: absolute;
  bottom: calc(100% + 10px);
  margin-bottom: 8px;
  z-index: 1000;
  width: 300px;
`;

export const ScreenshotMenuContent = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(83, 83, 83, 0.6);
  backdrop-filter: blur(5px);
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.12);
`;

export const MenuHeader = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.56);
  color: ${COLORS.white};
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
`;

export const MenuField = styled.div`
  height: 45px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.56);
  }
`;

export const FieldName = styled.span`
  color: ${COLORS.white};
  font-size: 14px;
  line-height: 16px;
  width: 60%;
`;

export const FieldAction = styled.div`
  width: 100px;
  display: flex;
  justify-content: center;
`;

export const DownloadButton = styled.button<{ $isDownloaded: boolean }>`
  background: var(--button-background-color);
  color: var(--button-text-color);
  width: 100%;
  border-radius: 4px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  padding: 5px 0;
  opacity: ${({ $isDownloaded }): string => ($isDownloaded ? '1' : '0.8')};
  transition: all 0.1s;

  &:hover {
    opacity: 1;
  }
`;

export const MenuArrow = styled.div`
  position: absolute;
  width: 14px;
  height: 14px;
  background-image: linear-gradient(135deg, transparent 50%, rgba(83, 83, 83, 0.6) 50%);
  border-right: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  backdrop-filter: blur(1px);
  bottom: -7px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
`;
