import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';
import { DEFAULT_THEME_CSS_VARIABLES } from 'shared/constants/customization';

export const ModalWindowContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 740px;
  width: 90vw;
  max-height: 90vh;
  color: ${COLORS.white};
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 50px 0;
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.modalBackgroundColor};

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${COLORS.doveGray};
    border-radius: 2px;
  }

  @media${DEVICE.mobile} {
    padding: 30px 10px 20px;
  }
`;

export const ModalHeader = styled.header`
  padding: 0 30px;
  display: flex;
  flex-direction: column;

  @media ${DEVICE.tablet} {
    padding: 0 10px;
  }
`;

export const ModalTitle = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;

  h1 {
    font-weight: 500;
    font-size: 24px;
    line-height: 26px;
    color: ${COLORS.white};
    margin-bottom: 0;
  }

  img {
    width: 16px;
    height: 16px;
    margin-left: 12px;
  }
`;

export const TooltipBlock = styled.div`
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: ${COLORS.white};
  text-align: center;
  padding: 6px 4px;
`;

export const ModalSubTitle = styled.h2`
  margin: 20px 0 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: ${COLORS.white};
`;

export const AddPeopleFieldContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  padding: 0 30px;

  @media ${DEVICE.tablet} {
    padding: 0 10px;
  }
`;

export const FieldLabel = styled.span`
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
`;

export const AddPeopleField = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;

  @media${DEVICE.mobile} {
    flex-direction: column;
  }
`;

export const InputContainer = styled.div`
  position: relative;
  width: 100%;

  @media${DEVICE.mobile} {
    margin-top: 10px;
    height: min-content;
  }
`;

export const AddPeopleInput = styled.input`
  height: 39px;
  width: 100%;
  border: none;
  border-bottom: 1px solid ${COLORS.turbo};
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.inputBackgroundColor};
  border-radius: 4px 4px 0 0;
  padding: 13px 11px;
`;

export const InputFooter = styled.div`
  display: flex;
  flex-direction: column;

  .hint {
    margin-top: 6px;
    margin-left: auto;
    font-size: 10px;
    line-height: 11px;

    @media${DEVICE.tabletLarge} {
      display: none;
    }
  }
`;

export const AddUserButton = styled.button`
  width: 152px;
  height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: ${COLORS.turbo};
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${COLORS.codGray};
  margin-left: 20px;
  border-radius: 2px;

  &:hover {
    background: ${COLORS.gorse};
  }

  @media ${DEVICE.mobile} {
    width: 100%;
    margin: 10px 0 0 0;
  }
`;

export const PermissionsTableContainer = styled.section`
  width: 100%;
  margin-top: 30px;
  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${COLORS.doveGray};
    border-radius: 2px;
  }
`;

export const PermissionsTable = styled.table`
  width: 100%;
  position: relative;

  &:last-child {
    margin-top: 30px;
  }
`;

export const TableHeader = styled.thead`
  position: sticky;
  top: -1px;
  background: #090909;

  tr {
    height: 44px;
  }

  tr > th:first-child {
    width: 40%;
    text-align: left;
    padding-left: 58px;
    font-weight: 500;
    font-size: 15px;

    img {
      width: 11px;
      height: 11px;
      margin-left: 12px;
    }
  }

  @media ${DEVICE.tablet} {
    tr {
      display: none;
    }
  }
`;

export const ColumnTitle = styled.th<{ $isAvailable: boolean }>`
  width: 15%;
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
  color: ${COLORS.white};
  opacity: ${({ $isAvailable }): string => ($isAvailable ? '1' : '0.3')};
`;

export const TableBody = styled.tbody`
  tr,
  td {
    height: 32px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media ${DEVICE.tablet} {
      white-space: initial;
      overflow: initial;
      text-overflow: initial;
    }
  }

  .public,
  .team {
    font-size: 15px;
    line-height: 17px;
    padding: 6px 0 6px 58px;
    white-space: break-spaces;

    img {
      width: 11px;
      height: 11px;
      margin-left: 12px;
    }

    @media ${DEVICE.tablet} {
      padding-left: 0;
    }
  }

  tr > td:first-child:not(.public, .team) {
    padding-left: 30px;
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    color: ${COLORS.white};

    @media ${DEVICE.tablet} {
      padding-left: 0;
    }
  }

  &.public-table-body > tr:nth-child(odd) {
    background: ${COLORS.mineShaft};
  }

  &.team-table-body > tr:nth-child(odd) {
    background: ${COLORS.mineShaft};
  }

  td:not(.public, .team) > span {
    margin-left: 12px;
  }

  @media ${DEVICE.tablet} {
    tr {
      display: flex;
      flex-direction: column;
      height: auto;
      width: 100%;
      padding: 20px 30px;
    }

    td {
      display: flex;
      align-items: center;
    }

    tr > td:first-child {
      margin-bottom: 15px;
    }
  }
`;

export const DeleteButton = styled.img`
  width: 13px;
  height: 13px;
  cursor: pointer;
`;

export const AccessCheckbox = styled.div<{ $isAvailable: boolean }>`
  margin: 0 auto;
  width: 18px;
  height: 18px;
  border: 1px solid ${COLORS.white};
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  cursor: pointer;
  opacity: ${({ $isAvailable }): string => ($isAvailable ? '1' : '0.3')};

  @media ${DEVICE.tablet} {
    margin: initial;
  }
`;

export const AccessTitle = styled.span<{ $isAvailable: boolean }>`
  display: none;
  opacity: ${({ $isAvailable }): string => ($isAvailable ? '1' : '0.3')};

  @media ${DEVICE.tablet} {
    display: block;
  }
`;

export const ModalFooter = styled.div`
  padding: 0 30px;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media ${DEVICE.tablet} {
    padding: 0 10px;
    margin-top: 10px;
  }
`;

export const FooterButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 30px;

  &.copy-link {
    margin-left: auto;
  }

  img {
    width: 18px;
    height: 18px;
  }

  span {
    margin-left: 7px;
    font-weight: 700;
    font-size: 13px;
    line-height: 14px;
  }

  &:hover span {
    text-decoration: underline;
  }
`;

export const ModalBottomTeamMessage = styled.p`
  margin: 40px 0 0;
  padding: 0 30px;
  text-align: center;
  word-break: break-word;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;

  @media ${DEVICE.tablet} {
    padding: 0 10px;
  }
`;
