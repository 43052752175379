import { COLORS } from 'shared/constants/colors';
import styled from 'styled-components';

export const SelectContainer = styled.div<{ $isMenuActive: boolean; $disabled?: boolean }>`
  color: ${COLORS.white};
  font-size: 14px;
  line-height: 16px;
  width: 100%;
  display: flex;
  position: relative;
  pointer-events: ${({ $disabled }): string => ($disabled ? 'none' : 'initial')};
  opacity: ${({ $disabled }): string => ($disabled ? '0.5' : '1')};
`;

export const MainField = styled.div<{ $isMenuActive: boolean }>`
  position: relative;
  width: 100%;
  height: 42px;
  background: ${COLORS.deepBlack};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  transition: border-radius 0.1s ease-out 0.2s;
  border-bottom-left-radius: ${({ $isMenuActive }): string => ($isMenuActive ? '0' : '4px')};
  border-bottom-right-radius: ${({ $isMenuActive }): string => ($isMenuActive ? '0' : '4px')};

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    transform: scaleX(${({ $isMenuActive }): string => ($isMenuActive ? '1' : '0')});
    transition: all 0.2s ease-out;
    border-bottom: 1px solid ${COLORS.white};
  }

  & div {
    width: 100%;
    padding: 0 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & img {
    transform: ${({ $isMenuActive }): string => ($isMenuActive ? 'rotate(180deg)' : '0')};
    transition: all 0.3s;
    margin-right: 10px;
  }

  &:hover div {
    color: var(--theme-secondary-color);
  }
`;

export const SelectMenu = styled.div<{ $isMenuActive: boolean }>`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  max-height: ${({ $isMenuActive }): string => ($isMenuActive ? '200px' : '0px')};
  background: ${COLORS.deepBlack};
  position: absolute;
  z-index: 11;
  transition: max-height 0.3s;
  border-radius: 0 0 4px 4px;
  margin-top: 42px;
  box-shadow: rgb(0 0 0 / 70%) -5px 10px 10px, rgb(0 0 0 / 30%) 0 1px 18px,
    rgb(0 0 0 / 60%) 0 30px 15px;

  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--theme-secondary-color);
  }
`;

export const OptionItem = styled.div<{ $isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 42px;
  padding: 0 18px;
  cursor: pointer;
  background: ${({ $isActive }): string => ($isActive ? 'rgba(255, 255, 255, 0.1)' : 'transparent')};

  &:hover {
    background: var(--theme-secondary-color);

    span {
      color: var(--button-text-color);
    }
  }

  & span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    max-width: 98%;
    text-align: left;
    margin-right: 10px;
  }
`;
