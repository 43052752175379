import React, { useEffect, useRef, useState, JSX } from 'react';
import * as Styled from './styles';
import ResetSettingsArrow from 'assets/images/reset-settings-icon.svg';
import EditIcon from 'assets/images/key-binding-edit.svg';
import DeleteIcon from 'assets/images/key-binding-delete.svg';
import { EKeyBindingsKeys, EKeyPriorities } from 'shared/enums/EKeyBindingsKeys';
import { KeyBindingsMap } from 'shared/types/key-binding';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { RootState } from 'services/store';
import {
  changeKeyBinding,
  fetchKeyBindings,
  removeKeyBinding,
  removeKeyBindings,
  syncKeyBindings
} from 'services/store/reducers/keyBindingsReducer';
import {
  getKeyCodeMarker,
  getViceVersaKeyMap,
  shortenKeyName,
  transformActionKeyName
} from 'utils/key-bindings-utils';
import { openNotification } from 'utils/notification-utils';
import { ESnackbarStyle } from 'shared/types';
import { CustomTooltip } from 'shared/components';

enum EActionsKeysUIOrder {
  forward,
  back,
  left,
  right,
  up,
  down,
  fastSpeed,
  increaseFOV,
  decreaseFOV,
  speedControl,
  pan,
  quickZoom,
  rotate,
  zenMode,
  fullScreen
}

interface IActiveSell {
  action: EKeyBindingsKeys;
  type: EKeyPriorities;
}

const notifications = {
  pressAnyKey(): string {
    return 'Press any key you want, or hit esc to cancel';
  },
  isAlreadyUsed(action: string): string {
    return `That key binding is already used as the ${action} control`;
  }
};

const CameraControlsTab: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const formRef = useRef<HTMLDivElement>(null);
  const store = useAppSelector((store): RootState => store);
  const { keyBindings, isKeyBindingsUnsynced } = store.keyBindings;

  const [isResetSettingClicked, setIsResetSettingsClicked] = useState<boolean>(false);
  const [formNotification, setFormNotification] = useState<string | null>(null);
  const [activeSell, setActiveSell] = useState<IActiveSell | null>(null);
  const [onCompleteFn, setOnCompleteFn] = useState<(() => void) | null>(null);

  useEffect((): (() => void) => {
    dispatch(fetchKeyBindings({}));

    if (formRef.current) {
      formRef.current.addEventListener('contextmenu', preventDefaults);
      formRef.current.addEventListener('mouseup', preventDefaults);
    }

    return (): void => {
      if (!formRef.current) return;

      formRef.current.addEventListener('contextmenu', preventDefaults);
      formRef.current.addEventListener('mouseup', preventDefaults);
    };
  }, []);

  useEffect((): void => {
    if (!!activeSell) {
      setFormNotification(notifications.pressAnyKey());
    }
  }, [activeSell]);

  const handleChangeBinding = (
    action: EKeyBindingsKeys,
    type: EKeyPriorities,
    currentCode: string
  ): void => {
    const onComplete = (): void => {
      window.removeEventListener('keyup', listenButtonClick);
      window.removeEventListener('mouseup', listenButtonClick);

      resetForm();
      setOnCompleteFn(null);
    };
    const onError = (action: string): void => {
      setFormNotification(notifications.isAlreadyUsed(action));
      openNotification(ESnackbarStyle.HOLD_UP, notifications.isAlreadyUsed(action));
    };
    const listenButtonClick = (event: KeyboardEvent | MouseEvent): void => {
      event.preventDefault();
      event.stopPropagation();

      const keys = getViceVersaKeyMap(keyBindings);
      const code = getKeyCodeMarker(event);

      if ((event.target as HTMLElement).parentElement!.id === 'reset-button') return;

      if (code === 'Escape' || currentCode === code) {
        onComplete();
        return;
      }

      if (code.length && keys.hasOwnProperty(code)) {
        onError(keys[code]);
        return;
      }

      dispatch(changeKeyBinding({ action, code, type }));
      onComplete();
    };

    window.addEventListener('keyup', listenButtonClick);
    window.addEventListener('mouseup', listenButtonClick);

    setActiveSell({ action, type });
    setOnCompleteFn((prevState): (() => void) => {
      prevState = onComplete;

      return prevState;
    });
  };

  const resetForm = (): void => {
    setActiveSell(null);
    setFormNotification(null);
  };

  const preventDefaults = (event: MouseEvent): boolean => {
    event.preventDefault();

    return false;
  };

  const handleDeleteBinding = (action: EKeyBindingsKeys, type: EKeyPriorities): void => {
    dispatch(removeKeyBinding({ action, type }));
  };

  const handleSaveBindings = (e: React.FormEvent<HTMLDivElement>): void => {
    e.preventDefault();
    dispatch(syncKeyBindings({}));
  };

  const handleResetBindings = (): void => {
    setIsResetSettingsClicked(true);

    if (onCompleteFn) {
      onCompleteFn();
    }

    dispatch(removeKeyBindings({}));
    resetForm();

    setTimeout((): void => {
      setIsResetSettingsClicked(false);
    }, 500);
  };

  const mapKeyBindingsIntoJSX = (bindings: KeyBindingsMap): JSX.Element[] => {
    const bindingsKeys = Object.keys(bindings) as EKeyBindingsKeys[];

    return bindingsKeys
    //@ts-ignore
      .sort((a: string, b: string): number => EActionsKeysUIOrder[a] - EActionsKeysUIOrder[b])
      .map(
        (name): JSX.Element => (
          <React.Fragment key={name}>
            <Styled.TableRowTitle>
              <span>{transformActionKeyName(name)}</span>

              {name === EKeyBindingsKeys.speedControl && (
                <CustomTooltip
                  content='When you hold this button and scroll, your movement speed will be set to a custom value.'
                  placement={'top'}
                >
                  <Styled.Hint>?</Styled.Hint>
                </CustomTooltip>
              )}
            </Styled.TableRowTitle>

            <Styled.TableRowItem
              $isActive={activeSell?.action === name && activeSell.type === EKeyPriorities.primary}
            >
              {activeSell?.action === name && activeSell.type === EKeyPriorities.primary
                ? '???'
                : shortenKeyName(bindings[name].primaryKey)}

              <Styled.TableItemToolsContainer>
                <Styled.TableItemToolsItem
                  src={EditIcon}
                  onClick={(): void => {
                    handleChangeBinding(name, EKeyPriorities.primary, bindings[name].primaryKey);
                  }}
                />
                <Styled.TableItemToolsItem
                  src={DeleteIcon}
                  onClick={(): void => {
                    handleDeleteBinding(name, EKeyPriorities.primary);
                  }}
                />
              </Styled.TableItemToolsContainer>
            </Styled.TableRowItem>

            <Styled.TableRowItem
              $isActive={activeSell?.action === name && activeSell.type === EKeyPriorities.secondary}
            >
              {activeSell?.action === name && activeSell.type === EKeyPriorities.secondary
                ? '???'
                : shortenKeyName(bindings[name].secondaryKey)}

              <Styled.TableItemToolsContainer>
                <Styled.TableItemToolsItem
                  src={EditIcon}
                  onClick={(): void => {
                    handleChangeBinding(
                      name,
                      EKeyPriorities.secondary,
                      bindings[name].secondaryKey
                    );
                  }}
                />
                <Styled.TableItemToolsItem
                  src={DeleteIcon}
                  onClick={(): void => {
                    handleDeleteBinding(name, EKeyPriorities.secondary);
                  }}
                />
              </Styled.TableItemToolsContainer>
            </Styled.TableRowItem>
          </React.Fragment>
        )
      );
  };

  return (
    <Styled.TabContent>
      <Styled.FormContainer ref={formRef} onSubmit={handleSaveBindings}>
        <Styled.ViewerSettingsForm>
          <Styled.FormHeader>
            <Styled.FormHeaderLeftSide>
              <Styled.FormContentTitle>Camera Controls</Styled.FormContentTitle>
              <CustomTooltip
                content='Camera controls are used to navigate the 3D model viewing window.'
                placement={'top'}
              >
                <Styled.Hint>?</Styled.Hint>
              </CustomTooltip>
            </Styled.FormHeaderLeftSide>

            <Styled.FormHeaderRightSide id={'reset-button'} onClick={handleResetBindings}>
              <span>Reset to default</span>
              <Styled.FormHeaderRightSideArrow
                src={ResetSettingsArrow}
                $isClicked={isResetSettingClicked}
              />
            </Styled.FormHeaderRightSide>
          </Styled.FormHeader>

          <Styled.FormContent>
            <Styled.FormContentSubTitle>
              To change, select setting then enter your input.
            </Styled.FormContentSubTitle>

            <Styled.FormContentBlock>
              <Styled.FormKeybindingTable>
                <>
                  <span />

                  <Styled.TableColumnTitle>Primary</Styled.TableColumnTitle>

                  <Styled.TableColumnTitle $isSecondary>Secondary</Styled.TableColumnTitle>
                </>

                {mapKeyBindingsIntoJSX(keyBindings).slice(0, -5)}
              </Styled.FormKeybindingTable>

              <Styled.FormKeybindingTable>
                {mapKeyBindingsIntoJSX(keyBindings).slice(-5, -2)}
              </Styled.FormKeybindingTable>

              {!formNotification ? (
                isKeyBindingsUnsynced && (
                  <Styled.FormSubmitButton type={'submit'}>Save Changes</Styled.FormSubmitButton>
                )
              ) : (
                <Styled.FormToast>{formNotification}</Styled.FormToast>
              )}
            </Styled.FormContentBlock>
          </Styled.FormContent>
            <Styled.FormHeader>
            <Styled.FormHeaderLeftSide>
              <Styled.FormContentTitle>View Controls</Styled.FormContentTitle>
              <CustomTooltip
                content='View controls are used to toggle the state of the UI between layouts.'
                placement={'top'}
              >
                <Styled.Hint>?</Styled.Hint>
              </CustomTooltip>
            </Styled.FormHeaderLeftSide>

          </Styled.FormHeader>
          <Styled.FormContent>
            <Styled.FormContentSubTitle>
              To change, select setting then enter your input.
            </Styled.FormContentSubTitle>

            <Styled.FormContentBlock>
              <Styled.FormKeybindingTable>
                <>
                  <span />

                  <Styled.TableColumnTitle>Primary</Styled.TableColumnTitle>

                  <Styled.TableColumnTitle $isSecondary={true}>Secondary</Styled.TableColumnTitle>
                </>

                {mapKeyBindingsIntoJSX(keyBindings).slice(-2)}
              </Styled.FormKeybindingTable>

              {!formNotification ? (
                isKeyBindingsUnsynced && (
                  <Styled.FormSubmitButton type={'submit'}>Save Changes</Styled.FormSubmitButton>
                )
              ) : (
                <Styled.FormToast>{formNotification}</Styled.FormToast>
              )}
            </Styled.FormContentBlock>
          </Styled.FormContent>
        </Styled.ViewerSettingsForm>
      </Styled.FormContainer>
    </Styled.TabContent>
  );
};

export default CameraControlsTab;
