import * as THREE from 'three';
import { MainScene } from 'shared/webgl/scenes';
import { CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer';
import {
  DIMENSIONS_CUBE_FACE_X_ID,
  DIMENSIONS_CUBE_FACE_Y_ID,
  DIMENSIONS_CUBE_FACE_Z_ID
} from 'shared/constants/html-elements-ids';
import { DimensionUnits } from 'shared/interfaces';
import {
  CalcBoundingBoxNDataResult,
  CreateFacesData,
  GetFacesResult,
  SetupFaceData,
  UpdateFaceData
} from 'shared/interfaces';

export class ModelDimensionsController {
  private cubeObject: THREE.Object3D = new THREE.Object3D();
  private xFace: CSS3DObject | undefined;
  private yFace: CSS3DObject | undefined;
  private zFace: CSS3DObject | undefined;
  private factor: number = 1;
  private xFaceRotation: THREE.Vector3 = new THREE.Vector3(0, 0, 0);
  private yFaceRotation: THREE.Vector3 = new THREE.Vector3(0, 0, Math.PI / 2);
  private zFaceRotation: THREE.Vector3 = new THREE.Vector3(0, Math.PI / 2, 0);
  private sharpness: number = 10000;
  private offset: number = 0.08;
  private scale: number = 1;
  private initialized: boolean = false;
  private visible: boolean = false;

  constructor(private readonly mainScene: MainScene) {}

  get scaledSharpness(): number {
    return this.sharpness / this.scale;
  }

  get scaledOffset(): number {
    return this.offset * this.scale;
  }

  private updateFace({ face, size, position, text, rotation }: UpdateFaceData): void {
    if (!face.element.children.length) return;

    face.element.style.width = size * this.scaledSharpness + 'px';
    face.element.children[1].innerHTML = text;

    face.position.copy(position).multiplyScalar(this.scaledSharpness);

    if (rotation) {
      face.rotation.setFromVector3(rotation);
    }
  }

  private setupFace(props: SetupFaceData): CSS3DObject {
    const objectCSS = new CSS3DObject(props.element);

    this.updateFace({...props, face: objectCSS});

    return objectCSS;
  }

  private getFaces(parent: HTMLElement): GetFacesResult {
    return Array.from(parent.children).reduce((acc, item): GetFacesResult => {
      switch (true) {
        case item.id === DIMENSIONS_CUBE_FACE_X_ID:
          acc.xFaceEl = item as HTMLDivElement;
          break;

        case item.id === DIMENSIONS_CUBE_FACE_Y_ID:
          acc.yFaceEl = item as HTMLDivElement;
          break;

        case item.id === DIMENSIONS_CUBE_FACE_Z_ID:
          acc.zFaceEl = item as HTMLDivElement;
          break;

        default:
          break;
      }
      return acc;
    }, {} as GetFacesResult);
  }

  private calcBoundingBoxNData(): CalcBoundingBoxNDataResult | undefined {
    if (!this.mainScene.model) return;

    const IOWPos = this.mainScene.objectWrapper.position;
    const object = this.mainScene.objectWrapper.clone();
    object.rotation.y = 0;
    const bb = new THREE.Box3().setFromObject(object, true);
    const BBSize = bb.getSize(new THREE.Vector3());
    const units =
      Math.max(BBSize.x / this.factor, BBSize.y / this.factor, BBSize.z / this.factor) > 1
        ? DimensionUnits.m
        : DimensionUnits.cm;

    const xFacePosition = new THREE.Vector3(IOWPos.x, IOWPos.y, IOWPos.z + BBSize.z / 2 + this.scaledOffset);
    const yFacePosition = new THREE.Vector3(
      IOWPos.x + BBSize.x / 2 + this.scaledOffset,
      IOWPos.y + BBSize.y / 2,
      IOWPos.z - BBSize.z / 2 - this.scaledOffset
    );
    const zFacePosition = new THREE.Vector3(IOWPos.x + BBSize.x / 2 + this.scaledOffset, IOWPos.y, IOWPos.z);

    const xFaceText =
      ((BBSize.x / this.factor) * (units === DimensionUnits.cm ? 100 : 1)).toFixed(2) + ' ' + units;
    const yFaceText =
      ((BBSize.y / this.factor) * (units === DimensionUnits.cm ? 100 : 1)).toFixed(2) + ' ' + units;
    const zFaceText =
      ((BBSize.z / this.factor) * (units === DimensionUnits.cm ? 100 : 1)).toFixed(2) + ' ' + units;

    return {
      BBSize,
      xFacePosition,
      yFacePosition,
      zFacePosition,
      xFaceText,
      yFaceText,
      zFaceText
    };
  }

  public create({ parent, factor, scale }: CreateFacesData): void {
    this.initialized = false;

    this.mainScene.dimensionsWrapper.clear();

    if (!parent) return;

    this.factor = factor;
    this.scale = scale;

    const data = this.calcBoundingBoxNData();

    if (!data) return;

    const { BBSize, xFacePosition, yFacePosition, zFacePosition, xFaceText, yFaceText, zFaceText } =
      data;

    this.cubeObject.scale.setScalar(1 / this.scaledSharpness);
    this.cubeObject.position.setScalar(0);

    const { xFaceEl, yFaceEl, zFaceEl } = this.getFaces(parent);

    this.xFace = this.setupFace({
      element: xFaceEl,
      position: xFacePosition,
      rotation: this.xFaceRotation,
      size: BBSize.x,
      text: xFaceText
    });
    this.yFace = this.setupFace({
      element: yFaceEl,
      position: yFacePosition,
      rotation: this.yFaceRotation,
      size: BBSize.y,
      text: yFaceText
    });
    this.zFace = this.setupFace({
      element: zFaceEl,
      position: zFacePosition,
      rotation: this.zFaceRotation,
      size: BBSize.z,
      text: zFaceText
    });

    this.cubeObject.add(this.xFace);
    this.cubeObject.add(this.yFace);
    this.cubeObject.add(this.zFace);
    this.mainScene.dimensionsWrapper.add(this.cubeObject);

    this.initialized = true;
  }

  public update({factor, scale}: { factor?: number, scale?: number }): void {
    if (!this.initialized || !this.visible) return;

    if (factor !== undefined) {
      this.factor = factor;
    }

    if (scale !== undefined) {
      this.scale = scale;
    }

    const data = this.calcBoundingBoxNData();

    if (!data || !this.xFace || !this.yFace || !this.zFace) return;

    const { BBSize, xFacePosition, yFacePosition, zFacePosition, xFaceText, yFaceText, zFaceText } =
      data;

    this.updateFace({ face: this.xFace, position: xFacePosition, text: xFaceText, size: BBSize.x });
    this.updateFace({ face: this.yFace, position: yFacePosition, text: yFaceText, size: BBSize.y });
    this.updateFace({ face: this.zFace, position: zFacePosition, text: zFaceText, size: BBSize.z });

    this.cubeObject.scale.setScalar(1 / this.scaledSharpness);
  }

  public toggleVisibility(visible: boolean): void {
    this.visible = visible;
    this.mainScene.css3DRenderer.domElement.hidden = !visible;

    if (visible) {
      this.update({});
    }
  }
}
