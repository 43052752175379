import { BaseSsStrategy } from 'services/strategy-services/create-screenshot-service/strategies/base-ss.strategy';
import { CreateScreenshotData, CSInitializationExtendedData } from 'shared/interfaces';

export class UserModelSsStrategy extends BaseSsStrategy {
  constructor({ mainScene, width, height }: CSInitializationExtendedData) {
    super({ mainScene });

    this.setSize(width, height);
  }

  public async createScreenshot({cubemapController}: CreateScreenshotData): Promise<string> {
    const { screenshotRenderer, scene, screenshotCamera } = this.mainScene;

    this.setupRenderer();
    this.setupCamera();
    this.setupCanvasTDO();
    const restoreCubemap = this.cheatCubemap(cubemapController);
    
    if (!this.canvasTDO || !this.contextTDO) return '';
    
    const wasEnvironmentVisible = this.mainScene.environmentWrapper.visible;
    this.mainScene.environmentWrapper.visible = false;
    screenshotRenderer.render(scene, screenshotCamera);
    this.mainScene.environmentWrapper.visible = wasEnvironmentVisible;

    this.contextTDO.drawImage(
      screenshotRenderer.domElement,
      0,
      0,
      this.coverImageWidth,
      this.coverImageHeight
    );

    this.contextTDO.globalCompositeOperation = 'destination-in';

    screenshotCamera.layers.enableAll();
    screenshotCamera.layers.disable(1);

    screenshotRenderer.render(scene, screenshotCamera);

    screenshotCamera.layers.enableAll();

    this.contextTDO.drawImage(
      screenshotRenderer.domElement,
      0,
      0,
      this.coverImageWidth,
      this.coverImageHeight
    );

    restoreCubemap();

    return this.canvasTDO.toDataURL('image/png');
  }
}
