import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';

export const HomePageContainer = styled.div<{
  $isArMode: boolean;
  $isModelLoaded: boolean;
  $isFullHeight: boolean;
}>`
  width: 100%;
  height: ${({ $isModelLoaded, $isFullHeight }): string =>
    $isFullHeight ? 'initial' : $isModelLoaded ? 'calc(100vh - 118px)' : 'calc(100vh - 82px)'};
  background: ${COLORS.codGrayGradient};
  position: ${({ $isArMode }): string => ($isArMode ? 'absolute' : 'relative')};
  top: 0;
  left: 0;

  @media${DEVICE.tablet} {
    height: ${({ $isFullHeight }): string =>
      $isFullHeight ? 'var(--viewport-height)' : 'calc(var(--viewport-height) - 38px)'};
  }
`;

export const PageContent = styled.div<{ $isArMode: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: min-content;
  padding: 0 11px;
  z-index: ${({ $isArMode }): string => ($isArMode ? '11' : '1')};
`;
