import { EPremiumFeature } from 'shared/types';
import ArPreview from 'assets/gifs/ar-feature-preview.gif';
import CommentsPreview from 'assets/gifs/comments-feature-preview.gif';
import SceneSettingsPreview from 'assets/gifs/scene-settings-feature-preview.gif';

type Features = {
  [key in EPremiumFeature]: {
    title: string;
    description: string;
    preview: string;
    lockedMessage?: string;
  };
};

export const FEATURES: Features = {
  [EPremiumFeature.AR]: {
    title: 'Augmented Reality Viewing',
    description:
      'View your 3D models in Augmented Reality. Using the View in AR premium feature, you can tap to view any of your 3D models in Web-based Augmented Reality instantly. No app download required, simply tap the ‘AR’ feature button to launch the AR viewing mode and see your models realistically augmented into your own environment.',
    preview: ArPreview,
    lockedMessage: 'Augmented Reality Viewing on this model is disabled. Contact the model owner to enable AR View.'
  },
  [EPremiumFeature.COMMENTS]: {
    title: 'Commenting',
    description:
      'Comments take collaboration to the next level. Use comments to respond to feedback and collaborate in real-time on your 3D models. Add a comment to a pinned location on your model and the camera position is automatically saved, enabling others to tap to view the comment and watch as the camera automatically moves to its original position!',
    preview: CommentsPreview,
    lockedMessage: 'Commenting on this model is currently disabled. Contact the model owner to enable Comments.'
  },
  [EPremiumFeature.SCENE_SETTINGS]: {
    title: 'All Lighting, Environments, and Cubemaps',
    description:
      'Scene settings are a great way to enhance the presentation of your 3D models. Use additional lighting, environment, and cubemap options to ensure you get the perfect look for your 3D models. Then, share this perfect look with your team and friends!',
    preview: SceneSettingsPreview
  }
};
