import React, { JSX, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import * as Styled from './styles';
import { useAppSelector } from 'shared/hooks';
import { CameraController, LightController, ObjectController } from 'shared/webgl/controllers';
import { useEnvironmentController } from './hooks';
import { ELightPresets } from 'shared/enums/ELightsPresets';
import { CubemapImagePaths, ESnackbarStyle } from 'shared/types';
import useCubemapController from './hooks/cubemap-controller/useCubemapController';
import { INITIAL_MODEL_SETTINGS } from 'shared/constants/model-settings';
import { openNotification } from 'utils/notification-utils';
import { E3DModelFileTypes } from 'shared/enums/E3DModelFileTypes';
import { RootState } from 'services/store';
import { ScenesGlobalStateService } from 'services/strategy-services';
import { MainScene } from 'shared/webgl/scenes';
import { preventDefaults } from 'utils/dom-utils';
import { CUSTOMIZATION_MAIN_SCENE_CANVAS_ID } from 'shared/constants/html-elements-ids';

type Props = {
  isPreviewMode?: boolean;
  modelURL?: string;
  environmentPreviewURL?: string | null;
  cubemapImagePaths?: CubemapImagePaths;
};

const scenesGlobalStateService = new ScenesGlobalStateService();

const PreviewViewerController: React.FC<Props> = ({
  isPreviewMode,
  modelURL,
  environmentPreviewURL,
  cubemapImagePaths
}): JSX.Element => {
  const referenceModelURL = `${process.env.REACT_APP_URL}/resources/samples/logo_glbee/logo-glbee.glb`;

  const viewerControllerRef = useRef<HTMLDivElement | null>(null);
  const mainScene = useMemo((): MainScene => new MainScene(CUSTOMIZATION_MAIN_SCENE_CANVAS_ID), []);
  const lightController = useMemo((): LightController => new LightController(mainScene), []);
  const objectController = useMemo(
    (): ObjectController => new ObjectController(mainScene),
    [mainScene]
  );
  const cameraController = useMemo(
    (): CameraController => new CameraController(mainScene),
    [mainScene]
  );

  const store = useAppSelector((store): RootState => store);
  const { keyBindings } = store.keyBindings;
  const { setImagePaths } = useCubemapController(mainScene.scene);
  const { setEnvironmentPath } = useEnvironmentController(mainScene);

  useEffect((): (() => void) => {
    scenesGlobalStateService.addScenes(mainScene);

    if (viewerControllerRef.current) {
      viewerControllerRef.current.addEventListener('contextmenu', preventDefaults);
    }

    return (): void => {
      cameraController.remove();
      scenesGlobalStateService.clearScenes();

      if (viewerControllerRef.current) {
        viewerControllerRef.current.removeEventListener('contextmenu', preventDefaults);
      }
    };
  }, []);

  useEffect((): void => {
    if (!!environmentPreviewURL) {
      setEnvironmentPath(environmentPreviewURL);
    }
  }, [environmentPreviewURL, setEnvironmentPath]);

  useEffect((): void => {
    if (!!cubemapImagePaths) {
      setImagePaths(cubemapImagePaths);
    }
  }, [cubemapImagePaths, setImagePaths]);

  useEffect((): void => {
    cameraController.setKeybindingsMap(keyBindings);
  }, [cameraController, keyBindings]);

  useLayoutEffect((): void => {
    setUpControllers();
    setUpScene();
    setUpCameraController();
  }, []);

  const loadModel = async (path: string): Promise<void> => {
    await mainScene
      .loadModel(path, E3DModelFileTypes.glb)
      .catch((e: { message: string | undefined }): any => {
        openNotification(ESnackbarStyle.ERROR, e.message);
        return;
      });

    mainScene.objectWrapper.add(mainScene.model!);
    objectController.positioned = false;
  };

  const setUpControllers = async (): Promise<void> => {
    lightController.setUp(mainScene.lightWrapper, mainScene.objectWrapper);

    if (!!modelURL) {
      await loadModel(modelURL);
    }

    if (isPreviewMode) {
      await loadModel(referenceModelURL);
    }

    objectController.switchScene({
      modelDimensionsBlock: null,
      scaleFactor: INITIAL_MODEL_SETTINGS.scaleFactor.x,
      firstSetup: true,
      isCustomizationMode: true
    });

    updateScene();
  };

  const setUpScene = (): void => {
    if (!!viewerControllerRef.current) {
      mainScene.mounting({ parent: viewerControllerRef.current });

      setTimeout((): void => {
        mainScene.updateViewerSize();
      }, 100);
    }
  };

  const setUpCameraController = (): void => {
    cameraController.setUp({
      listeningBlock: viewerControllerRef.current,
      tooltipBlock: null
    });
    cameraController.init();
  };

  const updateScene = (): void => {
    lightController.updateByOptions({
      lightPreset: ELightPresets.ModelViewer,
      castShadow: false
    });

    objectController.updateByOptions({
      ...INITIAL_MODEL_SETTINGS,
      scaleFactor: INITIAL_MODEL_SETTINGS.scaleFactor.x,
      firstSetup: true
    });
  };

  return (
    <>
      <Styled.Viewer $isPreviewMode={!!isPreviewMode} ref={viewerControllerRef}></Styled.Viewer>
    </>
  );
};

export default PreviewViewerController;
