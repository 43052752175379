import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEVICE, DEVICE_SIZES } from 'shared/constants/deviceSizes';
import { DEFAULT_THEME_CSS_VARIABLES } from 'shared/constants/customization';

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 95vh;
  max-height: 740px;
  max-width: 1200px;
  width: 90vw;
  color: ${COLORS.white};
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.modalBackgroundColor};
  overflow: hidden;
  padding: 50px 0 50px 50px;
  border-radius: 7px;

  @media ${DEVICE.tabletLarge} {
    max-height: none;
    overflow-y: auto;
    padding: 50px;
  }
`;

export const ModelTitleContainer = styled.div`
  margin-bottom: 20px;
  width: 100%;
  
  h1 {
    padding-top: 0;
    
    @media (min-width: ${DEVICE_SIZES.tabletLarge}px) {
      padding-right: 50px;
    }
  }
`;

export const TitleText = styled.span`
  display: inline;
`;

export const Lock = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.turbo};
  width: 28px;
  min-width: 28px;
  height: 28px;
  margin: 0 0 7px 15px;
  vertical-align: middle;

  img {
    width: 20px;
    height: 20px;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  width: 100%;
  height: calc(100% - 70px);
  overflow: hidden;

  @media ${DEVICE.tabletLarge} {
    flex-direction: column;
    height: auto;
    overflow: visible;
  }
`;

export const LeftSide = styled.section`
  position: relative;
  width: 30%;
  min-width: 320px;
  padding-right: 15px;
  padding-bottom: 25px;

  @media (max-width: 1245px) {
    width: 40%;
  }

  @media${DEVICE.tabletLarge} {
    width: 100%;
    min-width: unset;
  }
`;

export const FeatureGifContainer = styled.div`
  margin: 30px 0;
  width: 100%;

  img {
    width: 100%;
    max-height: 410px;
    object-fit: contain;
    border-radius: 2px;
  }
`;

export const RightSide = styled.section`
  position: relative;
  width: 70%;
  height: 100%;
  padding-left: 25px;
  overflow-y: auto;
  container-type: inline-size;

  > * {
    padding-right: 60px;  // 50px + scrollbar width
  }

  @media (max-width: 1245px) {
    width: 60%;
  }

  @media ${DEVICE.tabletLarge} {
    width: 100%;
    height: auto;
    padding: 0 30px 30px;
    overflow: visible;

    > * {
      padding-right: 0;
    }
  }
`;
