import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';

export const PageTitle = styled.h1`
  font-size: 32px;
  font-weight: 400;
  color: ${COLORS.white};
  margin: 10px 0 0 0;
  line-height: 32px;

  @media ${DEVICE.tablet} {
    padding: 0 30px;
  }
`;

export const PageSubtitle = styled.p`
  color: ${COLORS.doveGray};
  font-size: 16px;
  line-height: 20px;
  margin-top: 15px;
  margin-bottom: 30px;

  @media ${DEVICE.tablet} {
    padding: 0 30px;
  }
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;  // cardsize * cards + max_gap-margin = 320*3 + 30*2-20 = 1000
`;
