import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import * as Styled from './styles';
import Collapse from 'antd/lib/collapse/Collapse';
import { ModalCreateAccount, SubscriptionInfo } from 'shared/components';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { AuthState, ESnackbarStyle, EPaymentType } from 'shared/types';
import { showModal } from 'services/store/reducers/modalReducer';
import { FAQS_LIST, TESTIMONIAL_LIST } from './constants';
import { DEVICE_SIZES } from 'shared/constants/deviceSizes';
import { openNotification } from 'utils/notification-utils';
import LeftOutlined from '@ant-design/icons/lib/icons/LeftOutlined';

type Testimonial = {
  id: number;
  quote: string;
  text: string;
  userName: string;
  userPosition: string;
};

const PricingPage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { isAuth } = useAppSelector((state): AuthState => state.auth);
  const [email, setEmail] = useState<string>('');
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const { Panel } = Collapse;
  const getSlideSize = (): number => {
    switch (true) {
      case window.innerWidth >= DEVICE_SIZES.desktopLarge:
        return 3;
      case window.innerWidth >= DEVICE_SIZES.desktop:
        return 2;
      default:
        return 1;
    }
  };
  const [testimonialsInSlide, setTestimonialsInSlide] = useState<number>(getSlideSize());
  const totalSlides = Math.ceil(TESTIMONIAL_LIST.length / testimonialsInSlide);

  const onResize = useCallback((): void => {
    setTestimonialsInSlide(getSlideSize());
    setActiveSlide(0);
  }, []);

  window.addEventListener('resize', onResize);

  useEffect(
    (): (() => void) => (): void => {
      window.removeEventListener('resize', onResize);
    },
    [onResize]
  );

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setEmail(value);
  };

  const handleGetStartedClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    if (isAuth) {
      openNotification(ESnackbarStyle.HOLD_UP, 'You are already logged in!');
      return;
    }
    dispatch(showModal(<ModalCreateAccount email={email} />));
  };

  const SLIDES = useMemo((): Testimonial[][] => {
    let slides: Testimonial[][] = [];
    let slide: Testimonial[] = [];
    TESTIMONIAL_LIST.forEach((testimonial, index): void => {
      slide = [...slide, testimonial];
      if (!((index + 1) % testimonialsInSlide)) {
        slides = [...slides, slide];
        slide = [];
      }
    });
    if (!!slide.length) {
      slides = [...slides, slide];
    }
    return slides;
  }, [testimonialsInSlide]);

  const handlePrevButtonClick = (): void => {
    setActiveSlide((prev): number => (prev - 1 >= 0 ? prev - 1 : prev));
  };

  const handleNextButtonClick = (): void => {
    setActiveSlide((prev): number => (prev + 1 < totalSlides ? prev + 1 : prev));
  };

  const handleNavDotClick =
    (activeSlide: number): (() => void) =>
    (): void => {
      setActiveSlide(activeSlide);
    };

  return (
    <Styled.PageContainer>
      <Styled.ContentWrapper>
        <Styled.PageContent>
          <Styled.SubscriptionsContainer>
            <SubscriptionInfo 
              withCustomPlan 
              title="Choose the plan that works for you"
              paymentType={EPaymentType.UPDATE_USER_PLAN}
            />
          </Styled.SubscriptionsContainer>
          <Styled.GetStartedSection>
            {!isAuth && (
              <Styled.GetStartedLeftSide>
                <Styled.LeftSideContent>
                  <Styled.FormTitle>Get started with a free plan today</Styled.FormTitle>
                  <Styled.FormSubtitle>
                    Create a free account and experience the platform today
                  </Styled.FormSubtitle>
                  <Styled.GetStartedForm>
                    <Styled.FormInput
                      value={email}
                      onChange={handleEmailChange}
                      type='email'
                      placeholder='Your email address'
                    />
                    <Styled.SubmitButton onClick={handleGetStartedClick}>
                      Get Started
                    </Styled.SubmitButton>
                  </Styled.GetStartedForm>
                </Styled.LeftSideContent>
              </Styled.GetStartedLeftSide>
            )}
            <Styled.GetStartedRightSide $isAuth={isAuth}>
              <Styled.VideoContainer>
                <iframe
                  width='100%'
                  height='100%'
                  src='https://www.youtube.com/embed/Yb_XBp63Sug'
                  title='YouTube video player'
                  frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                  allowFullScreen
                ></iframe>
              </Styled.VideoContainer>
            </Styled.GetStartedRightSide>
          </Styled.GetStartedSection>

          {/*<Styled.TestimonialsSection>*/}
          {/*  <Styled.SliderContainer>*/}
          {/*    <Styled.Slider>*/}
          {/*      {SLIDES.map(*/}
          {/*        (slide, index): JSX.Element => (*/}
          {/*          <Styled.Slide activeSlide={activeSlide} key={index}>*/}
          {/*            {slide.map(*/}
          {/*              ({ id, quote, text, userName, userPosition }): JSX.Element => (*/}
          {/*                <Styled.TestimonialCard key={id}>*/}
          {/*                  <Styled.CardHeader>{quote}</Styled.CardHeader>*/}
          {/*                  <Styled.LogoWrapper>*/}
          {/*                    <Styled.LogoIcon src={Logo} />*/}
          {/*                  </Styled.LogoWrapper>*/}
          {/*                  <Styled.CardContent>*/}
          {/*                    <Styled.ContentText>{text}</Styled.ContentText>*/}
          {/*                    <Styled.UserName>{userName}</Styled.UserName>*/}
          {/*                    <Styled.UserPosition>{userPosition}</Styled.UserPosition>*/}
          {/*                  </Styled.CardContent>*/}
          {/*                </Styled.TestimonialCard>*/}
          {/*              )*/}
          {/*            )}*/}
          {/*          </Styled.Slide>*/}
          {/*        )*/}
          {/*      )}*/}
          {/*    </Styled.Slider>*/}

          {/*    <Styled.SliderFooter>*/}
          {/*      <Styled.SliderController*/}
          {/*        className='arrow-left-mobile'*/}
          {/*        onClick={handlePrevButtonClick}*/}
          {/*      >*/}
          {/*        <img src={Arrow} alt='Slider Arrow' />*/}
          {/*      </Styled.SliderController>*/}
          {/*      <Styled.NavigationDots>*/}
          {/*        {Array.from(Array(totalSlides).keys()).map(*/}
          {/*          (dotIndex): JSX.Element => (*/}
          {/*            <Styled.Dot*/}
          {/*              onClick={handleNavDotClick(dotIndex)}*/}
          {/*              isActive={dotIndex === activeSlide}*/}
          {/*              key={dotIndex}*/}
          {/*            />*/}
          {/*          )*/}
          {/*        )}*/}
          {/*      </Styled.NavigationDots>*/}
          {/*      <Styled.ControllersContainer>*/}
          {/*        <Styled.SliderController className='arrow-left' onClick={handlePrevButtonClick}>*/}
          {/*          <img src={Arrow} alt='Slider Arrow' />*/}
          {/*        </Styled.SliderController>*/}
          {/*        <Styled.SliderController onClick={handleNextButtonClick}>*/}
          {/*          <img src={Arrow} alt='Slider Arrow' />*/}
          {/*        </Styled.SliderController>*/}
          {/*      </Styled.ControllersContainer>*/}
          {/*    </Styled.SliderFooter>*/}
          {/*  </Styled.SliderContainer>*/}
          {/*  <Styled.BackgroundLine />*/}
          {/*  <Styled.SectionRightSide>*/}
          {/*    <Styled.TestimonialsTitle>Testimonials</Styled.TestimonialsTitle>*/}
          {/*    <Styled.TestimonialsDescription>*/}
          {/*      Don't just take our word for it, read the testimonials, where users share their*/}
          {/*      feedback and praise the platform's simplicity, seamless integration with 3D modeling*/}
          {/*      software, speedy upload and download times, and high-quality 3D models.*/}
          {/*    </Styled.TestimonialsDescription>*/}
          {/*  </Styled.SectionRightSide>*/}
          {/*</Styled.TestimonialsSection>*/}

          <Styled.FAQSection>
            <Styled.FAQSectionTitle>FAQs</Styled.FAQSectionTitle>
            <Styled.FAQSectionContent>
              {FAQS_LIST.map(
                ({ id, question, answer }): JSX.Element => (
                  <Collapse
                    bordered={false}
                    expandIconPosition={'end'}
                    expandIcon={({ isActive }): JSX.Element => (
                      <LeftOutlined
                        style={{ color: '#ffffff', fontSize: '24px' }}
                        rotate={isActive ? 90 : -90}
                      />
                    )}
                    style={{ background: 'transparent', color: '#ffffff' }}
                    key={id}
                  >
                    <Panel
                      header={<Styled.Question>{question}</Styled.Question>}
                      style={{ background: 'transparent', color: '#ffffff' }}
                      key={id}
                    >
                      <Styled.Answer>{answer}</Styled.Answer>
                    </Panel>
                  </Collapse>
                )
              )}
            </Styled.FAQSectionContent>
          </Styled.FAQSection>
        </Styled.PageContent>
      </Styled.ContentWrapper>
    </Styled.PageContainer>
  );
};

export default PricingPage;
