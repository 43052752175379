import { useAppSelector } from 'shared/hooks/store-hooks';
import { RootState } from 'services/store';
import { ESnackbarStyle, EModelType, ModelSettings } from 'shared/types';
import { MAX_MODEL_NAME_LENGTH } from 'shared/constants/limits';
import { getChangedModel } from 'utils/model-utils';
import { updateModel } from 'services/api/modelService';
import {
  getEmbeddedModelSettingsStatus,
  setModelDataLocalStorage,
  updateModelInSessionStorage
} from 'utils/storage-utils';
import { openNotification } from 'utils/notification-utils';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDebounce } from 'shared/hooks';
import { CHANGES_SAVED } from 'shared/constants/notifications';
import { API_REQUEST_CANCELED_CODE } from 'shared/constants/errors';

type Result = {
  isSettingsSaving: boolean;
};

type Settings = {
  modelSettings: ModelSettings;
  modelName: string;
};

type Axes = { x: number; y: number; z: number };

const useAutoSave = (): Result => {
  const store = useAppSelector((store): RootState => store);
  const {
    model,
    modelSettings,
    modelName,
    modelType,
    isSceneCompletelyLoaded,
    isEmbeddedModelMode
  } = store.viewerData;
  const { activeBranding } = store.branding;
  const { hasEditAccess } = store.modelFeatureAccess;
  const [isSettingsSaving, setIsSettingsSaving] = useState<boolean>(false);
  const updates = useMemo(
    (): Settings => ({ modelSettings, modelName }),
    [modelSettings, modelName]
  );
  const debouncedSettings = useDebounce(updates, 2000);
  const lastSavedSettings = useDebounce(debouncedSettings, 4000);
  const prevSettings = useRef<Settings>({ modelSettings, modelName });
  const isInitModelSetup = useRef<boolean>(true);
  const isAnonymousModel = modelType === EModelType.ANONYMOUS;

  useEffect((): void => {
    const { modelSettings: prev, modelName: prevName } = prevSettings.current;
    const { modelSettings: current, modelName: currentName } = lastSavedSettings;
    const envKeys: (keyof ModelSettings)[] = ['lighting', 'environment', 'cubemap'];
    const positionKeys: (keyof ModelSettings)[] = ['offset', 'rotation', 'scale'];
    const axes: (keyof Axes)[] = ['x', 'y', 'z'];
    const isEnvChanged = envKeys.some((key): boolean => prev[key] !== current[key]);
    const isPositionChanged = positionKeys.some((key): boolean =>
      axes.some((axis): boolean => (prev[key] as Axes)[axis] !== (current[key] as Axes)[axis])
    );
    const isNameChanged = prevName !== currentName;
    if (hasEditAccess && (isEnvChanged || isPositionChanged || isNameChanged)) {
      openNotification(ESnackbarStyle.SUCCESS, CHANGES_SAVED);
    }
    prevSettings.current = lastSavedSettings;
  }, [hasEditAccess, lastSavedSettings]);

  useEffect((): void => {
    if (isAnonymousModel) {
      const { modelName, modelSettings } = debouncedSettings;
      setModelDataLocalStorage({ modelName, modelSettings, modelType: EModelType.ANONYMOUS });
    }
  }, [debouncedSettings, isAnonymousModel]);

  useEffect((): void => {
    const autoSaveModel = async (): Promise<void> => {
      if (!!model) {
        try {
          const { modelName, modelSettings } = debouncedSettings;
          const name = modelName.trim().slice(0, MAX_MODEL_NAME_LENGTH) || model.modelName;

          const changedModel = getChangedModel(
            name,
            modelSettings,
            activeBranding.id
          );

          await updateModel(changedModel, model.id);
          updateModelInSessionStorage(changedModel);
        } catch (e) {
          if (e?.code === API_REQUEST_CANCELED_CODE) return;
          openNotification(ESnackbarStyle.ERROR, e?.message);
        } finally {
          setIsSettingsSaving(false);
        }
      }
    };

    const hasChangeAccess =
      isSceneCompletelyLoaded &&
      hasEditAccess &&
      !isInitModelSetup.current &&
      !isEmbeddedModelMode &&
      !getEmbeddedModelSettingsStatus();

    if (hasChangeAccess) {
      setIsSettingsSaving(true);
      autoSaveModel();
    }
    isInitModelSetup.current = false;
  }, [
    activeBranding,
    debouncedSettings,
    hasEditAccess,
    isEmbeddedModelMode,
    isSceneCompletelyLoaded,
    model
  ]);

  return { isSettingsSaving };
};

export default useAutoSave;
