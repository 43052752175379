import styled from 'styled-components';

export const StatsBlock = styled.div<{
  $isVisible: boolean;
}>`
  position: relative;

  & > div {
    position: absolute !important;
    display: ${({ $isVisible }): string => ($isVisible ? 'flex' : 'none')};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0 !important;
    left: unset !important;
    right: 0 !important;
  }
`;
