import React, { JSX } from 'react';
import * as Styled from './styles';
import { CUBE_ORBIT_CONTROLLER_CUBE_FACE_ID } from 'shared/constants/html-elements-ids';

interface Props {
  cubeOrbitControllerBlockRef: React.RefObject<HTMLDivElement>;
  isEmbeddedModelMode?: boolean;
}

export const CubeOrbitController: React.FC<Props> = ({
  cubeOrbitControllerBlockRef,
  isEmbeddedModelMode
}): JSX.Element => {
  return (
    <Styled.Container
      $isEmbeddedModelMode={isEmbeddedModelMode || false}
      ref={cubeOrbitControllerBlockRef}
    >
      {Array(6)
        .fill(CUBE_ORBIT_CONTROLLER_CUBE_FACE_ID)
        .map(
          (value, index): JSX.Element => (
            <Styled.Face key={value + index} className={value} />
          )
        )}
    </Styled.Container>
  );
};
