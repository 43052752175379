import { DEVICE } from 'shared/constants/deviceSizes';
import styled from 'styled-components';

export const Viewer = styled.div<{
  $isZenMode: boolean;
  $isEmbeddedModelMode: boolean;
  $isEmbedModelScene: boolean;
}>`
  position: relative;
  width: 100%;
  height: ${({ $isZenMode, $isEmbeddedModelMode, $isEmbedModelScene }): string =>
    $isEmbedModelScene
      ? '100%'
      : $isEmbeddedModelMode
      ? '100vh'
      : $isZenMode
      ? 'calc(100vh - 36px)'
      : 'calc(100vh - 118px)'};
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  scrollbar-width: none;

  @media ${DEVICE.tablet} {
    height: ${({ $isZenMode, $isEmbeddedModelMode, $isEmbedModelScene }): string =>
      $isEmbedModelScene
        ? '100%'
        : $isEmbeddedModelMode
        ? 'var(--viewport-height)'
        : $isZenMode
        ? 'calc(var(--viewport-height) - 8px)'
        : 'calc(var(--viewport-height) - 44px)'};
  }
`;
