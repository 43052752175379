import React, { JSX, useCallback, useEffect, useMemo, useState } from 'react';
import * as Styled from './styles';
import * as THREE from 'three';
import { IViewerComment, IViewerCommentFromServer } from 'shared/interfaces';
import { useAppDispatch, useAppSelector, useModelInfoData } from 'shared/hooks';
import { RootState } from 'services/store';
import { useCommentsData } from 'shared/hooks/data-hooks/comments-data-hook/useCommentsData';
import { openNotification } from 'utils/notification-utils';
import { EPremiumFeature, ESnackbarStyle } from 'shared/types';
import { RESTRICT_COMMENTS, UPGRADE_YOUR_TEAM_PLAN } from 'shared/constants/notifications';
import { showModal } from 'services/store/reducers/modalReducer';
import { ModalFeatureSignedIn, ModalFeatureSignedOut } from '../../modals';
import { handleNewComment } from 'utils/comments-utils';
import {
  checkIfModelContainsSkinnedMesh,
  getObjectFullPath,
  intersectSkinnedObjectByBBs
} from 'utils/scenes-utils';
import { MAIN_SCENE_CANVAS_ID } from 'shared/constants/html-elements-ids';
import { setActiveCommentId, setIsCommentsBarActive } from 'services/store/reducers/commentsReducer';
import { useLocation } from 'react-router-dom';
import { ShortClickHandlerService } from 'services/strategy-services';
import { MainScene } from 'shared/webgl/scenes';
import { CameraController } from 'shared/webgl/controllers';
import { CommentCSS2DObjectWrapper } from '../comment-css-2d-object-wrapper';
import { checkIsIFrame } from 'utils/helper-utils';

interface Props {
  mainScene: MainScene;
  cameraController: CameraController;
  setEventsReceiver: (
    callback: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  ) => void;
  createScreenshot: (editor?: HTMLDivElement) => Promise<string>;
}

export const CommentsController: React.FC<Props> = ({
  mainScene,
  cameraController,
  setEventsReceiver,
  createScreenshot
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const commentId = params.get('commentId');
  const rayCaster = useMemo((): THREE.Raycaster => new THREE.Raycaster(), []);
  const shortClickHandlerService = useMemo(
    (): ShortClickHandlerService => new ShortClickHandlerService(),
    []
  );
  const store = useAppSelector((store): RootState => store);
  const { model, modelSettings, isSceneCompletelyLoaded, isModelInitialized, isEmbeddedModelMode } =
    store.viewerData;
  const { user, isAuth } = store.auth;
  const { isCommentsVisible, isCommentsBarActive, activeCommentId, comments } = store.comments;
  const { hasCommentAccess } = store.modelFeatureAccess;

  const [newComment, setNewComment] = useState<IViewerCommentFromServer>(handleNewComment());
  const [indexedMap, setIndexedMap] = useState<THREE.Object3D[]>([]);
  const [isCommentsLoaded, setIsCommentsLoaded] = useState<boolean>(false);

  const {
    isSampleModel,
    isAnonymousModel,
    isTeamModel,
    isModelOwner,
    isQuickView,
    isUMIDInitialized
  } = useModelInfoData(model, user);
  const { fetchComments, setOnCommentsFirstLoaded } = useCommentsData(model, user);

  useEffect((): void => {
    setOnCommentsFirstLoaded((): void => {
      setIsCommentsLoaded(true);
    });
  }, []);

  useEffect((): void => {
    if (!isUMIDInitialized) return;

    fetchComments({});
  }, [hasCommentAccess, isAuth, isSampleModel, isAnonymousModel, isQuickView, isUMIDInitialized]);

  useEffect((): void => {
    updateIndexedMap();
  }, [isModelInitialized]);

  useEffect((): void => {
    if (isCommentsLoaded && isSceneCompletelyLoaded && !!commentId) {
      updateIndexedMap();
      dispatch(setIsCommentsBarActive(!isCommentsBarActive));
      dispatch(setActiveCommentId(+commentId));
    }
  }, [isCommentsLoaded, isSceneCompletelyLoaded]);

  const getThreadElementIdByThreadId = useCallback(
    (id: IViewerComment['threadId'] | string): string => {
      return 'viewer-object-thread-' + id;
    },
    []
  );

  const updateIndexedMap = useCallback((): void => {
    if (!mainScene.objectWrapper.children[0]) return;

    const indexedMap: THREE.Object3D[] = [];

    mainScene.objectWrapper.children[0].traverse((object): void => {
      if (object.name !== 'comment') {
        indexedMap.push(object);
      }
    });
    setIndexedMap(indexedMap);
  }, [mainScene.objectWrapper]);

  const showPlansModalWindow = useCallback((): void => {
    if (!checkIsIFrame()) {
      if (isAuth) {
        isModelOwner
          ? dispatch(showModal(<ModalFeatureSignedIn feature={EPremiumFeature.COMMENTS} />))
          : openNotification(ESnackbarStyle.HOLD_UP, RESTRICT_COMMENTS);
      } else {
        dispatch(showModal(<ModalFeatureSignedOut feature={EPremiumFeature.COMMENTS} />));
      }
    }
  }, [dispatch, isAuth, isModelOwner]);

  const createComment = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent> | MouseEvent): void => {
      if (
        modelSettings.scale.x === 0 ||
        modelSettings.scale.y === 0 ||
        modelSettings.scale.z === 0
      ) {
        openNotification(
          ESnackbarStyle.HOLD_UP,
          'Impossible to create comment if scale is equal to 0!'
        );
        return;
      }

      const hasSkinnedMeshes = checkIfModelContainsSkinnedMesh(mainScene.objectWrapper);
      const clientRect = mainScene.renderer.domElement.getBoundingClientRect();
      const x = (event.clientX - clientRect.left) / clientRect.width;
      const y = (event.clientY - clientRect.top) / clientRect.height;
      const pointer = new THREE.Vector2();

      pointer.x = x * 2 - 1;
      pointer.y = -y * 2 + 1;

      rayCaster.setFromCamera(pointer, mainScene.camera);
      updateIndexedMap();

      const accurateIntersections = rayCaster.intersectObjects(mainScene.objectWrapper.children);
      const inaccurateIntersections =
        accurateIntersections.length > 1 && !hasSkinnedMeshes
          ? []
          : intersectSkinnedObjectByBBs(rayCaster, mainScene.objectWrapper) ||
            accurateIntersections;
      const intersects =
        inaccurateIntersections.length && hasSkinnedMeshes
          ? inaccurateIntersections
          : accurateIntersections;

      if (!intersects.length) {
        return;
      }

      let objectIndex = -1;
      const { object, point, face } = intersects[0];

      indexedMap.forEach((el, index): void => {
        if (el.id === object.id) {
          objectIndex = index;
        }
      });

      const localPosition = object.worldToLocal(new THREE.Vector3().copy(point));
      const path = getObjectFullPath(object);
      const newComment = handleNewComment({
        user,
        isSampleModel,
        objectIndex,
        face,
        path,
        localPosition,
        cameraPosition: mainScene.camera.position,
        cameraRotation: mainScene.camera.rotation,
        orbitControlsTarget: mainScene.orbitControls.target,
        scale: modelSettings.scale
      });

      setNewComment(newComment);
      dispatch(setActiveCommentId(newComment.id));
    },
    [
      dispatch,
      indexedMap,
      isSampleModel,
      modelSettings.scale,
      mainScene.objectWrapper,
      mainScene.camera,
      mainScene.orbitControls.target,
      rayCaster,
      mainScene.renderer.domElement,
      updateIndexedMap,
      user
    ]
  );

  const handleShortClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent> | MouseEvent): void => {
      if (
        !isEmbeddedModelMode &&
        isCommentsVisible &&
        event.target instanceof HTMLElement &&
        event.target.id === MAIN_SCENE_CANVAS_ID
      ) {
        switch (true) {
          case activeCommentId < 0:
            switch (true) {
              case isSampleModel:
                break;

              case hasCommentAccess && (isAuth):
                createComment(event);
                break;

              case (!isTeamModel && isModelOwner) || !isAuth:
                showPlansModalWindow();
                break;

              default:
                openNotification(ESnackbarStyle.HOLD_UP, UPGRADE_YOUR_TEAM_PLAN);
                break;
            }
            break;

          case activeCommentId >= 0:
            dispatch(setActiveCommentId(-1));
            break;

          default:
            break;
        }
      }
    },
    [
      isCommentsVisible,
      activeCommentId,
      dispatch,
      hasCommentAccess,
      isAuth,
      isSampleModel,
      createComment,
      isTeamModel,
      isModelOwner,
      showPlansModalWindow
    ]
  );

  useEffect((): void => {
    setEventsReceiver((event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
      shortClickHandlerService.handleShortClick(event, handleShortClick);
    });
  }, [handleShortClick, setEventsReceiver, shortClickHandlerService]);

  return (
    <>
      {!!newComment && (
        <Styled.CommentHolder key={getThreadElementIdByThreadId(newComment.id)}>
          <CommentCSS2DObjectWrapper
            key={getThreadElementIdByThreadId(newComment.id)}
            mainScene={mainScene}
            cameraController={cameraController}
            commentThread={newComment}
            isNewComment={!!newComment}
            indexedMap={indexedMap}
            getThreadElementIdByThreadId={getThreadElementIdByThreadId}
            updateIndexedMap={updateIndexedMap}
            createScreenshot={createScreenshot}
          />
        </Styled.CommentHolder>
      )}

      {comments.map(
        (commentThread): JSX.Element => (
          <Styled.CommentHolder key={getThreadElementIdByThreadId(commentThread.id)}>
            <CommentCSS2DObjectWrapper
              key={getThreadElementIdByThreadId(commentThread.id)}
              mainScene={mainScene}
              cameraController={cameraController}
              commentThread={commentThread}
              indexedMap={indexedMap}
              getThreadElementIdByThreadId={getThreadElementIdByThreadId}
              updateIndexedMap={updateIndexedMap}
              createScreenshot={createScreenshot}
            />
          </Styled.CommentHolder>
        )
      )}
    </>
  );
};
