import React from 'react';
import * as Styled from './styles';
import Close from 'assets/images/close.svg';
import { BellCrossedOutIcon, BellFilledIcon, BellRingingIcon } from 'assets/dynamic-icons';
import { useHandleClickOutside } from 'shared/hooks';
import { ENotificationPreferenceMode, NotificationPreferences } from 'shared/types';

type Props = {
  handleCloseClick: () => void;
  notificationPreferences: NotificationPreferences | null;
  updateNotificationPreferences: (mode: ENotificationPreferenceMode) => void;
  isThread?: boolean;
};

const NotificationSettingsBar: React.FC<Props> = ({
  handleCloseClick,
  notificationPreferences,
  updateNotificationPreferences,
  isThread
}): JSX.Element => {
  const ref = useHandleClickOutside(handleCloseClick);

  const currentMode = notificationPreferences?.notificationPreferences;

  const handleNotificationsModeClick =
    (mode: ENotificationPreferenceMode): (() => void) =>
    (): void => {
      if (currentMode !== mode) {
        updateNotificationPreferences(mode);
      }
    };

  return (
    <Styled.NotificationsSettings ref={ref}>
      <Styled.NotificationsSettingsHeader>
        <span>Send notifications when ...</span>
        <Styled.CloseButton type='button' onClick={handleCloseClick}>
          <img src={Close} alt='Close' />
        </Styled.CloseButton>
      </Styled.NotificationsSettingsHeader>
      <Styled.NotificationsSettingsField
        onClick={handleNotificationsModeClick(ENotificationPreferenceMode.ALL)}
        $isActive={currentMode === ENotificationPreferenceMode.ALL}
      >
        <div className='field-icon all-notifications'>
          <BellRingingIcon />
        </div>
        <span>{`Anyone comments${isThread ? ' in this thread' : ''}`}</span>
      </Styled.NotificationsSettingsField>
      <Styled.NotificationsSettingsField
        onClick={handleNotificationsModeClick(ENotificationPreferenceMode.MENTIONED)}
        $isActive={currentMode === ENotificationPreferenceMode.MENTIONED}
      >
        <div className='field-icon'>
          <BellFilledIcon />
        </div>
        <span>{`Someone tags me${isThread ? ' in this thread' : ''}`}</span>
      </Styled.NotificationsSettingsField>
      <Styled.NotificationsSettingsField
        onClick={handleNotificationsModeClick(ENotificationPreferenceMode.MUTED)}
        $isActive={currentMode === ENotificationPreferenceMode.MUTED}
      >
        <div className='field-icon'>
          <BellCrossedOutIcon />
        </div>
        <span>{`Never${isThread ? ' from this thread' : ''}`}</span>
      </Styled.NotificationsSettingsField>
    </Styled.NotificationsSettings>
  );
};

export default NotificationSettingsBar;
