import React, { useEffect, useRef, useState } from 'react';
import { EPremiumFeature, SelectOption } from 'shared/types';
import * as Styled from './styles';
import ArrowDownIcon from 'assets/images/arrow-down.svg';
import LockIcon from 'assets/images/lock-icon.svg';
import NoPhoto from 'assets/images/no-photo.png';
import { useAppDispatch, useHandleClickOutside } from 'shared/hooks';
import { convertStringToButtonId } from 'utils/form-utils';
import { RESTRICT_FEATURE } from 'shared/constants/notifications';
import { EEnvironmentPresets } from 'shared/enums/EEnvironmentPresets';
import { openAlert } from 'utils/alert-utils';
import { showModal } from '../../../../../services/store/reducers/modalReducer';
import ModalFeatureSignedIn from 'shared/components/modals/modal-feature-signed-in/ModalFeatureSignedIn';

type Option = SelectOption & {
  preview?: string;
  isAvailable: boolean;
};

type Props = {
  selectId: string;
  activeValue: any;
  action: (value: Option) => void;
  options: Option[];
  isModelOwner: boolean;
  isLighting?: boolean;
};

const SettingsSelect: React.FC<Props> = ({
  selectId,
  activeValue,
  action,
  options,
  isModelOwner,
  isLighting
}): React.ReactElement => {
  const dispatch = useAppDispatch();
  const [activeOption, setActiveOption] = useState<SelectOption>(options[0]);
  const [isMenuActive, setIsMenuActive] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect((): void => {
    const activeOption = options.find((option): boolean => option.value.key === activeValue.key);
    setActiveOption(activeOption || options[0]);
  }, [activeValue, options]);

  const handleOptionClick =
    (option: Option): (() => void) =>
    (): void => {
      if (option.isAvailable) {
        setIsMenuActive(false);
        if (option.value.key !== activeValue.key) {
          action(option);
        }
      } else {
        isModelOwner ? dispatch(showModal(<ModalFeatureSignedIn feature={EPremiumFeature.SCENE_SETTINGS} />)) : openAlert(RESTRICT_FEATURE);
      }
    };

  const toggleMenuActivity = (): void => {
    setIsMenuActive((state): boolean => !state);
  };

  const ref = useHandleClickOutside((): void => setIsMenuActive(false));

  return (
    <Styled.SelectContainer $isMenuActive={isMenuActive} ref={ref}>
      <Styled.MainField id={selectId} $isMenuActive={isMenuActive} onClick={toggleMenuActivity}>
        <div>{activeOption.title}</div>
        <img src={ArrowDownIcon} alt='Arrow' />
      </Styled.MainField>
      <Styled.SelectMenu $isMenuActive={isMenuActive} ref={dropdownRef}>
        {options.map(
          (option): JSX.Element => (
            <Styled.OptionItem
              onClick={handleOptionClick(option)}
              $isActive={option.value.key === activeValue.key}
              $isAvailable={option.isAvailable}
              id={convertStringToButtonId(option.title)}
              key={option.title}
            >
              <Styled.PreviewImageContainer
                $isNoEnvironment={option.value.key === EEnvironmentPresets.NoEnvironment}
              >
                {!!option.preview ? (
                  <Styled.PreviewImage
                    src={option.preview}
                    $isLighting={!!isLighting}
                    $isNoEnvironment={option.value.key === EEnvironmentPresets.NoEnvironment}
                    alt={option.title}
                  />
                ) : (
                  <Styled.NoPreview>
                    <img src={NoPhoto} alt='No preview' />
                  </Styled.NoPreview>
                )}
              </Styled.PreviewImageContainer>
              <Styled.OptionTitle $isAvailable={option.isAvailable}>
                {option.title}
              </Styled.OptionTitle>
              {!option.isAvailable && (
                <Styled.Lock>
                  <img src={LockIcon} alt='Lock' />
                </Styled.Lock>
              )}
            </Styled.OptionItem>
          )
        )}
      </Styled.SelectMenu>
    </Styled.SelectContainer>
  );
};

export default SettingsSelect;
