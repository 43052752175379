import styled from 'styled-components';
import { DEVICE } from 'shared/constants/deviceSizes';

export const ProjectLogoContainer = styled.div<{ $isSidebarOpened: boolean }>`
  display: none;

  @media${DEVICE.tabletLarge} {
    display: ${({ $isSidebarOpened }): string => ($isSidebarOpened ? 'flex' : 'none')};
    align-items: center;
    justify-content: center;
    padding: 20px 10px;
  }
`;

export const SidebarContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SidebarMenuBlock = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    &:after {
      content: '';
      margin: 0 auto;
      width: 85%;
      height: 1px;
      background-color: var(--text-color);
      opacity: 0.2;
    }
  }
`;

export const SidebarItems = styled.nav`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
`;

export const SidebarItemContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SidebarItemIcon = styled.div`
  width: 14px;
  height: 14px;
  margin-right: 13px;
  color: var(--text-color);
  filter: drop-shadow(1px 1px 1px rgb(0 0 0 / 0.7));
`;

export const SidebarItemTitle = styled.div<{ $isPageWithScene: boolean }>`
  margin-top: 2px;
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color);
  white-space: nowrap;
  display: ${({ $isPageWithScene }): string => ($isPageWithScene ? 'none' : 'block')};
  max-width: 140px;
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${DEVICE.tabletLarge} {
    display: block;
    max-width: 210px;
  }
`;

export const SidebarItem = styled.div`
  display: flex;
  align-items: center;
  padding-left: 22px;
  width: 100%;
  min-height: 45px;
  cursor: pointer;

  a {
    display: flex;
    align-items: center;
  }

  & > .active,
  &:hover {
    ${SidebarItemIcon} {
      color: var(--theme-secondary-color);
    }

    ${SidebarItemTitle} {
      color: var(--theme-secondary-color);
    }
  }
`;

export const Overlay = styled.div<{ $isSidebarOpened: boolean }>`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: rgba(0, 0, 0, 0.5);

  @media ${DEVICE.tabletLarge} {
    display: ${({ $isSidebarOpened }): string => ($isSidebarOpened ? 'flex' : 'none')};
    z-index: 1041;
  }
`;

export const SidebarContainer = styled.aside<{
  $isSidebarOpened: boolean;
  $isPageWithScene: boolean;
}>`
  width: ${({ $isPageWithScene }): string => ($isPageWithScene ? '60px' : '200px')};
  top: 0;
  left: 0;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  z-index: 1030;
  background: var(--sidebar-background-color);
  backdrop-filter: blur(5px);
  transition: all 0.3s;
  padding-top: 82px;
  border-right: 1px solid rgba(255, 255, 255, 0.17);

  &:hover {
    width: 200px;

    ${SidebarItemTitle} {
      display: block;
    }
  }

  @media ${DEVICE.tabletLarge} {
    width: 270px;
    z-index: 1042;
    transform: ${({ $isSidebarOpened }): string =>
      $isSidebarOpened ? 'translateX(0%)' : 'translateX(-100%)'};
    padding-top: 0;

    &:hover {
      width: 270px;
    }
  }
`;
