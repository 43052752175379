import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { FONTS } from 'shared/constants/fonts';
import Comment from 'assets/images/comment.png';
import { NavLink } from 'react-router-dom';
import { DEVICE } from 'shared/constants/deviceSizes';

export const ModelCardContainer = styled(NavLink)`
  display: block;
  width: 100%;
  min-width: 0;
  max-width: 100%;
  margin: 0;
  container-name: model-card;
  container-type: inline-size;
  text-decoration: none;
  background: ${COLORS.mineShaft};
  border-radius: 8px;
  border: 1px solid transparent;
  transition: border-color 0.2s;
  cursor: pointer;
  position: relative;

  &:hover {
    border-color: ${COLORS.mischka};
  }
`;

export const LabelStatus = styled.div<{ $isPublic?: boolean }>`
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  padding: 0 14px;
  background: ${({ $isPublic }): string => ($isPublic ? COLORS.turbo : COLORS.mineShaft)};
  font-family: ${FONTS.interRegular};
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  color: ${({ $isPublic }): string => ($isPublic ? COLORS.black : COLORS.white)};
  border-radius: 6px;

  &.my-models-label {
    top: 56px;
    background: ${COLORS.turbo};
    color: ${COLORS.black};
  }
`;

export const NewTabIcon = styled.img`
  margin-left: 10px;
  width: 25px;
  height: 25px;
`;

export const NoteCounter = styled.div`
  position: absolute;
  top: 5px;
  left: 5px;
  height: 36px;
  max-height: 36px;
  min-width: 36px;
  padding: 0 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(${Comment});
  background-color: ${COLORS.turbo};
  background-repeat: no-repeat;
  background-size: 28px 25px;
  background-position: center 8px;
  border-radius: 5px;

  span {
    font-weight: 600;
    font-size: 12px;
    line-height: 13px;
    color: ${COLORS.black};
  }
`;

export const CardTop = styled.div`
  position: relative;
  height: 250px;
  width: 100%;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
`;

export const ModelImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const DefaultImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: scale-down;
`;

export const ModelDescriptionContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  font-family: ${FONTS.interRegular};
  color: ${COLORS.mischka};
  position: relative;
`;

export const ModelTitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

export const ModelWorkspace = styled.h6`
  font-size: 20px;
  font-weight: 400;
  line-height: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${COLORS.doveGray};
  margin: 10px 0 0;
`;

export const CardActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  position: relative;

  @media ${DEVICE.mobile} {
    gap: 10px;
    flex-direction: column;
    align-items: stretch;
  }
`;

export const CardButtons = styled.div`
  display: flex;
  gap: 20px;
  padding-right: 20px;
  flex: 1;
  position: relative;
  
  @media ${DEVICE.mobile} {
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
`;

export const ActionButton = styled.button`
  background: ${COLORS.corn};
  width: 43px;
  height: 43px;
  min-width: 43px;
  flex-shrink: 0;
  min-width: 43px;
  flex-shrink: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s;

  img {
    width: 45%;
    height: 45%;
  }

  &:hover {
    background: ${COLORS.turbo};
  }
`;

export const ShareButtonContainer = styled.div`
  position: relative;
`;

export const QrCode = styled.div`
  width: 175px;
  height: 175px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: ${COLORS.white};
  margin: 0 auto 5px auto;

  .qr-code {
    width: 100%;
    height: 100%;
  }
`;

export const QrCodeDescription = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  font-family: ${FONTS.interRegular};
  color: ${COLORS.white};
`;

export const Spinner = styled.img`
  width: 60px;
  height: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const ShareQrCodeContainer = styled.div<{ $isLoading: boolean }>`
  width: 113px;
  height: 113px;
  position: absolute;
  bottom: 0;
  right: 0;
  background: ${({ $isLoading }): string => ($isLoading ? COLORS.mineShaft : COLORS.white)};

  .qr-code {
    width: 100%;
    height: 100%;
  }
`;

export const StatsButton = styled.button<{ $isStatsActive: boolean }>`
  position: relative;
  height: ${({ $isStatsActive }) => ($isStatsActive ? '54px' : '43px')};
  min-width: 180px;
  border-radius: ${({ $isStatsActive }) => ($isStatsActive ? '7px 7px 0 0' : '7px')};
  background-color: ${COLORS.codGray};
  transition: ${({ $isStatsActive }) =>
    $isStatsActive
      ? 'height 0.3s'
      : 'border-radius 0.3s 0.3s, height 0.3s 0.3s'};

  @media ${DEVICE.mobile} {
    height: ${({ $isStatsActive }) => ($isStatsActive ? '30px' : '40px')};
    width: 100%;
    transition: ${({ $isStatsActive }) =>
      $isStatsActive
        ? 'none'
        : 'border-radius 0.3s 0.3s, height 0.05s 0.25s'};
  }

  span, img {
    position: absolute;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    color: ${COLORS.doveGray};
  }

  span {
    top: 12px;
    left: 15px;
    white-space: nowrap;
  }

  img {
    top: 18px;
    right: 15px;
    width: 16px;
    height: 6px;
    transform: ${({ $isStatsActive }) => ($isStatsActive ? 'rotate(0deg)' : 'rotate(-180deg)')};
  }
`;

export const ModelNameContainer = styled.h3`
  margin: 0;
  font-size: 32px;
  font-weight: 600;
  line-height: 1;
  color: ${COLORS.white};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  padding: 5px 10px;
  margin-top: -5px;
  margin-left: -10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${COLORS.black};
  }
`;