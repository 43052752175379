import React, { useCallback, useEffect } from 'react';
import * as Styled from './styles';
import { ContentHeader, PageContainer, PageTitle } from 'shared/styles';
import { SearchSelect, ModelList } from 'shared/components';
import { useRecentViewedData } from 'shared/hooks';

const RecentsPage = (): JSX.Element => {
  const {
    fetchRecentViewedModels,
    modelsData,
    setSearch,
    fetchModel,
    isSearchMode,
    setIsSearchMode
  } = useRecentViewedData();

  useEffect((): void => {
    fetchRecentViewedModels();
  }, [fetchRecentViewedModels]);

  const handleSearchInputChange = useCallback(
    (value: string): void => {
      setSearch(value);
    },
    [setSearch]
  );

  const handleSearchListItemClick = async (modelId: string): Promise<void> => {
    await fetchModel(modelId);
  };

  return (
    <PageContainer>
      <Styled.PageContent>
        <ContentHeader>
          <PageTitle>Recents</PageTitle>
          <Styled.SearchSelectContainer>
            <SearchSelect
              inputAction={handleSearchInputChange}
              listAction={handleSearchListItemClick}
              options={isSearchMode ? modelsData.slice(0, 5) : []}
              setIsSearchMode={setIsSearchMode}
            />
          </Styled.SearchSelectContainer>
        </ContentHeader>
        <ModelList
          models={modelsData}
          fetchModelsList={fetchRecentViewedModels}
          showMyModelsLabel={true}
        />
      </Styled.PageContent>
    </PageContainer>
  );
};

export default RecentsPage;
