import styled from 'styled-components';
import { DEVICE } from 'shared/constants/deviceSizes';
import { COLORS } from 'shared/constants/colors';

export const NoDataBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: var(--text-primary);
  font-size: 16px;
  color: ${COLORS.white};
`;

export const ModelsListContainer = styled.section`
  --gap: 20px;
  --min-card-width: 445px;
  --max-columns: 6;

  .model-cards-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(var(--min-card-width), 1fr));
    gap: var(--gap);
    width: 100%;
    margin: 0 auto;

    @media ${DEVICE.mobile} {
      grid-template-columns: 1fr;
    }
  }
`; 