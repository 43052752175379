import { LoadingManager } from 'three';
import { allowed3dModelAssetsTypes } from 'shared/constants/allowed-3d-model-assets-types';
import JSZip, { JSZipObject } from 'jszip';
import { FileWithPath } from 'react-dropzone';
import { E3DModelFileTypes } from 'shared/enums/E3DModelFileTypes';
import {
  BASIC_FILE_FORMATS_REGEXP,
  LIKE_BASE64_REGEXP,
  MODEL_ASSETS_FILE_FORMATS,
  MTL_FORMAT_REGEXP
} from 'shared/constants/regexps';
import { ResponseModel } from 'shared/types';
import { GltfValidationController } from './gltf-utils';
import { checkIsIFrame } from './helper-utils';

interface ICreateLoadingManagerResult {
  setLoadingContent: (content: ResponseModel[]) => void;
  loadingManager: LoadingManager;
}

export const getFileNameData = (fileName: string): string[] => fileName.toLowerCase().split('.');

export const changeSpacesToUnderscores = (name: string): string =>
  name.replaceAll(' ', '_').replaceAll('%20', '_');

export const getBlobUrlForFile = async (file: JSZipObject): Promise<string> => {
  const blob = await file.async('blob');
  return URL.createObjectURL(blob);
};

export const createLoadingManager = (): ICreateLoadingManagerResult => {
  let loadingContent: ResponseModel[] = [];
  const loadingManager = new LoadingManager();

  const setLoadingContent = (content: ResponseModel[]): void => {
    loadingContent = content;
  };

  const getPathFromUrl = (url: string, isLocal: boolean = false): string => {
    const parsedUrl = new URL(url);
    return parsedUrl.pathname
      .split('/')
      .slice(isLocal ? 3 : 5)
      .join('/');
  };

  const getRelativeURLs = (relativeURL: string, URLs: Record<string, string>): string[] => {
    const paths = Object.keys(URLs);
    const filesWithSameName = paths.filter(
      (path): boolean => path.split('/').pop() === relativeURL.split('/').pop()
    );

    if (filesWithSameName.length === 1) {
      return [URLs[filesWithSameName[0]]];
    }

    const splitURL = relativeURL.split('/');
    const similarURLs: string[] = [];

    for (let i = 0; i < splitURL.length; i++) {
      const mainPath = splitURL.slice(i).join('/');
      const foundClearURLs = paths.filter(
        (path): boolean => path.includes(mainPath) && !similarURLs.includes(path)
      );
      const fountTransformedURLs = paths.filter(
        (path): boolean =>
          changeSpacesToUnderscores(path).includes(changeSpacesToUnderscores(mainPath)) &&
          !similarURLs.includes(path)
      );

      similarURLs.push(...foundClearURLs, ...fountTransformedURLs);
    }

    return similarURLs.map((url): string => URLs[url]);
  };

  const localFileURLModifier = (url: string, lsBlobUrls: string, lsFileUrls: string): string => {
    if (LIKE_BASE64_REGEXP.test(url)) return url;

    const relativePath = getPathFromUrl(url, true);
    const fileExtension = relativePath.split('.').pop()!;

    if (allowed3dModelAssetsTypes.allModels.includes(fileExtension)) {
      return url;
    }

    const blobUrls: { [key: string]: string } = lsBlobUrls ? JSON.parse(lsBlobUrls) : undefined;
    const fileUrls: { [key: string]: string } = lsFileUrls ? JSON.parse(lsFileUrls) : undefined;
    const urls = {
      ...blobUrls,
      ...fileUrls
    };
    const relativeURLs = getRelativeURLs(relativePath, urls);

    return relativeURLs[0] || url;
  };

  const networkFileURLModifier = (url: string, loadingContent: ResponseModel[]): string => {
    const relativePath = getPathFromUrl(url);
    const fileExtension = url.split('.').pop() || relativePath.split('.').pop()!;
    const isModelFile = allowed3dModelAssetsTypes.allModels.includes(fileExtension);
    const isAssetFile = allowed3dModelAssetsTypes.allResources.includes(fileExtension);

    if (isModelFile || !isAssetFile) {
      return url;
    }

    const URLs = loadingContent.reduce((acc, item): Record<string, string> => {
      const relativePath = getPathFromUrl(item.publicUrl);

      acc[relativePath] = item.publicUrl;

      return acc;
    }, {} as Record<string, string>);
    const relativeURLs = getRelativeURLs(relativePath, URLs);
    return relativeURLs[0] || url;
  };

  loadingManager.setURLModifier((url): string => {
    const lsBlobUrls = checkIsIFrame() ? '' : localStorage.getItem('blobUrls') || '';
    const lsFileUrls = checkIsIFrame() ? '' : localStorage.getItem('fileUrls') || '';
    const isRemoteUrl = url.startsWith('http');

    switch (true) {
      case (!!lsBlobUrls || !!lsFileUrls) && !isRemoteUrl:
        return localFileURLModifier(url, lsBlobUrls, lsFileUrls);

      case !!loadingContent.length:
        return networkFileURLModifier(url, loadingContent);

      default:
        return url;
    }
  });

  return {
    setLoadingContent,
    loadingManager
  };
};

export const getJSZipObjectsFromNestedZip = async (
  jsZipObjects: JSZipObject[]
): Promise<JSZipObject[]> => {
  return new Promise(async (resolve, reject): Promise<void> => {
    try {
      let resultZipObjects: JSZipObject[] = [];

      for (const jsZipObject of jsZipObjects) {
        if (getFileNameData(jsZipObject.name).pop() === 'zip') {
          const zipBlob = await jsZipObject.async('blob');
          const result = await JSZip.loadAsync(zipBlob);
          const files = Object.values(result.files).filter((item): boolean => !item.dir);

          resultZipObjects.push(...files);
        } else {
          resultZipObjects.push(jsZipObject);
        }
      }

      const hasNestedZip = !!resultZipObjects.find(
        (file): boolean => getFileNameData(file.name).pop() === 'zip'
      );

      if (hasNestedZip) {
        resultZipObjects = await getJSZipObjectsFromNestedZip(resultZipObjects);
      }

      resolve(resultZipObjects);
    } catch (err) {
      reject(err);
    }
  });
};

export const extractZipObjectsFromZip = async (
  files: FileWithPath[]
): Promise<{
  jsZipObjects: JSZipObject[];
  nonZipFiles: File[];
}> => {
  const jsZipObjects: JSZipObject[] = [];
  const nonZipFiles: File[] = [];

  for (const file of files) {
    const format = getFileNameData(file.name).pop();
    if (format === 'zip') {
      const result = await JSZip.loadAsync(file);
      const zipObjects = Object.values(result.files).filter((item): boolean => !item.dir);
      const nestedZipObjects = await getJSZipObjectsFromNestedZip(zipObjects);
      jsZipObjects.push(...nestedZipObjects);
    } else {
      const fileName = file.path || file.name;
      nonZipFiles.push(new File([file], fileName[0] === '/' ? fileName.slice(1) : fileName));
    }
  }
  return { jsZipObjects, nonZipFiles };
};

export const getFilesFromJSZipObjects = async (zipObjects: JSZipObject[]): Promise<File[]> => {
  const unzippedFiles: File[] = [];

  for (const zipObject of zipObjects) {
    const zipBlob = await zipObject.async('blob');
    unzippedFiles.push(new File([zipBlob], zipObject.name));
  }

  return unzippedFiles;
};

export const createZipFromFiles = async (files: FileWithPath[], zipName: string): Promise<File> => {
  const zip = new JSZip();

  files.forEach((file): void => {
    zip.file(file.path || file.name, file);
  });

  const content = await zip.generateAsync({ type: 'blob' });
  

  return new File([content], zipName + '.zip');
};

const getFileExtensionInfos = (files: File[]): Record<string, number> =>
  files.reduce((acc: Record<string, number>, file): Record<string, number> => {
    const extension = file.name.split('.').pop()?.toLowerCase() || '';
    return { ...acc, [extension]: !!acc[extension] ? acc[extension]++ : 1 };
  }, {});

export const getFileType = (files: File[]): E3DModelFileTypes | undefined => {
  const fileExtensions: Record<string, number> = getFileExtensionInfos(files);
  const extensions: string[] = Object.keys(fileExtensions);

  switch (true) {
    case !extensions.length:
      return;

    case extensions.some((ext): boolean => !allowed3dModelAssetsTypes.allResources.includes(ext)):
      return;

    case fileExtensions[allowed3dModelAssetsTypes.glb] === 1 &&
      extensions.every((ext): boolean => allowed3dModelAssetsTypes.glb.includes(ext)):
      return E3DModelFileTypes.glb;

    case fileExtensions[allowed3dModelAssetsTypes._gltf] &&
      extensions.every((ext): boolean => allowed3dModelAssetsTypes.gltf.includes(ext)):
      return E3DModelFileTypes.gltf;

    case fileExtensions[allowed3dModelAssetsTypes._fbx] &&
      extensions.every((ext): boolean => allowed3dModelAssetsTypes.fbx.includes(ext)):
      return E3DModelFileTypes.fbx;

    case fileExtensions[allowed3dModelAssetsTypes._obj] &&
      extensions.every((ext): boolean => allowed3dModelAssetsTypes.obj.includes(ext)):
      return E3DModelFileTypes.obj;

    case fileExtensions[allowed3dModelAssetsTypes._stl] &&
      extensions.every((ext): boolean => allowed3dModelAssetsTypes.stl.includes(ext)):
      return E3DModelFileTypes.stl;

    case process.env.NODE_ENV === 'development' &&
      fileExtensions[allowed3dModelAssetsTypes.usdz] === 1 &&
      extensions.every((ext): boolean => allowed3dModelAssetsTypes.usdz.includes(ext)):
      return E3DModelFileTypes.usdz; // fixme: delete first rule part

    case process.env.NODE_ENV === 'development' &&
      fileExtensions[allowed3dModelAssetsTypes._usd] &&
      extensions.every((ext): boolean => allowed3dModelAssetsTypes.usd.includes(ext)):
      return E3DModelFileTypes.usd; // fixme: delete first rule part

    case process.env.NODE_ENV === 'development' &&
      fileExtensions[allowed3dModelAssetsTypes._usda] &&
      extensions.every((ext): boolean => allowed3dModelAssetsTypes.usda.includes(ext)):
      return E3DModelFileTypes.usda; // fixme: delete first rule part

    case process.env.NODE_ENV === 'development' &&
      fileExtensions[allowed3dModelAssetsTypes._usdc] &&
      extensions.every((ext): boolean => allowed3dModelAssetsTypes.usdc.includes(ext)):
      return E3DModelFileTypes.usdc; // fixme: delete first rule part

    default:
      return;
  }
};

export const getMtlUrlsFromUrls = (urls: Record<string, string>): string[] =>
  Object.keys(urls).reduce(
    (acc: string[], item): string[] => (MTL_FORMAT_REGEXP.test(item) ? [...acc, urls[item]] : acc),
    []
  );

export const getFileUrlsForNonZipFiles = async (
  nonZipFiles: FileWithPath[]
): Promise<Record<string, string>> => {
  let fileUrls: { [key: string]: string } = {};
  for (const file of nonZipFiles) {
    fileUrls = { ...fileUrls, [file.name]: URL.createObjectURL(file) };
  }

  localStorage.setItem('fileUrls', JSON.stringify(fileUrls));

  return nonZipFiles.reduce(
    (acc: Record<string, string>, { name }): Record<string, string> =>
      BASIC_FILE_FORMATS_REGEXP.test(name.toLowerCase()) ? { ...acc, [name]: fileUrls[name] } : acc,
    {}
  );
};

export const getModelAssetsFileUrlsForNonZipFiles = async (
  nonZipFiles: FileWithPath[]
): Promise<Record<string, string>> => {
  let fileUrls: { [key: string]: string } = {};
  for (const file of nonZipFiles) {
    fileUrls = { ...fileUrls, [file.name]: URL.createObjectURL(file) };
  }

  return nonZipFiles.reduce(
    (acc: Record<string, string>, { name }): Record<string, string> =>
      MODEL_ASSETS_FILE_FORMATS.test(name) ? { ...acc, [name]: fileUrls[name] } : acc,
    {}
  );
};

export const getBlobUrlsForZipFiles = async (
  jsZipObjects: JSZipObject[]
): Promise<Record<string, string>> => {
  let blobUrls: { [key: string]: string } = {};
  for (const file of jsZipObjects) {
    blobUrls = { ...blobUrls, [file.name]: await getBlobUrlForFile(file) };
  }

  localStorage.setItem('blobUrls', JSON.stringify(blobUrls));

  return jsZipObjects.reduce(
    (acc: Record<string, string>, { name }): Record<string, string> =>
      BASIC_FILE_FORMATS_REGEXP.test(name.toLowerCase()) ? { ...acc, [name]: blobUrls[name] } : acc,
    {}
  );
};

export const handleInputFiles = async (
  inputFiles: File[]
): Promise<{ jsZipObjects: JSZipObject[]; unzippedFiles: File[]; nonZipFiles: File[] }> => {
  const { jsZipObjects, nonZipFiles } = await extractZipObjectsFromZip(inputFiles);
  const unzippedFiles = await getFilesFromJSZipObjects(jsZipObjects);
  return { jsZipObjects, unzippedFiles, nonZipFiles };
};

export const getFileUrls = async (
  nonZipFiles: FileWithPath[],
  jsZipObjects: JSZipObject[]
): Promise<Record<string, string>> => {
  const unzippedFileUrls = await getBlobUrlsForZipFiles(jsZipObjects);
  const nonZipFileUrls = await getFileUrlsForNonZipFiles(nonZipFiles);
  return { ...unzippedFileUrls, ...nonZipFileUrls };
};

export const checkGltfFileSecurity = async (files: FileWithPath[]): Promise<boolean> => {
  const fileMap = new Map();
  const validator = new GltfValidationController();
  files.forEach((file): void => {
    fileMap.set(file.webkitRelativePath || file.name, file);
  });
  return await validator.fullValidate(fileMap);
};

export const getTotalFilesSize = (files: File[]): number => {
  return files.reduce((acc, file): number => acc + file.size, 0);
};
