import { COLORS } from 'shared/constants/colors';
import { FONTS } from 'shared/constants/fonts';
import styled from 'styled-components';

export const FormField = styled.div`
  height: 60px;
  width: 100%;
  position: relative;

  img {
    position: absolute;
    right: 10px;
    top: 8px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`;

export const FieldInput = styled.input<{ $isPassword?: boolean; $isEmptyValue?: boolean }>`
  background: ${COLORS.black};
  border: 1px solid ${COLORS.white};
  border-radius: 4px;
  width: 100%;
  height: 36px;
  font-size: 14px;
  line-height: 16px;
  font-family: ${FONTS.interRegular};
  color: ${COLORS.white};
  padding: ${({ $isPassword, $isEmptyValue }): string =>
    $isPassword && !$isEmptyValue ? '11px 32px 11px 11px' : '11px'};
`;
