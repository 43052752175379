import React, { useCallback, useEffect, useMemo } from 'react';
import * as Styled from './styles';
import { PageContainer, PageTitle } from 'shared/styles';
import { ModalCreateTeamCheckout, TeamSubscriptions } from 'shared/components';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import {
  EConfirmModalHeader,
  EPaymentType,
  ESnackbarStyle,
  SubscriptionsState
} from 'shared/types';
import { useHistory, useLocation } from 'react-router-dom';
import { openNotification } from 'utils/notification-utils';
import { showConfirmModal } from 'services/store/reducers/modalReducer';
import { clearTeamDataLocalStorage, getTeamDataFromLocalStorage } from 'utils/storage-utils';
import { setMembersCount, setTeamName } from 'services/store/reducers/createTeamReducer';

const CreateTeamPage = (): JSX.Element => {
  const location = useLocation();
  const params = useMemo(
    (): URLSearchParams => new URLSearchParams(location.search),
    [location.search]
  );
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { subscriptionPlans } = useAppSelector((store): SubscriptionsState => store.subscriptions);

  const clearParams = useCallback(
      (paramItems: string[]): void => {
        paramItems.forEach((item): void => params.delete(item));
        history.replace({ search: params.toString() });
      },
      [history, params]
  );

  useEffect((): void => {
    const paymentRedirectStatus = params.get('redirect_status');
    const setupIntentClientSecret = params.get('setup_intent_client_secret');
    if (!!paymentRedirectStatus) {
      clearParams(['redirect_status', 'setup_intent', 'setup_intent_client_secret']);
    }
    if (paymentRedirectStatus === 'failed') {
      openNotification(ESnackbarStyle.ERROR, 'Payment failed');
      return;
    }
    if (paymentRedirectStatus === 'succeeded' && !!setupIntentClientSecret) {
      const { teamName, planId, isBusiness, isMonth, membersCount } = getTeamDataFromLocalStorage();
      const plan = subscriptionPlans.find((plan): boolean => plan.id === planId);
      dispatch(setTeamName(teamName));
      dispatch(setMembersCount(membersCount));
      dispatch(
        showConfirmModal({
          header: EConfirmModalHeader.CHECKOUT,
          content: (
            <ModalCreateTeamCheckout
              isMonthlyType={isMonth}
              plan={plan!}
              setupIntentClientSecret={setupIntentClientSecret}
              isBusinessPayment={isBusiness}
            />
          )
        })
      );
      clearTeamDataLocalStorage();
      openNotification(ESnackbarStyle.SUCCESS, 'Your card has been successfully saved');
    }
  }, [clearParams, dispatch, params, subscriptionPlans]);

  return (
    <PageContainer>
      <Styled.PageContent>
        <Styled.PageTitle>Create a team</Styled.PageTitle>
        <Styled.PageSubtitle>
          In team spaces, models and their usage limits are shared across all users in a single
          space.
        </Styled.PageSubtitle>
        <TeamSubscriptions 
          withCounter={true}
          paymentType={EPaymentType.CREATE_TEAM_PLAN}
        />
      </Styled.PageContent>
    </PageContainer>
  );
};

export default CreateTeamPage;
