import { useCallback, useEffect, useMemo } from 'react';
import {
  setIsViewerLoading,
  setLoadingState,
  setTotalLoadingSteps
} from 'services/store/reducers/loaderReducer';
import {
  clearViewerDataState,
  setModel,
  setModelMtls,
  setModelFileType,
  uploadModelScreenshot,
} from 'services/store/reducers/viewerDataReducer';
import {
  AuthState,
  ELoadingState,
  EQuotaName,
  ESnackbarStyle,
  ETeamRole,
  EModelType,
  Model,
  ModelWithMetadata
} from 'shared/types';
import {
  checkGltfFileSecurity,
  createZipFromFiles,
  getFileType,
  getFileUrls,
  getModelAssetsFileUrlsForNonZipFiles,
  getMtlUrlsFromUrls,
  getTotalFilesSize,
  handleInputFiles
} from 'utils/file-processing-utils';
import { openNotification } from 'utils/notification-utils';
import { INCORRECT_FILE_FORMAT, MODEL_UPLOADED } from 'shared/constants/notifications';
import {
  DRAG_DROP_FILE_FORMAT_REGEXP,
  GLB_GLTF_MODEL_TYPE_REGEXP,
  MAIN_FILE_FORMAT_REGEXP,
  MTL_FORMAT_REGEXP
} from 'shared/constants/regexps';
import { useAppDispatch, useAppSelector, useBrowserStore } from 'shared/hooks';
import { useHistory } from 'react-router-dom';
import { FileWithPath } from 'react-dropzone';
import { BASIC_MAX_MODEL_SIZE } from 'shared/constants/limits';
import { getUserPlanLimit } from 'services/api/subscriptionsService';
import { PROMPTS } from 'shared/constants/prompts';
import { closeModal, showModal } from 'services/store/reducers/modalReducer';
import { ModalLimit } from 'shared/components';
import { ModelParserService } from 'services/strategy-services';
import { E3DModelFileTypes } from 'shared/enums/E3DModelFileTypes';
import { convertBytesToMegabytes, createNewModelObject, getChangedModel } from 'utils/model-utils';
import {
  clearModelLocalStorage,
  getModelDataLocalStorage,
  setModelDataLocalStorage
} from 'utils/storage-utils';
import { INITIAL_MODEL_SETTINGS } from 'shared/constants/model-settings';
import { getTeamMember, uploadTeamModel } from 'services/api/teamService';
import { convertFileToFormData } from 'utils/convert-file-utils';
import { updateModel, uploadModel } from 'services/api/modelService';
import { openMessage } from 'utils/message-utils';
import { API_REQUEST_CANCELED_CODE } from 'shared/constants/errors';

type SpaceOption = {
  spaceTitle: string;
  spaceId: number;
};

type Result = {
  uploadModelToViewer: (
    files: FileWithPath[],
    modelType: EModelType.QUICK_VIEW | EModelType.ANONYMOUS
  ) => Promise<void>;
  uploadModelToSpace: (files: FileWithPath[], team?: SpaceOption) => Promise<void>;
  uploadModelToMultipleSpaces: (files: FileWithPath[], spaces: SpaceOption[]) => Promise<void>;
  uploadDroppedModelsToSpace: (files: FileWithPath[], team?: SpaceOption) => Promise<void>;
};

const useUploadFile = (): Result => {
  const controller = useMemo((): AbortController => new AbortController(), []);
  const signal: AbortSignal = controller.signal;
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { isAuth } = useAppSelector((store): AuthState => store.auth);
  const { setFilesToBrowserStore, removeFilesFromBrowserStore } = useBrowserStore();

  useEffect(
    (): (() => void) => (): void => {
      controller.abort();
    },
    [controller]
  );

  const getTotalModelSize = (files: FileWithPath[]): number =>
    files.reduce((total, { size }): number => total + size, 0);

  const getTeamLimitNotificationText = (
    quota: EQuotaName.ACTIVE_MODELS_LIMIT | EQuotaName.MODEL_SIZE_LIMIT,
    teamName: string
  ): string => {
    const teamNotifications = {
      [EQuotaName.ACTIVE_MODELS_LIMIT]: `You’ve reached the model limit for ${teamName} workspace`,
      [EQuotaName.MODEL_SIZE_LIMIT]: `Model size exceeds the allowed limit for ${teamName} workspace`
    };
    return teamNotifications[quota];
  };

  const showLimitPrompt = useCallback(
    (
      quota: EQuotaName.ACTIVE_MODELS_LIMIT | EQuotaName.MODEL_SIZE_LIMIT,
      team?: SpaceOption
    ): void => {
      !!team
        ? openNotification(
            ESnackbarStyle.HOLD_UP,
            getTeamLimitNotificationText(quota, team.spaceTitle)
          )
        : dispatch(
            showModal(
              <ModalLimit title={PROMPTS[quota].title} subtitle={PROMPTS[quota].subtitle} />
            )
          );
    },
    [dispatch]
  );

  const checkModelSizeLimit = useCallback(
    async (fileSize: number, team?: SpaceOption): Promise<boolean> => {
      if (!isAuth) {
        if (fileSize > BASIC_MAX_MODEL_SIZE) {
          const limit = convertBytesToMegabytes(BASIC_MAX_MODEL_SIZE);
          openNotification(
            ESnackbarStyle.HOLD_UP,
            `Model size exceeds the allowed limit. ${limit}MB maximum file size.`
          );
        }

        return fileSize <= BASIC_MAX_MODEL_SIZE;
      }
      const { limit } = (await getUserPlanLimit(EQuotaName.MODEL_SIZE_LIMIT, 'me', team?.spaceId))
        .data;
      const isSuperAdmin = limit === -1;
      const isSuccess = isSuperAdmin || limit >= fileSize;
      if (!isSuccess) showLimitPrompt(EQuotaName.MODEL_SIZE_LIMIT, team);
      return isSuccess;
    },
    [isAuth, showLimitPrompt]
  );

  const checkActiveModelsLimit = useCallback(
    async (team?: SpaceOption): Promise<boolean> => {
      if (!isAuth) return true;
      const { success, limit } = (
        await getUserPlanLimit(EQuotaName.ACTIVE_MODELS_LIMIT, 'me', team?.spaceId)
      ).data;
      const isSuperAdmin = limit === -1;
      const isSuccess = isSuperAdmin || success;
      if (!isSuccess) showLimitPrompt(EQuotaName.ACTIVE_MODELS_LIMIT, team);
      return isSuccess;
    },
    [isAuth, showLimitPrompt]
  );

  const validateModelForLimits = useCallback(
    async (files: FileWithPath[], team?: SpaceOption): Promise<boolean> => {
      const totalModelSize = getTotalModelSize(files);
      const isModelSizeValid = await checkModelSizeLimit(totalModelSize, team);
      const isModelsLimitValid = await checkActiveModelsLimit(team);
      return isModelSizeValid && isModelsLimitValid;
    },
    [checkActiveModelsLimit, checkModelSizeLimit]
  );

  const checkSecure = async (targetFiles: File[]): Promise<boolean> => {
    const modelFileType = getFileType(targetFiles);
    return (
      !!modelFileType &&
      (!GLB_GLTF_MODEL_TYPE_REGEXP.test(modelFileType) ||
        (await checkGltfFileSecurity(targetFiles)))
    );
  };

  const uploadModelToViewer = useCallback(
    async (
      files: FileWithPath[],
      modelType: EModelType.QUICK_VIEW | EModelType.ANONYMOUS
    ): Promise<void> => {
      dispatch(setTotalLoadingSteps(7));
      dispatch(setIsViewerLoading(true));
      history.push('/');
      try {
        dispatch(setLoadingState(ELoadingState.LIMIT_VALIDATION));
        if (modelType === EModelType.ANONYMOUS) {
          const isLimitsValid = await validateModelForLimits(files);
          if (!isLimitsValid) {
            dispatch(setIsViewerLoading(false));
            return;
          }
        }
        dispatch(setLoadingState(ELoadingState.EXTRACTING_FILES));
        const { jsZipObjects, unzippedFiles, nonZipFiles } = await handleInputFiles(files);
        const targetFiles: File[] = [...unzippedFiles, ...nonZipFiles];
        const modelFileType = getFileType(targetFiles);
        dispatch(setLoadingState(ELoadingState.SECURITY_CHECKING));
        const isModelSecure = await checkSecure(targetFiles);
        if (!modelFileType || !isModelSecure) throw new Error(INCORRECT_FILE_FORMAT);

        dispatch(setLoadingState(ELoadingState.MODEL_PARSING));
        const urls = await getFileUrls(nonZipFiles, jsZipObjects);
        const fileNames = Object.keys(urls);
        const mainFileNamesWithPath = fileNames.filter(
          (item): boolean => !MTL_FORMAT_REGEXP.test(item)
        );
        const assetsUrls = await getModelAssetsFileUrlsForNonZipFiles(nonZipFiles);
        const modelParser = new ModelParserService();
        await modelParser.parse(modelFileType, urls, assetsUrls);
        const modelMetadata = modelParser.modelStats();



        if (mainFileNamesWithPath.length > 1) {
          openNotification(
            ESnackbarStyle.HOLD_UP,
            'Selection contain multiple model files. The first is opening.'
          );
        }
        const mtls = modelFileType === E3DModelFileTypes.obj ? getMtlUrlsFromUrls(urls) : [];
        const mainFileName = mainFileNamesWithPath[0].split('/').pop()!;
        const [modelName] = mainFileName.split('.');
        const mainFileSize = targetFiles.find((file): boolean =>
          file.name.endsWith(mainFileName)
        )!.size;

        const model: ModelWithMetadata = createNewModelObject(
          modelName,
          Math.max(getTotalFilesSize(targetFiles), modelMetadata?.fileSize.totalSize, mainFileSize),
          urls[mainFileNamesWithPath[0]],
          modelType
        );
        if (modelMetadata) {
          model.metadata = { ...model.metadata, ...modelMetadata };
        }
        setModelDataLocalStorage({ modelName, modelSettings: INITIAL_MODEL_SETTINGS, modelType });
        setFilesToBrowserStore(targetFiles);
        dispatch(setModelFileType(modelFileType));
        dispatch(setModelMtls(mtls));
        await dispatch(setModel({ model }));
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
      }
    },
    [dispatch, history, setFilesToBrowserStore, validateModelForLimits]
  );

  const saveModelFile = useCallback(
    async (zippedFile: File, fileHint: string, teamId?: number): Promise<Model> => {
      const modelFormData: FormData = convertFileToFormData(zippedFile, fileHint);
      const uploadedModel: ModelWithMetadata = teamId
        ? (await uploadTeamModel(teamId, modelFormData)).data
        : (await uploadModel(modelFormData, signal)).data;
      window.dataLayer.push({ event: 'modelUploaded' });
      const { lsModelSettings, lsModelName } = getModelDataLocalStorage();
      if (!!lsModelSettings && !!lsModelName) {
        const changedModel = getChangedModel(lsModelName, lsModelSettings, null);
        await updateModel(changedModel, uploadedModel.id);
      }
      openNotification(ESnackbarStyle.SUCCESS, MODEL_UPLOADED);
      return uploadedModel;
    },
    [signal]
  );

  const handleModelFile = useCallback(
    async (files: FileWithPath[]): Promise<{ zippedFile: File; mainFileName: string }> => {
      const { unzippedFiles, nonZipFiles } = await handleInputFiles(files);
      const targetFiles: File[] = [...unzippedFiles, ...nonZipFiles];
      const isModelSecure = await checkSecure(targetFiles);
      if (!isModelSecure) throw new Error(INCORRECT_FILE_FORMAT);

      const mainFile = targetFiles.find(({ name }): boolean =>
        MAIN_FILE_FORMAT_REGEXP.test(name.toLowerCase())
      );
      if (!mainFile) throw new Error('Missing main file');
      const mainFileName = mainFile.name.split('/').pop()!;
      const [name] = mainFileName.split('.');
      const zippedFile = await createZipFromFiles(targetFiles, name);
      return { zippedFile, mainFileName };
    },
    []
  );

  const uploadModelToSpace = useCallback(
    async (files: FileWithPath[], team?: SpaceOption): Promise<void> => {
      dispatch(setTotalLoadingSteps(9));
      dispatch(setIsViewerLoading(true));
      try {
        dispatch(setLoadingState(ELoadingState.LIMIT_VALIDATION));
        if (!!team) {
          const member = (await getTeamMember(team.spaceId)).data;
          const isViewerRole = member.role === ETeamRole.VIEWER;
          if (isViewerRole)
            throw new Error(
              `In ${team.spaceTitle} workspace you don't have permissions to save the model.`
            );
        }
        const isLimitsValid = await validateModelForLimits(files, team);
        if (!isLimitsValid) {
          const { lsModelType } = getModelDataLocalStorage();
          lsModelType === EModelType.ANONYMOUS
            ? await uploadModelToViewer(files, EModelType.QUICK_VIEW)
            : dispatch(setIsViewerLoading(false));
          return;
        }

        dispatch(setLoadingState(ELoadingState.PACKING_FILES));
        const { zippedFile, mainFileName } = await handleModelFile(files);
        dispatch(setLoadingState(ELoadingState.LOADING_MODEL));
        const uploadedModel = await saveModelFile(zippedFile, mainFileName, team?.spaceId);
        await dispatch(uploadModelScreenshot({ modelId: uploadedModel.id }));
        dispatch(clearViewerDataState());
        clearModelLocalStorage();
        removeFilesFromBrowserStore();
        dispatch(closeModal());
        history.push(`/${uploadedModel.shortCode}?new=true`);
      } catch (e) {
        dispatch(setIsViewerLoading(false));
        if (e.code === API_REQUEST_CANCELED_CODE) return;
        openNotification(ESnackbarStyle.ERROR, e?.message);
      }
    },
    [
      dispatch,
      handleModelFile,
      history,
      removeFilesFromBrowserStore,
      saveModelFile,
      uploadModelToViewer,
      validateModelForLimits
    ]
  );

  const uploadDroppedModelsToSpace = useCallback(
    async (files: FileWithPath[], team?: SpaceOption): Promise<void> => {
      const isEveryFileModel = files.every((file): boolean =>
        DRAG_DROP_FILE_FORMAT_REGEXP.test(file.name.toLowerCase())
      );
      const isMultipleModels = files.length > 1 && isEveryFileModel;

      dispatch(setTotalLoadingSteps(isMultipleModels ? files.length * 3 + 2 : 10));
      dispatch(setIsViewerLoading(true));
      try {
        dispatch(setLoadingState(ELoadingState.LIMIT_VALIDATION));
        if (!!team) {
          const member = (await getTeamMember(team.spaceId)).data;
          const isViewerRole = member.role === ETeamRole.VIEWER;
          if (isViewerRole)
            throw new Error(
              `In ${team.spaceTitle} workspace you don't have permissions to save the model.`
            );
        }

        if (isMultipleModels) {
          for (const file of files) {
            dispatch(setLoadingState(ELoadingState.LIMIT_VALIDATION));
            const isLimitsValid = await validateModelForLimits([file], team);
            if (!isLimitsValid) return;
            dispatch(setLoadingState(ELoadingState.PACKING_FILES));
            const { zippedFile, mainFileName } = await handleModelFile([file]);
            dispatch(setLoadingState(ELoadingState.LOADING_MODEL));
            await saveModelFile(zippedFile, mainFileName, team?.spaceId);
          }
          dispatch(setLoadingState(ELoadingState.LOADED));
        } else {
          await uploadModelToSpace(files, team);
        }
      } catch (e) {
        dispatch(setIsViewerLoading(false));
        if (e.code === API_REQUEST_CANCELED_CODE) return;
        openNotification(ESnackbarStyle.ERROR, e?.message);
      } finally {
        if (isMultipleModels) dispatch(setIsViewerLoading(false));
      }
    },
    [dispatch, handleModelFile, saveModelFile, uploadModelToSpace, validateModelForLimits]
  );

  const uploadModelToMultipleSpaces = useCallback(
    async (files: FileWithPath[], spaces: SpaceOption[]): Promise<void> => {
      const MY_MODELS_SPACE_ID = -1;
      dispatch(setTotalLoadingSteps(spaces.length * 3));
      dispatch(setIsViewerLoading(true));
      try {
        for (const space of spaces) {
          dispatch(setLoadingState(ELoadingState.LIMIT_VALIDATION));
          if (space.spaceId === MY_MODELS_SPACE_ID) {
            const isLimitsValid = await validateModelForLimits(files);
            if (!isLimitsValid) {
              dispatch(setIsViewerLoading(false));
              return;
            }
          } else {
            const member = (await getTeamMember(space.spaceId)).data;
            const isViewerRole = member.role === ETeamRole.VIEWER;
            if (isViewerRole) {
              throw new Error(
                `In ${space.spaceTitle} workspace you don't have permissions to save the model.`
              );
            }
            const isLimitsValid = await validateModelForLimits(files, space);
            if (!isLimitsValid) {
              dispatch(setIsViewerLoading(false));
              return;
            }
          }
        }

        let firstUpload: { modelId: string; spaceTitle: string } | null = null;

        for (const { spaceId, spaceTitle } of spaces) {
          dispatch(setLoadingState(ELoadingState.PACKING_FILES));
          const { zippedFile, mainFileName } = await handleModelFile(files);
          dispatch(setLoadingState(ELoadingState.LOADING_MODEL));
          const uploadedModel =
            spaceId === MY_MODELS_SPACE_ID
              ? await saveModelFile(zippedFile, mainFileName)
              : await saveModelFile(zippedFile, mainFileName, spaceId);
          await dispatch(uploadModelScreenshot({ modelId: uploadedModel.id }));
          if (!firstUpload) {
            firstUpload = { modelId: uploadedModel.shortCode, spaceTitle };
          }
        }
        openMessage(`Opening model in ${firstUpload?.spaceTitle || ''} workspace …`);
        dispatch(clearViewerDataState());
        removeFilesFromBrowserStore();
        history.push(`/${firstUpload?.modelId || ''}`);
      } catch (e) {
        dispatch(setIsViewerLoading(false));
        openNotification(ESnackbarStyle.ERROR, e?.message);
      }
    },
    [
      dispatch,
      handleModelFile,
      history,
      removeFilesFromBrowserStore,
      saveModelFile,
      validateModelForLimits
    ]
  );

  return {
    uploadModelToViewer,
    uploadModelToSpace,
    uploadModelToMultipleSpaces,
    uploadDroppedModelsToSpace
  };
};

export default useUploadFile;
