import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';
import { DEFAULT_THEME_CSS_VARIABLES } from 'shared/constants/customization';
import { ErrorMessage } from 'shared/styles';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const InputsRow = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  position: relative;
  min-height: 52px;
  margin-bottom: 20px;

  @media ${DEVICE.tablet} {
    flex-direction: column;
    gap: 16px;
    padding: 0 30px;
    min-height: 84px;
    margin-bottom: 30px;
  }
`;

export const WorkspaceNameField = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1;

  @media ${DEVICE.tablet} {
    width: 100%;
  }
`;

export const NameFieldLabel = styled.span`
  color: ${COLORS.white};
  font-size: 14px;
  line-height: 32px;
  white-space: nowrap;
`;

export const NameFieldInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 15px;
  flex: 1;
  position: relative;

  ${ErrorMessage} {
    position: absolute;
    bottom: -24px;
    font-size: 12px;
    line-height: 15px;

    @media ${DEVICE.tablet} {
      bottom: -16px;
    }
  }
`;

export const NameFieldInput = styled.input<{ $isError: boolean }>`
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.inputBackgroundColor};
  border: 1px solid ${({ $isError }): string => ($isError ? COLORS.red : 'transparent')};
  border-radius: 4px;
  color: ${COLORS.white};
  font-size: 12px;
  height: 32px;
  outline: none;
  padding: 0 16px;
  width: 100%;

  &::placeholder {
    color: ${COLORS.doveGray};
  }

  &:focus {
    border-color: ${({ $isError }): string => ($isError ? COLORS.red : COLORS.white)};
  }
`;

export const MembersCounterContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
`;

export const CounterField = styled.div`
  display: flex;
  align-items: center;
`;

export const MembersCounter = styled.div`
  display: flex;
  align-items: center;
  margin-left: 15px;
`;

export const CounterInput = styled.input`
  width: 80px;
  height: 32px;
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.inputBackgroundColor};
  border: none;
  border-radius: 4px;
  text-align: center;
  color: ${COLORS.white};
  font-size: 12px;
  font-weight: 600;
  margin: 0 6px;
`;

export const CounterButton = styled.button`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: ${COLORS.turbo};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
`;

