import React, { useState } from 'react';
import { InfinityList, ModelCard } from 'shared/components';
import { Model } from 'shared/types';
import * as Styled from './styles';

type Props = {
  models: Model[];
  fetchModelsList: () => Promise<void>;
  isInfinite?: boolean;
  switchPage?: () => void;
  showMyModelsLabel?: boolean;
  isTeamPage?: boolean;
  isLoaded?: boolean;
};

const ModelList: React.FC<Props> = ({
  models,
  fetchModelsList,
  isInfinite = false,
  switchPage,
  showMyModelsLabel,
  isTeamPage,
  isLoaded = true
}): JSX.Element | null => {
  const [isStatsActive, setIsStatsActive] = useState(false);

  const handleStatsToggle = () => {
    setIsStatsActive(prev => !prev);
  };

  if (!isLoaded) return null;

  if (!models.length) {
    return <Styled.NoDataBlock>No Models Available</Styled.NoDataBlock>;
  }

  return (
    <Styled.ModelsListContainer>
      {isInfinite ? (
        <InfinityList
          component={(props) => (
            <ModelCard 
              {...props} 
              isStatsActive={isStatsActive}
              onStatsToggle={handleStatsToggle}
            />
          )}
          fetchModelsList={fetchModelsList}
          items={models}
          pointAction={switchPage || (() => {})}
          step={12}
          isTeamPage={isTeamPage}
        />
      ) : (
        <div className="model-cards-grid">
          {models.map((model: Model): React.ReactElement => (
            <ModelCard
              key={model.id}
              item={model}
              fetchModelsList={fetchModelsList}
              showMyModelsLabel={showMyModelsLabel}
              isTeamPage={isTeamPage}
              isStatsActive={isStatsActive}
              onStatsToggle={handleStatsToggle}
            />
          ))}
        </div>
      )}
    </Styled.ModelsListContainer>
  );
};

export default ModelList; 