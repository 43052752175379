import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';

export const DeleteButton = styled.img`
  width: 15px;
  height: 15px;
  cursor: pointer;
`;

export const AccessCheckbox = styled.div<{ $isAvailable: boolean }>`
  margin: 0 auto;
  width: 18px;
  height: 18px;
  border: 1px solid ${COLORS.white};
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  cursor: pointer;
  opacity: ${({ $isAvailable }): string => ($isAvailable ? '1' : '0.3')};

  @media ${DEVICE.tablet} {
    margin: initial;
  }
`;

export const AccessTitle = styled.span<{ $isAvailable: boolean }>`
  display: none;
  opacity: ${({ $isAvailable }): string => ($isAvailable ? '1' : '0.3')};

  @media ${DEVICE.tablet} {
    display: block;
  }
`;
