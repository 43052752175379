import React, { useCallback, useEffect, useState } from 'react';
import * as Styled from './styles';
import { useAppDispatch, useStatisticsData } from 'shared/hooks';
import { EQuotaName, ESnackbarStyle } from 'shared/types';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { openNotification } from 'utils/notification-utils';
import { getUserLimits } from 'services/api/subscriptionsService';
import { convertBytesToMegabytes } from 'utils/model-utils';
import Chart, { ChartItem } from 'chart.js/auto';
import { BORDER_COLORS, CHART_OPTIONS } from './constants';
import moment from 'moment';

type Limit = {
  title: string;
  limit: number;
  used: number;
};

type DayStat = {
  viewedModels: number;
  downloadModels: number;
};

const UsageTab = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [limits, setLimits] = useState<[string, Limit][]>([]);
  const [modelSizeLimit, setModelSizeLimit] = useState<Limit | null>(null);
  const { fetchStatistics, statistics } = useStatisticsData();
  const [statByLastTenDays, setStatByLastTenDays] = useState<DayStat[]>([]);

  const getLastTenDays = (): string[] =>
    [...Array(10)].map((_, i): string => moment().subtract(i, 'd').format('D MMM')).reverse();

  useEffect((): void => {
    const startDate = moment().subtract(10, 'days').startOf('day').toISOString();
    const endDate = moment().toISOString();
    fetchStatistics(startDate, endDate);
  }, [fetchStatistics]);

  useEffect((): void => {
    const getCurrentUserLimits = async (): Promise<void> => {
      dispatch(startLoader());
      try {
        const response = (await getUserLimits()).data;
        const limits = Object.entries(response);
        setLimits(
          limits.filter(([limitType]): boolean => limitType !== EQuotaName.MODEL_SIZE_LIMIT)
        );
        setModelSizeLimit(response[EQuotaName.MODEL_SIZE_LIMIT]);
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
      } finally {
        dispatch(stopLoader());
      }
    };
    getCurrentUserLimits();
  }, [dispatch]);

  useEffect((): void => {
    if (statistics.length) {
      const dates = getLastTenDays();
      const data = dates.reduce((collection: DayStat[], date): DayStat[] => {
        const { viewedModels, downloadModels } = statistics.find(
          (item): boolean => moment(item.createdAt).format('D MMM') === date
        ) || { viewedModels: 0, downloadModels: 0 };
        return [...collection, { viewedModels, downloadModels }];
      }, []);
      setStatByLastTenDays(data);
    } else {
      setStatByLastTenDays(Array(10).fill({ viewedModels: 0, downloadModels: 0 }));
    }
  }, [statistics]);

  const setChart = useCallback((ctx: HTMLElement | null, data: number[]): Chart => {
    const dates = getLastTenDays();

    return new Chart(ctx as ChartItem, {
      type: 'line',
      data: {
        labels: dates,
        datasets: [
          {
            label: '',
            data,
            borderColor: BORDER_COLORS,
            borderWidth: 2
          }
        ]
      },
      options: CHART_OPTIONS
    });
  }, []);

  useEffect((): (() => void) => {
    const modelsViewersContext = document.getElementById('modelsViewers');
    const modelsDownloadingContext = document.getElementById('modelsDownloading');
    const modelsViewersChart = setChart(
      modelsViewersContext,
      statByLastTenDays.map((item): number => item.viewedModels)
    );
    const modelsDownloadingChart = setChart(
      modelsDownloadingContext,
      statByLastTenDays.map((item): number => item.downloadModels)
    );

    return (): void => {
      modelsViewersChart.destroy();
      modelsDownloadingChart.destroy();
    };
  }, [statByLastTenDays, setChart]);

  const getUsageFieldValue = (limit: number, currentValue: number): string => {
    const onePercent = limit / 100;
    return (currentValue / onePercent).toFixed(2);
  };

  const getUsageLimit = (limitType: EQuotaName, limit: number, used: number): string => {
    const isUnlimited = limit === -1;
    if (isUnlimited) return 'unlimited';

    switch (limitType) {
      case EQuotaName.ACTIVE_MODELS_LIMIT:
        return `${used} of ${limit} models`;
      case EQuotaName.MODEL_VIEWS_LIMIT:
        return `${used} of ${limit} monthly views used`;
      case EQuotaName.MONTHLY_AR_VIEWS_LIMIT:
        return `${used} of ${limit} monthly AR views used`;
      default:
        return `${used} of ${limit} used`;
    }
  };

  return (
    <Styled.TabContent>
      <Styled.TabHeader>
        <Styled.ChartContainer>
          <Styled.ChartTitle>Model Views</Styled.ChartTitle>
          <Styled.Chart>
            <canvas id='modelsViewers' />
          </Styled.Chart>
        </Styled.ChartContainer>
        <Styled.ChartContainer>
          <Styled.ChartTitle>Model Downloads</Styled.ChartTitle>
          <Styled.Chart>
            <canvas id='modelsDownloading' />
          </Styled.Chart>
        </Styled.ChartContainer>
      </Styled.TabHeader>
      {!!limits.length && (
        <Styled.UsageChartContainer>
          <Styled.UsageChartTitle>Account limits</Styled.UsageChartTitle>
          <Styled.UsageChartContent>
            {limits.map(
              ([limitType, { title, used, limit }]): JSX.Element => (
                <Styled.UsageChartField key={limitType}>
                  <Styled.FieldHeader>
                    <Styled.FieldTitle>{title}</Styled.FieldTitle>
                    <Styled.LimitValues>
                      {getUsageLimit(limitType as EQuotaName, limit, used)}
                    </Styled.LimitValues>
                  </Styled.FieldHeader>
                  <Styled.ChartLineWrapper>
                    <Styled.ChartLine $chartValue={getUsageFieldValue(limit, used)} />
                  </Styled.ChartLineWrapper>
                </Styled.UsageChartField>
              )
            )}
            <Styled.UsageChartField>
              <Styled.FieldHeader>
                <Styled.FieldTitle className='model-size'>
                  {modelSizeLimit?.title}
                </Styled.FieldTitle>
                <Styled.LimitValues>
                  {`${convertBytesToMegabytes(modelSizeLimit?.limit || 0)}MB`}
                </Styled.LimitValues>
              </Styled.FieldHeader>
            </Styled.UsageChartField>
          </Styled.UsageChartContent>
        </Styled.UsageChartContainer>
      )}
    </Styled.TabContent>
  );
};

export default UsageTab;
