import React, { useEffect, useRef, useState } from 'react';
import * as Styled from './styles';
import Logo from 'assets/images/logo.png';
import Delete from 'assets/images/delete.svg';
import Download from 'assets/images/download.svg';
import Link from 'assets/images/link.svg';
import QuickShare from 'assets/images/quick-share.svg';
import NewTab from 'assets/images/new-tab-icon.svg';
import LogoAR from 'assets/images/logo-ar.png';
import Spinner from 'assets/images/spinner.svg';
import StatsArrow from 'assets/images/stats-dropdown-arrow.svg';
import {
  EConfirmModalHeader,
  EModelAccessLevel,
  EQuotaName,
  ESharingStatus,
  ESnackbarStyle,
  Model
} from 'shared/types';
import {
  useAppDispatch,
  useAppSelector,
  useCopyToClipboard,
  useFeatureAccess,
  useQrCodeGenerator
} from 'shared/hooks';
import { deleteModel, downloadModel } from 'services/api/modelService';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { getPlainNotesList } from 'services/api/notesService';
import { checkQuota } from 'utils/model-utils';
import { closeConfirmModal, showConfirmModal } from 'services/store/reducers/modalReducer';
import { ModalDeleteModel, CustomTooltip } from 'shared/components';
import { ModelCardStats } from './components/model-card-stats';
import { MODEL_DELETED, MODEL_DOWNLOADING } from 'shared/constants/notifications';
import { updateModelPublicAccessLevel } from 'services/api/permissionsService';
import { openNotification } from 'utils/notification-utils';
import { RootState } from 'services/store';
import {getTeam} from '../../../services/api/teamService';
import { TOOLTIP_MESSAGES } from './constants';

type Props = {
  item: Model;
  fetchModelsList: () => Promise<void>;
  showMyModelsLabel?: boolean;
  isTeamPage?: boolean;
  isStatsActive: boolean;
  onStatsToggle: () => void;
};

const ModelCard: React.FC<Props> = ({
  item: model,
  fetchModelsList,
  showMyModelsLabel,
  isTeamPage,
  isStatsActive,
  onStatsToggle
}): React.ReactElement => {
  const dispatch = useAppDispatch();
  const store = useAppSelector((store): RootState => store);
  const { user } = store.auth;
  const { activeTeam } = store.teams;
  const [hasArViewAccess, setHasArViewAccess] = useState<boolean>(false);
  const [hasEditAccess, setHasEditAccess] = useState<boolean>(false);
  const isShareQRCodeCreated = useRef(false);
  const shareLinkWithQueryParams = `${model.shortLinkUrl}?ar=1`;
  const { coverImageUrl, modelName } = model;
  const isPublicModel = model.status === ESharingStatus.PUBLISHED;
  const isModelOwner = user?.id === model.ownerId;
  const hasDeleteAccess = !!activeTeam ? hasEditAccess : isModelOwner;
  const { copyToClipboard } = useCopyToClipboard();
  const { checkArViewAccess, checkEditAccess } = useFeatureAccess(model, true);
  const {
    isQrCodeLoading: isArQrLoading,
    createQRCode: createArQRCode,
    qrCodeURL: arQRCodeURL
  } = useQrCodeGenerator(shareLinkWithQueryParams, LogoAR);
  const {
    isQrCodeLoading: isShareQrLoading,
    createQRCode: createShareQRCode,
    qrCodeURL: shareQRCodeURL
  } = useQrCodeGenerator(model.shortLinkUrl);
  const [totalNotes, setTotalNotes] = useState<number>(0);
  const [workspaceName, setWorkspaceName] = useState<string>('Workspace')
  const [isShareQrCodeVisible, setIsShareQrCodeVisible] = useState<boolean>(false);

  useEffect((): void => {
    if ((isModelOwner || isTeamPage || hasArViewAccess) && !isShareQRCodeCreated.current) {
      createShareQRCode();
      createArQRCode();
      isShareQRCodeCreated.current = true;
    }
  }, [createShareQRCode, createArQRCode, isModelOwner, isPublicModel, isTeamPage, hasArViewAccess]);

  useEffect((): void => {
    (async (): Promise<void> => {
      const hasArViewAccess = await checkArViewAccess();
      const hasEditAccess = await checkEditAccess();
      setHasArViewAccess(hasArViewAccess);
      setHasEditAccess(hasEditAccess);
    })();
  }, [checkArViewAccess, checkEditAccess]);

  useEffect((): void => {
    (async (): Promise<void> => {
      const total = (await getPlainNotesList(model.id)).data.length;
      setTotalNotes(total);
    })();
  }, [model]);

  useEffect((): void => {
    (async (): Promise<void> => {
      if (isTeamPage) {
        setWorkspaceName(activeTeam?.teamName || '')
      } else {
        setWorkspaceName(!!model.teamId ? (await getTeam(model.teamId)).data.teamName : 'My Workspace')
      }
    })();
  }, [activeTeam, isTeamPage, model]);

  const handleDownloadButtonClick = async (
    event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(startLoader());
    try {
      openNotification(ESnackbarStyle.SUCCESS, MODEL_DOWNLOADING);
      const response = await downloadModel(model.id);
      const type = response.data.type;
      const modelName = response.headers['content-disposition'].split('filename=')[1].split('"')[1];
      const blob = new Blob([response.data], { type });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = modelName;
      a.click();
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    } finally {
      dispatch(stopLoader());
    }
  };

  const openModelInNewTab = async (event: React.MouseEvent<HTMLElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();
    window.open(`/${model.shortCode || model.id}`, '_blank');
  };

  const handleDeleteButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    event.stopPropagation();
    const action = async (): Promise<void> => {
      try {
        dispatch(startLoader());
        await deleteModel(model!.id);
        await fetchModelsList();
        dispatch(closeConfirmModal());
        openNotification(ESnackbarStyle.SUCCESS, MODEL_DELETED);
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
      } finally {
        dispatch(stopLoader());
      }
    };

    dispatch(
      showConfirmModal({
        header: EConfirmModalHeader.DELETE,
        content: <ModalDeleteModel model={model} action={action} />
      })
    );
  };

  const handleShareButtonClick = async (
    event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();
    
    if (!isPublicModel) {
      dispatch(startLoader());
      try {
        await updateModelPublicAccessLevel(model.id, [EModelAccessLevel.VIEW_ACCESS]);
        await checkQuota(model.id, EQuotaName.MODEL_VIEWS_LIMIT);
        await fetchModelsList();
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
        return;
      } finally {
        dispatch(stopLoader());
      }
    }
    
    await copyToClipboard(model.shortLinkUrl || model.id);
  };

  const handleStatsButtonClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    event.stopPropagation();
    onStatsToggle();
  };

  return (
    <Styled.ModelCardContainer to={`/${model.shortCode || model.id}`} data-cy='model'>
      <Styled.CardTop>
        {(isModelOwner || isTeamPage) && (
          <Styled.LabelStatus $isPublic={isPublicModel}>
            {isPublicModel ? 'Public' : 'Private'}
          </Styled.LabelStatus>
        )}
        {showMyModelsLabel && isModelOwner && (
          <Styled.LabelStatus className='my-models-label'>My model</Styled.LabelStatus>
        )}
        {!!totalNotes && (
          <Styled.NoteCounter>
            <span>{totalNotes}</span>
          </Styled.NoteCounter>
        )}
        {(hasArViewAccess) && (
          <Styled.ShareQrCodeContainer $isLoading={isArQrLoading}>
            {isArQrLoading && <Styled.Spinner src={Spinner} alt='Loading' />}
            {!!arQRCodeURL && <img className='qr-code' src={arQRCodeURL} alt='AR QR code' />}
          </Styled.ShareQrCodeContainer>
        )}
        {!!coverImageUrl ? (
          <Styled.ModelImage
            onError={({ currentTarget }): void => {
              currentTarget.src = Logo;
              currentTarget.style.objectFit = 'scale-down';
            }}
            src={coverImageUrl}
            alt='Model Image'
          />
        ) : (
          <Styled.DefaultImage src={Logo} alt='Model Image' />
        )}
      </Styled.CardTop>

      <Styled.ModelDescriptionContainer>
        <Styled.ModelTitleBlock>
          <Styled.ModelNameContainer onClick={openModelInNewTab}>
            {modelName}
            <Styled.NewTabIcon src={NewTab} />
          </Styled.ModelNameContainer>
          <Styled.ModelWorkspace>
            {workspaceName}
          </Styled.ModelWorkspace>
        </Styled.ModelTitleBlock>

        <Styled.CardActions>
          <Styled.CardButtons>
            {hasEditAccess && (
              <CustomTooltip content={
                isPublicModel ? (
                  <>
                    <Styled.QrCode>
                      {isShareQrLoading && <Styled.Spinner src={Spinner} alt='Loading' />}
                      {!!shareQRCodeURL && <img className='qr-code' src={shareQRCodeURL} alt='Share QR code' />}
                    </Styled.QrCode>
                    <Styled.QrCodeDescription>{TOOLTIP_MESSAGES.VIEW_3D}</Styled.QrCodeDescription>
                    <Styled.QrCodeDescription>{TOOLTIP_MESSAGES.COPY_LINK}</Styled.QrCodeDescription>
                  </>
                ) : TOOLTIP_MESSAGES.QUICK_SHARE
              }>
                <Styled.ActionButton onClick={handleShareButtonClick}>
                  <img
                    src={isPublicModel ? Link : QuickShare}
                    alt={isPublicModel ? 'Copy sharing link to your model' : 'Create and copy sharing link to your model'}
                  />
                </Styled.ActionButton>
              </CustomTooltip>
            )}

            <CustomTooltip content={TOOLTIP_MESSAGES.DOWNLOAD}>
              <Styled.ActionButton
                onClick={handleDownloadButtonClick}
                id='model-card-download-model-button'
              >
                <img src={Download} alt='Download' />
              </Styled.ActionButton>
            </CustomTooltip>
            {hasDeleteAccess && (
              <CustomTooltip content={TOOLTIP_MESSAGES.DELETE}>
                <Styled.ActionButton
                  onClick={handleDeleteButtonClick}
                  id='model-card-delete-model-button'
                >
                  <img src={Delete} alt='Delete' />
                </Styled.ActionButton>
              </CustomTooltip>
            )}
          </Styled.CardButtons>
          <Styled.StatsButton
            type='button'
            onClick={handleStatsButtonClick}
            $isStatsActive={isStatsActive}
          >
            <span>Model Statistics</span>
            <img src={StatsArrow} alt='Show/Hide Model Stats' />
          </Styled.StatsButton>
        </Styled.CardActions>

        <ModelCardStats model={model} isStatsActive={isStatsActive} />
      </Styled.ModelDescriptionContainer>
    </Styled.ModelCardContainer>
  );
};

export default ModelCard;
