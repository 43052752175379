import React, { useState } from 'react';
import { SelectOption } from 'shared/types';
import * as Styled from './styles';
import ArrowDownIcon from 'assets/images/arrow-down.svg';
import { useHandleClickOutside } from 'shared/hooks';
import { convertStringToButtonId } from 'utils/form-utils';
import { RemoveIcon } from 'assets/dynamic-icons';
import { CREATE_NEW_ID } from 'shared/constants/customization';

type Props = {
  activeOption: SelectOption;
  onChangeOption: (value: SelectOption) => void;
  onRemoveOption: (id: number) => void;
  options: SelectOption[];
  placeholder: string;
};

const Select: React.FC<Props> = ({
  activeOption,
  onChangeOption,
  onRemoveOption,
  options,
  placeholder
}): JSX.Element => {
  const [isMenuActive, setIsMenuActive] = useState<boolean>(false);

  const handleOptionClick =
    (option: SelectOption): (() => void) =>
    (): void => {
      setIsMenuActive(false);
      onChangeOption(option);
    };

  const toggleMenuActivity = (): void => {
    setIsMenuActive((state): boolean => !state);
  };

  const handleRemoveOptionClick =
    (id: number): ((event: React.MouseEvent<HTMLButtonElement>) => void) =>
    (event): void => {
      event.preventDefault();
      event.stopPropagation();
      onRemoveOption(id);
    };

  const ref = useHandleClickOutside((): void => setIsMenuActive(false));

  return (
    <Styled.SelectContainer $isMenuActive={isMenuActive} ref={ref}>
      <Styled.MainField
        $isMenuActive={isMenuActive}
        $isEmptyValue={!activeOption?.title}
        onClick={toggleMenuActivity}
      >
        <div>{activeOption?.title || placeholder}</div>
        <img src={ArrowDownIcon} alt='Arrow' />
      </Styled.MainField>
      <Styled.SelectMenu $isMenuActive={isMenuActive}>
        {options.map(
          (option): JSX.Element => (
            <Styled.OptionItem
              onClick={handleOptionClick(option)}
              $isCreateNewItem={option.value.id === CREATE_NEW_ID}
              $isActive={option.value.id === activeOption?.value.id}
              key={option.value.id}
            >
              <span id={convertStringToButtonId(option.title)}>{option.title}</span>
              {option.value.id !== CREATE_NEW_ID && (
                <Styled.RemoveButton
                  type='button'
                  onClick={handleRemoveOptionClick(option.value.id)}
                >
                  <RemoveIcon />
                </Styled.RemoveButton>
              )}
            </Styled.OptionItem>
          )
        )}
      </Styled.SelectMenu>
    </Styled.SelectContainer>
  );
};

export default Select;
