import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { FONTS } from 'shared/constants/fonts';
import { DEVICE } from 'shared/constants/deviceSizes';

export const TabContent = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${FONTS.interRegular};
  color: ${COLORS.white};
`;

export const TabHeader = styled.section`
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  padding-right: 20px;
`;

export const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 400px;
  max-width: 600px;
  width: 45%;
  margin: 10px 0 0 20px;

  @media ${DEVICE.tablet} {
    width: 100%;
    max-width: initial;
    min-width: initial;
  }
`;

export const ChartTitle = styled.span`
  font-family: ${FONTS.interRegular};
  font-size: 16px;
  color: ${COLORS.mischka};
`;

export const Chart = styled.div`
  margin-top: 10px;
  height: 100%;
  width: 100%;
`;

export const UsageChartContainer = styled.section`
  background: ${COLORS.mineShaft};
  border-radius: 7px;
  padding: 30px 30px 60px;
  width: 80%;
  max-width: 1000px;
  min-width: 300px;
  margin-top: 40px;

  @media${DEVICE.tablet} {
    width: 100%;
  }
`;

export const UsageChartTitle = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
`;

export const UsageChartContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const UsageChartField = styled.div`
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FieldHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 10px;
`;

export const FieldTitle = styled.span`
  font-size: 16px;
  line-height: 18px;

  @media${DEVICE.mobile} {
    font-size: 14px;
    line-height: 16px;
  }
`;

export const LimitValues = styled.span`
  font-size: 12px;
  line-height: 18px;
  margin-left: 10px;
  display: flex;
  text-align: right;

  span {
    margin: 2px 0;
  }

  span:last-child {
    margin-left: 4px;
  }

  @media${DEVICE.mobile} {
    flex-direction: column;
    font-size: 10px;
    line-height: 12px;
  }
`;

export const ChartLineWrapper = styled.div`
  background: ${COLORS.black};
  height: 6px;
  border-radius: 4px;
  overflow-x: hidden;
`;

export const ChartLine = styled.div<{ $chartValue: string }>`
  background: ${COLORS.turbo};
  height: 6px;
  border-radius: 4px;
  width: ${({ $chartValue }): string => `${$chartValue}%`};
`;
