import { COLORS } from 'shared/constants/colors';
import styled from 'styled-components';

export const SelectContainer = styled.div<{ $isMenuActive: boolean }>`
  color: ${COLORS.white};
  font-weight: 600;
  font-size: 14px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const MainField = styled.div<{ $isMenuActive: boolean }>`
  position: relative;
  width: 100%;
  height: 40px;
  color: ${COLORS.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
  border-radius: 6px 6px 0 0;
  transition: border-radius 0.1s ease 0.2s;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    transform: scaleX(${({ $isMenuActive }): string => ($isMenuActive ? '1' : '0')});
    transition: all 0.2s ease-out;
    border-bottom: 1px solid ${COLORS.white};
  }
`;

export const OptionIconContainer = styled.div`
  width: 22px;
  height: 22px;
`;

export const OptionTitle = styled.span`
  margin: 0 16px 0 10px;
`;

export const ArrowIconContainer = styled.div<{$isMenuActive: boolean}>`
  width: 10px;
  min-width: 10px;
  height: 10px;
  transform: ${({ $isMenuActive }): string => ($isMenuActive ? 'rotate(180deg)' : '0')};
  transition: transform 0.2s;
`;

export const SelectMenu = styled.div<{ $isMenuActive: boolean }>`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  max-height: ${({ $isMenuActive }): string => ($isMenuActive ? '200px' : '0px')};
  background: ${COLORS.deepBlack};
  position: absolute;
  z-index: 11;
  margin-top: 40px;
  transition: max-height ease-in-out 0.3s;
  border-radius: 0 0 4px 4px;
  box-shadow: rgb(0 0 0 / 70%) -5px 10px 10px, rgb(0 0 0 / 30%) 0 1px 18px,
    rgb(0 0 0 / 60%) 0 30px 15px;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

export const OptionItem = styled.div<{ $isActive: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  min-height: 40px;
  padding: 0 12px;
  cursor: pointer;
  color: ${COLORS.white};
  background: ${({ $isActive }): string => ($isActive ? 'rgba(255, 255, 255, 0.1)' : 'transparent')};

  &:hover {
    background: ${COLORS.turbo};
    color: ${COLORS.black};
  }

  & span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    max-width: 98%;
    text-align: left;
    margin-right: 10px;
  }
`;
