import React, { useMemo, useState, useRef, useEffect } from 'react';
import * as Styled from './styles';
import { SubscriptionPlan, SubscriptionFeatures } from 'shared/components';
import { SubscriptionsState, EPaymentType, TeamsState } from 'shared/types';
import { useAppSelector } from 'shared/hooks';
import { extractPlanData } from 'utils/subscription-plans-utils';
import { PLAN_CARD } from '../constants';

type Props = {
  isModal?: boolean;
  title?: string;
  paymentType?: EPaymentType;
  withCustomPlan?: boolean;
  seats?: number;
};

const SubscriptionInfo: React.FC<Props> = ({ 
  isModal, 
  title,
  paymentType,
  withCustomPlan,
  seats = 1
}): JSX.Element => {
  const { subscriptionPlans, currentSubscriptionPlan } = useAppSelector((store): SubscriptionsState => store.subscriptions);
  const { teamPlan } = useAppSelector((store): TeamsState => store.teams);
  
  const isTeamPlan = paymentType === EPaymentType.CREATE_TEAM_PLAN || paymentType === EPaymentType.UPDATE_TEAM_PLAN;
  
  const [isMonthlyType, setIsMonthlyType] = useState<boolean>(() => {
    const activePlan = isTeamPlan ? teamPlan : currentSubscriptionPlan;
    return !activePlan || activePlan.recurringInterval === 'month';
  });

  const featuresRef = useRef<HTMLDivElement>(null);

  const filteredPlans = useMemo(() => {
    return paymentType === EPaymentType.CREATE_TEAM_PLAN || paymentType === EPaymentType.UPDATE_TEAM_PLAN
      ? subscriptionPlans.slice(-2)
      : subscriptionPlans; 
  }, [subscriptionPlans, paymentType]);

  const extractedPlansData = useMemo(
    () => filteredPlans.map((plan) => extractPlanData(plan)),
    [filteredPlans]
  );

  const customPlanData = useMemo((): ReturnType<typeof extractPlanData> => {
    return extractPlanData(undefined, true);
  }, []);

  const [isMultiRow, setIsMultiRow] = useState(false);
  const subscriptionsContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = subscriptionsContainerRef.current;
    if (!container) return;

    let frameId: number;
    const checkMultiRow = () => {
      cancelAnimationFrame(frameId);
      frameId = requestAnimationFrame(() => {
        const threshold = 3 * PLAN_CARD.scaleValue(PLAN_CARD.width, true);
        setIsMultiRow(container.clientWidth <= threshold);
      });
    };

    const resizeObserver = new ResizeObserver(checkMultiRow);
    resizeObserver.observe(container);
    checkMultiRow();

    return () => {
      resizeObserver.disconnect();
      cancelAnimationFrame(frameId);
    };
  }, []);

  const handleEnterprisePlanClick = (): void => {
    window.open('/contact-us', '_blank');
  };

  const showEnterprisePlanLink = useMemo(() => isModal && !isMultiRow, [isModal, isMultiRow]);
  const totalPlans = useMemo(() => 
    showEnterprisePlanLink ? filteredPlans.length : filteredPlans.length + 1,
    [showEnterprisePlanLink, filteredPlans.length]
  );

  return (
    <Styled.SubscriptionsContainer
      $numPlans={totalPlans}
      $isModal={isModal}
      ref={subscriptionsContainerRef}>
      <Styled.HeaderContainer>
        {title && <Styled.Title>{title}</Styled.Title>}
        <Styled.BillingTypeSwitcher>
          <Styled.BillingTypeText>Billing Type:</Styled.BillingTypeText>
          <Styled.BillingTypeButton
            onClick={(): void => setIsMonthlyType(true)}
            $isActive={isMonthlyType}
          >
            Monthly
          </Styled.BillingTypeButton>
          <Styled.BillingTypeButton
            onClick={(): void => setIsMonthlyType(false)}
            $isActive={!isMonthlyType}
          >
            Yearly
          </Styled.BillingTypeButton>
        </Styled.BillingTypeSwitcher>
      </Styled.HeaderContainer>
      <Styled.PlansContainer 
        $numPlans={totalPlans} 
        $isModal={isModal}
      >
        {filteredPlans.map(
          (plan, index): JSX.Element => (
            <SubscriptionPlan 
              key={plan.id}
              plan={plan}
              extractedPlanData={extractedPlansData[index]}
              isMonthlyType={isMonthlyType} 
              isModal={isModal}
              featuresRef={featuresRef}
              paymentType={paymentType}
              seats={seats}
            />
          )
        )}
        {!showEnterprisePlanLink && (
          <SubscriptionPlan
            key={customPlanData.id}
            extractedPlanData={customPlanData}
            isMonthlyType={isMonthlyType} 
            isModal={isModal}
            featuresRef={featuresRef}
            paymentType={paymentType}
            seats={seats}
          />
        )}
      </Styled.PlansContainer>
      {showEnterprisePlanLink && (
        <Styled.EnterprisePlanLink>
          <span>Need an enterprise plan?</span>
          <span onClick={handleEnterprisePlanClick}>Click here!</span>
        </Styled.EnterprisePlanLink>
      )}
      {!isModal && (
        <div ref={featuresRef}>
          <SubscriptionFeatures 
            isMonthlyType={isMonthlyType}
            extractedPlansData={[...extractedPlansData, customPlanData]}
            seats={seats}
          />
        </div>
      )}
    </Styled.SubscriptionsContainer>
  );
};

export default SubscriptionInfo;
