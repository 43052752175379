import React, { useCallback, useEffect, useRef } from 'react';
import * as Styled from './styles';
import moment from 'moment';
import { PreviewViewerController } from 'shared/components';
import { NewModelIcon, ProfileIcon } from 'assets/dynamic-icons';
import { useAppSelector } from 'shared/hooks';
import LogoIcon from 'assets/images/logo.png';
import { MAIN_SCENE_CANVAS_ID } from 'shared/constants/html-elements-ids';
import {
  HomeIcon,
  MyModelsIcon,
  HistoryIcon,
  SettingsIcon,
  LogoutIcon,
  QuickViewIcon
} from 'assets/dynamic-icons';
import { RootState } from 'services/store';

const CustomizationPreview = (): JSX.Element => {
  const store = useAppSelector((store): RootState => store);
  const {
    brandColors,
    brandLogoUrl,
    siteTitle,
    siteSubtitle,
    environmentPreviewURL,
    cubemapImagePaths
  } = store.customization;
  const viewerRef = useRef<HTMLDivElement | null>(null);
  const canvas = document.getElementById(MAIN_SCENE_CANVAS_ID);

  const setCanvasSize = useCallback((): void => {
    canvas?.setAttribute('style', `width: 100%; height: 100%`);

    if (!!viewerRef.current) {
      canvas?.setAttribute('width', `${viewerRef.current.clientWidth}`);
    }
  }, [canvas]);

  useEffect((): (() => void) => {
    window.addEventListener('resize', setCanvasSize);

    return (): void => {
      window.removeEventListener('resize', setCanvasSize);
    };
  }, [setCanvasSize]);

  const { headerTextColor, buttonTextColor, buttonBackgroundColor, normalTextColor } = brandColors;

  return (
    <Styled.CustomizationPreviewContainer>
      <Styled.CustomizationPreview>
        <Styled.UIElementsContainer>
          <Styled.PreviewHeader style={{ background: `${headerTextColor}` }}>
            <Styled.HeaderLeftSide>
              <Styled.HeaderLogo src={brandLogoUrl || LogoIcon} alt='Preview Logo' />
              <Styled.TitleContainer>
                <Styled.HeaderTitle style={{ color: `${normalTextColor}` }}>
                  {siteTitle}
                </Styled.HeaderTitle>
                <Styled.HeaderSubtitle style={{ color: `${normalTextColor}` }}>
                  {siteSubtitle}
                </Styled.HeaderSubtitle>
              </Styled.TitleContainer>
            </Styled.HeaderLeftSide>
            <Styled.HeaderRightSide>
              <Styled.HeaderButton>
                <Styled.ButtonIcon
                  $buttonsColor={buttonBackgroundColor}
                  $buttonTextColor={buttonTextColor}
                >
                  <QuickViewIcon />
                </Styled.ButtonIcon>
                <Styled.ButtonText style={{ color: `${normalTextColor}` }}>
                  Quick View
                </Styled.ButtonText>
                <Styled.QuickViewHint $textColor={normalTextColor}>?</Styled.QuickViewHint>
              </Styled.HeaderButton>
              <Styled.HeaderButton>
                <Styled.ButtonIcon
                  $buttonsColor={buttonBackgroundColor}
                  $buttonTextColor={buttonTextColor}
                >
                  <NewModelIcon />
                </Styled.ButtonIcon>
                <Styled.ButtonText style={{ color: `${normalTextColor}` }}>
                  Upload New Model
                </Styled.ButtonText>
              </Styled.HeaderButton>
              <Styled.HeaderButton>
                <Styled.ButtonIcon
                  $buttonsColor={buttonBackgroundColor}
                  $buttonTextColor={buttonTextColor}
                >
                  <ProfileIcon />
                </Styled.ButtonIcon>
                <Styled.ButtonText style={{ color: `${normalTextColor}` }}>
                  Profile
                </Styled.ButtonText>
              </Styled.HeaderButton>
            </Styled.HeaderRightSide>
          </Styled.PreviewHeader>
          <Styled.PreviewSidebar style={{ background: `${headerTextColor}` }}>
            <Styled.SidebarIconsContainer>
              <Styled.SidebarIconWrapper
                $activeColor={buttonBackgroundColor}
                $color={buttonTextColor}
                className='home-icon'
              >
                <HomeIcon />
              </Styled.SidebarIconWrapper>
              <Styled.SidebarIconWrapper
                $activeColor={buttonBackgroundColor}
                $color={normalTextColor}
              >
                <MyModelsIcon />
              </Styled.SidebarIconWrapper>
              <Styled.SidebarIconWrapper
                $activeColor={buttonBackgroundColor}
                $color={normalTextColor}
              >
                <HistoryIcon />
              </Styled.SidebarIconWrapper>
              <Styled.SidebarIconWrapper
                $activeColor={buttonBackgroundColor}
                $color={normalTextColor}
              >
                <SettingsIcon />
              </Styled.SidebarIconWrapper>
              <Styled.SidebarIconWrapper
                $activeColor={buttonBackgroundColor}
                $color={normalTextColor}
              >
                <LogoutIcon />
              </Styled.SidebarIconWrapper>
            </Styled.SidebarIconsContainer>
          </Styled.PreviewSidebar>
          <Styled.PreviewFooter style={{ background: `${headerTextColor}` }}>
            <Styled.PreviewFooterYear style={{ color: `${normalTextColor}` }}>
              {moment().year()} © glbee
            </Styled.PreviewFooterYear>
            <Styled.PreviewFooterMenu>
              <Styled.PreviewFooterMenuItem style={{ color: `${normalTextColor}` }}>
                Support
              </Styled.PreviewFooterMenuItem>
              <Styled.PreviewFooterMenuItem style={{ color: `${normalTextColor}` }}>
                Privacy Policy
              </Styled.PreviewFooterMenuItem>
            </Styled.PreviewFooterMenu>
          </Styled.PreviewFooter>
        </Styled.UIElementsContainer>
        <div ref={viewerRef}>
          <PreviewViewerController
            environmentPreviewURL={environmentPreviewURL}
            cubemapImagePaths={cubemapImagePaths}
            isPreviewMode
          />
        </div>
      </Styled.CustomizationPreview>
    </Styled.CustomizationPreviewContainer>
  );
};

export default CustomizationPreview;
