import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';
import { DEFAULT_THEME_CSS_VARIABLES } from 'shared/constants/customization';

export const SettingsSection = styled.section`
  display: flex;
  flex-direction: column;
  width: 440px;
  padding: 30px 20px;
  background-color: ${COLORS.mineShaft};
  border-radius: 7px;

  &:not(:first-child) {
    margin-top: 10px;
  }

  @media ${DEVICE.mobile} {
    width: 100%;
    padding: 20px 10px;
  }
`;

export const SettingsSectionTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SettingsSectionTitle = styled.h2`
  font-weight: 600;
  font-size: 15px;
  line-height: 17px;
  color: ${COLORS.white};
  margin-bottom: 0;
`;

export const SettingsSectionTitleHint = styled.img`
  margin: 2px 0 0 8px;
  width: 12px;
  height: 12px;
`;

export const SectionTitleTooltip = styled.div`
  font-weight: 400;
  font-size: 10px;
  line-height: 11px;
  color: ${COLORS.white};
  text-align: center;
`;

export const SettingsField = styled.div`
  display: flex;
`;

export const FieldLabel = styled.label`
  margin-top: 7px;
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  color: ${COLORS.white};
  width: 60px;
  min-width: 60px;
  text-align: right;
`;

export const FieldInputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 52px;
  width: 100%;

  .hint {
    margin-top: 6px;
    margin-left: auto;
    font-size: 10px;
    line-height: 11px;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 8px;
  top: 3px;
  background: transparent;

  img {
    width: 12px;
    height: 12px;
  }
`;

export const EditButton = styled.button<{ $isNewCubemapField: boolean }>`
  position: absolute;
  right: ${({ $isNewCubemapField }): string => ($isNewCubemapField ? '30px' : '52px')};
  top: 3px;
  background: transparent;

  img {
    width: 15px;
    height: 15px;
  }
`;

export const RemoveButton = styled.button<{ $isInputActive?: boolean }>`
  position: absolute;
  right: ${({ $isInputActive }): string => ($isInputActive ? '8px' : '30px')};
  top: 3px;
  background: transparent;

  img {
    width: 15px;
    height: 15px;
  }
`;

export const FieldInput = styled.input`
  height: 30px;
  width: 100%;
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.inputBackgroundSecondaryColor};
  color: ${COLORS.white};
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  padding: 0 30px 0 12px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 6px;
`;

export const CubemapsSettings = styled.form`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

export const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 10px;
`;

export const UploadImageField = styled.div`
  display: flex;
  width: 47%;
  margin-right: 10px;
`;

export const UploadImageButton = styled.label`
  display: flex;
  margin-top: 5px;
  width: 30px;
  min-width: 30px;
  cursor: pointer;

  input {
    display: none;
  }

  img {
    width: 21px;
    height: 21px;
  }
`;

export const FileNameField = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  width: 100%;
  max-width: 158px;
  background: ${COLORS.black};
  color: ${COLORS.white};
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  padding: 0 30px 0 12px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span.placeholder {
    color: #8d8d8d;
  }
`;

export const SaveButton = styled.button`
  margin: 0 0 0 auto;
  width: 118px;
  height: 33px;
  background-color: ${COLORS.turbo};
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  color: ${COLORS.black};
  border-radius: 4px;

  &:hover {
    background-color: ${COLORS.gorse};
  }
`;
