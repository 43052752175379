import * as THREE from 'three';
import {
  IObjectOptionsUpdateResult,
  ISetupObjectProps,
  PartialObjectOptions
} from 'shared/interfaces';
import { INITIAL_MODEL_SETTINGS } from 'shared/constants/model-settings';
import { INTERNAL_OBJECT_WRAPPER_NAME } from 'shared/constants/scenes-constants';
import {
  MODEL_DEF_POSITION,
  MODEL_MAX_SCALE,
  MODEL_MIN_SCALE
} from 'shared/constants/scene-settings-constants';
import { MainScene } from 'shared/webgl/scenes';
import { BaseScene } from 'shared/webgl/scenes';
import {
  getAxialMax,
  getObjectScaleFactor,
  setFromAxial,
  setFromDegrees
} from 'utils/scenes-utils';
import { ModelDimensionsController } from 'shared/webgl/controllers/ModelDimensionsController';
import { openNotification } from 'utils/notification-utils';
import { ESnackbarStyle } from 'shared/types';
import gsap from 'gsap';

export class ObjectController {
  public positioned: boolean = false;
  public callbackStackOnPositioned: (() => void)[] = [];

  private dimensionsController: ModelDimensionsController;

  private objectWrapper: THREE.Object3D | undefined;
  private externalObjectWrapper: THREE.Object3D | undefined;
  private internalObjectWrapper: THREE.Object3D | undefined;
  private isCustomizationMode: boolean = false;
  private isArMode: boolean = false;
  private showDimensions: boolean = false;
  private needsUpdate: boolean = false;
  private castShadow: boolean = false;
  private wireframe: boolean = false;
  private skinning: boolean = true;
  private hasSkeleton: boolean = false;
  private frustumCulled: boolean = false;
  private autoRotate: boolean = false;
  private rotationSpeed: number = 0;
  private factor: number = NaN;
  private prevCustomPivot: THREE.Vector3 = new THREE.Vector3();
  private customPivot: THREE.Vector3 = new THREE.Vector3();
  private modelBBCenter: THREE.Vector3 = new THREE.Vector3();
  private scale: THREE.Vector3 = new THREE.Vector3(1, 1, 1);
  private offset: THREE.Vector3 = new THREE.Vector3();
  private rotation: THREE.Euler = new THREE.Euler();

  constructor(private readonly mainScene: MainScene, private readonly arScene?: BaseScene) {
    this.mainScene = mainScene;
    this.arScene = arScene;
    this.dimensionsController = new ModelDimensionsController(this.mainScene);

    const animate = (): void => {
      if (!this.externalObjectWrapper || !this.objectWrapper || !this.internalObjectWrapper) {
        this.positioned = false;

        window.requestAnimationFrame(animate);

        return;
      }

      if (!this.isArMode && !this.isCustomizationMode) {
        this.objectWrapper.scale.copy(this.allowedScale);
      }

      if (!this.positioned) {
        this.recenterObject();
      } else {
        this.updateOffset({});
        this.updateRotation({});

        if (this.autoRotate) {
          this.rotationSpeed += (1 - this.rotationSpeed) / 5;
        } else {
          this.rotationSpeed += (0 - this.rotationSpeed) / 5;
        }

        if (this.isArMode) {
          this.objectWrapper.rotation.y += 0.01 * this.rotationSpeed;
        } else {
          this.externalObjectWrapper.rotation.y += 0.01 * this.rotationSpeed;
          this.mainScene.dimensionsWrapper.rotation.copy(this.externalObjectWrapper.rotation);
        }
      }
    };

    this.mainScene.renderCallbackStack.push(animate);
    this.arScene?.renderCallbackStack?.push(animate);
  }

  public switchScene({
    modelDimensionsBlock,
    scaleFactor,
    firstSetup,
    isArMode = false,
    isCustomizationMode
  }: ISetupObjectProps): IObjectOptionsUpdateResult {
    this.isArMode = !!isArMode;
    this.isCustomizationMode = !!isCustomizationMode;
    this.objectWrapper =
      isArMode && this.arScene ? this.arScene.objectWrapper : this.mainScene.objectWrapper;
    this.externalObjectWrapper =
      isArMode && this.arScene
        ? this.arScene.externalObjectWrapper
        : this.mainScene.externalObjectWrapper;

    if (!this.isArMode) {
      this.positioned = false;
    } else {
      this.mainScene.resetModelTransforms();
    }

    this.createInternalWrapper();
    this.recenterObject(firstSetup);

    this.setScaleFactor({ scaleFactor, firstSetup });

    this.performTraverseUpdating();
    this.updateInternalWrapperPosition();

    this.dimensionsController.create({
      parent: modelDimensionsBlock,
      factor: this.factor,
      scale: getAxialMax(this.allowedScale)
    });

    return {
      updatedScaleFactor: this.factor,
      hasSkeleton: this.hasSkeleton
    };
  }

  private createInternalWrapper(): void {
    if (!this.mainScene.model || !this.objectWrapper) return;

    this.internalObjectWrapper = new THREE.Object3D();
    this.internalObjectWrapper.name = INTERNAL_OBJECT_WRAPPER_NAME;

    this.internalObjectWrapper.add(this.mainScene.model);

    this.objectWrapper.clear();
    this.objectWrapper.add(this.internalObjectWrapper);
  }

  private recenterObject(updateBB?: boolean): void {
    if (!this.mainScene.model || !this.internalObjectWrapper || this.isArMode || this.positioned)
      return;

    this.mainScene.modelBoundingBoxNeedsUpdate = !!updateBB;

    const boundingBox = this.mainScene.modelBoundingBox;
    boundingBox.getCenter(this.modelBBCenter);

    this.prevCustomPivot.copy(this.customPivot);
    this.customPivot
      .copy(this.internalObjectWrapper.position)
      .add(MODEL_DEF_POSITION)
      .sub(this.modelBBCenter.clone().setY(boundingBox.min.y));

    this.positioned = true;

    this.callbackStackOnPositioned.forEach((callback): void => callback());
    this.callbackStackOnPositioned = [];
  }

  private updateInternalWrapperPosition(): void {
    if (!this.internalObjectWrapper || !this.customPivot) return;

    this.internalObjectWrapper.scale.setScalar(this.factor);
    this.internalObjectWrapper.position.copy(this.customPivot).multiplyScalar(this.factor);
  }

  private get allowedScale(): THREE.Vector3 {
    return new THREE.Vector3()
      .copy(MODEL_MIN_SCALE)
      .max(new THREE.Vector3().copy(MODEL_MAX_SCALE).min(this.scale));
  }

  private setScaleFactor(options: PartialObjectOptions): void {
    if (
      (options.scaleFactor === this.factor &&
        options.scaleFactor !== INITIAL_MODEL_SETTINGS.scaleFactor.x) ||
      (this.factor !== undefined &&
        this.factor !== INITIAL_MODEL_SETTINGS.scaleFactor.x &&
        !Number.isNaN(this.factor) &&
        !options.firstSetup)
    ) {
      return;
    }

    if (
      !options.firstSetup &&
      options.scaleFactor !== undefined &&
      options.scaleFactor !== INITIAL_MODEL_SETTINGS.scaleFactor.x
    ) {
      this.factor = options.scaleFactor;

      return;
    }

    this.factor = getObjectScaleFactor(this.mainScene.modelBoundingBox, 1, true);
  }

  public updateAutoRotate(option: boolean): void {
    if (!option) {
      if (this.externalObjectWrapper) {
        const rotation = this.closestMultipleOfPi(this.externalObjectWrapper.rotation.y)
        gsap.to(this.externalObjectWrapper.rotation, {
          y: rotation,
          ease: 'power4.inOut',
          duration: 0.8
        })
      }
    }
  }

  private closestMultipleOfPi(x: number) {
    const pi = 2 * Math.PI;
    
    // Calculate floor and ceiling multiples
    const nFloor = Math.floor(x / pi);
    const nCeil = Math.ceil(x / pi);
    
    // Calculate the actual multiples
    const floorMultiple = nFloor * pi;
    const ceilMultiple = nCeil * pi;
    
    // Calculate distances to x
    const distanceFloor = Math.abs(x - floorMultiple);
    const distanceCeil = Math.abs(x - ceilMultiple);
    
    // Determine the closest multiple
    if (distanceFloor < distanceCeil) {
        return floorMultiple;
    } else {
        return ceilMultiple;
    }
}


  public updateByOptions(options: PartialObjectOptions): void {
    if (!this.objectWrapper || !this.internalObjectWrapper) {
      return;
    }
    
    if (options.castShadows !== undefined && options.castShadows !== this.castShadow) {
      this.castShadow = options.castShadows;
      this.needsUpdate = true;
    }

    if (options.wireframe !== undefined && options.wireframe !== this.wireframe) {
      this.wireframe = options.wireframe;
      this.needsUpdate = true;
    }

    if (options.showDimensions !== undefined) {
      this.showDimensions = options.showDimensions;
    }

    this.updateScale(options);
    this.updateOffset(options);
    this.updateRotation(options);
    this.dimensionsController.toggleVisibility(this.showDimensions);

    if (this.needsUpdate) {
      this.needsUpdate = false;
      this.performTraverseUpdating();
    }

    if (options.autoRotate !== undefined) {
      this.autoRotate = options.autoRotate;
    }
  }

  private updateScale(options: PartialObjectOptions): void {
    if (!this.objectWrapper) return;

    if (
      options.scale &&
      options.scale.x &&
      options.scale.y &&
      options.scale.z &&
      (options.firstSetup ||
        options.scale.x !== this.scale.x ||
        options.scale.y !== this.scale.y ||
        options.scale.z !== this.scale.z)
    ) {
      setFromAxial(this.scale, options.scale);

      this.objectWrapper.scale.copy(this.allowedScale);
      this.handleUpdateDimensions();

      this.mainScene.modelBoundingBoxNeedsUpdate = true;
    }
  }

  private updateOffset(options: PartialObjectOptions): void {
    if (this.objectWrapper && !this.isArMode) {
      if (options.offset) {
        setFromAxial(this.offset, options.offset);
      }

      this.objectWrapper.position.copy(this.offset);

      if (options.offset) {
        this.mainScene.modelBoundingBoxNeedsUpdate = true;

        this.handleUpdateDimensions();
      }
    }
  }

  private updateRotation(options: PartialObjectOptions): void {
    if (this.mainScene.model && !this.isArMode) {
      if (options.rotation) {
        setFromDegrees(this.rotation, options.rotation);
      }

      this.mainScene.model.rotation.copy(this.rotation);

      if (options.rotation) {
        this.mainScene.modelBoundingBoxNeedsUpdate = true;

        this.handleUpdateDimensions();
      }
    }
  }

  private performTraverseUpdating(): void {
    if (!this.internalObjectWrapper) return;

    this.internalObjectWrapper.traverse((object): void => {
      object.frustumCulled = this.frustumCulled;
      object.receiveShadow = false;
      object.castShadow = this.castShadow;

      if (object instanceof THREE.SkinnedMesh) {
        object.material.skinning = this.skinning;

        if (!this.hasSkeleton) {

          this.hasSkeleton = true;
          openNotification(
            ESnackbarStyle.HOLD_UP,
            `The model has skeletal structures! Models with skinned meshes have limitations of interaction.`
          );
        }
      }

      if (
        object instanceof THREE.Mesh<any> &&
        object.geometry &&
        object.geometry.getAttribute('position')
      ) {
        const materials = Array.isArray(object.material) ? object.material : [object.material];

        materials.forEach((material): void => {
          material.wireframe = this.wireframe;
        });
      }

      if (object instanceof THREE.Mesh<any>) {
        object.material.depthWrite = !object.material.transparent;
      }

      // prevents models from having black textures
      if (
        object instanceof THREE.Mesh<any> &&
        object.material instanceof THREE.MeshPhongMaterial &&
        object.material.map &&
        !object.material.map.image
      ) {
        object.material.map = null;
      }
    });
  }

  private handleUpdateDimensions(): void {
    requestAnimationFrame((): void => {
      this.dimensionsController.update({
        factor: this.factor,
        scale: getAxialMax(this.allowedScale)
      });
    });
  }
}
