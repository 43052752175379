import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { FONTS } from 'shared/constants/fonts';

export const ModelDescription = styled.section`
  margin-top: 24px;
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  font-family: ${FONTS.interRegular};
  color: ${COLORS.mischka};
  border-top: 1px solid rgba(255, 255, 255, 0.17);
  border-bottom: 1px solid rgba(255, 255, 255, 0.17);
`;

export const ModelDescriptionTitle = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: ${COLORS.white};
`;

export const Textures = styled.div`
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 4px;
  background: ${COLORS.deepBlack};
  overflow: hidden;
`;

export const TexturesField = styled.div`
  width: 100%;
  display: flex;
  transition: all 0.05s;

  &:nth-child(even) {
    background: rgba(71, 71, 71, 0.59);
  }

  &.block-title > div {
    font-weight: 600;
    font-size: 13px;
    line-height: 14px;
    height: 26px;
  }

  &:hover {
    background: var(--theme-secondary-color);
    color: var(--button-text-color);
  }

  &.block-title:hover {
    background: ${COLORS.deepBlack};
    color: ${COLORS.mischka};
  }
`;

export const FieldTitle = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
  margin-left: 14px;

  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
  }

  img {
    margin-left: 6px;
    width: 11px;
    height: 11px;
  }
`;

export const FieldValue = styled.div`
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
`;

export const TooltipField = styled.span`
  color: ${COLORS.white};
`;

export const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
`;

export const DescriptionBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 48%;
  border-radius: 4px;
  background: ${COLORS.deepBlack};
  overflow: hidden;
`;

export const BlockTitle = styled.span`
  display: flex;
  align-items: center;
  padding-left: 9px;
  font-weight: 600;
  font-size: 13px;
  line-height: 14px;
  height: 26px;
`;

export const DescriptionField = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 9px;

  span:last-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span:first-child {
    margin-right: 6px;
  }

  &:nth-child(even) {
    background: rgba(71, 71, 71, 0.59);
  }
`;

export const LitField = styled.div`
  width: 100%;
  height: 22px;
  display: flex;
`;

export const LitItem = styled.div<{ $isActive: boolean }>`
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ $isActive }): string =>
    $isActive ? 'var(--theme-secondary-color)' : COLORS.deepBlack};
  color: ${({ $isActive }): string => ($isActive ? 'var(--button-text-color)' : COLORS.mischka)};
`;
