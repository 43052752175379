import { useCallback, useEffect, useMemo, useState } from 'react';
import { getARModel, getCustomBrand, getModel } from 'services/api/modelService';
import { AuthState, ELoadingState, EQuotaName, ESnackbarStyle, ModelSettings } from 'shared/types';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import {
  setIsViewerLoading,
  setLoadingState,
  setTotalLoadingSteps
} from 'services/store/reducers/loaderReducer';
import { openNotification } from 'utils/notification-utils';
import { getModelFromSessionStorage, setModelToSessionStorage } from 'utils/storage-utils';
import { NEED_TO_LOGIN, NO_MODEL, RESTRICT_VIEWING } from 'shared/constants/notifications';
import {
  API_REQUEST_CANCELED_CODE,
  LIMIT_ERROR_CODE,
  MODEL_NOT_FOUND,
  MODEL_VIEW_ERROR
} from 'shared/constants/errors';
import { showEmbeddedModelPrompt, showModal } from 'services/store/reducers/modalReducer';
import { ModalLogin } from 'shared/components';
import { getModelSettings } from 'utils/model-utils';
import { E3DModelFileTypes } from 'shared/enums/E3DModelFileTypes';
import { FILE_TYPES } from 'shared/constants/model-settings';
import { MTL_FORMAT_REGEXP } from 'shared/constants/regexps';
import {
  setModel,
  setModelMtls,
  setModelSettings,
  setModelFileType
} from 'services/store/reducers/viewerDataReducer';
import { useHistory } from 'react-router-dom';
import { setBranding, setIsBrandingLoading } from 'services/store/reducers/brandingReducer';
import { openAlert } from 'utils/alert-utils';
import { VIEWS_LIMIT_ALERT } from 'shared/constants/styled-notifications';
import { convertBrandColorsToCSSVariables } from 'utils/theme-utils';

type Result = {
  fetchModel: (modelId: string, isArMode?: boolean) => Promise<void>;
  isModelLoading: boolean;
};

const useModelData = (isEmbeddedModel: boolean, isTransparentBackground?: boolean): Result => {
  const history = useHistory();
  const controller = useMemo((): AbortController => new AbortController(), []);
  const signal: AbortSignal = controller.signal;
  const { isAuth } = useAppSelector((store): AuthState => store.auth);
  const dispatch = useAppDispatch();
  const [isModelLoading, setIsModelLoading] = useState<boolean>(true);

  useEffect(
    (): (() => void) => (): void => {
      controller.abort();
    },
    [controller]
  );

  const checkError = useCallback(
    (error: any): void => {
      if (error.code === API_REQUEST_CANCELED_CODE) return;

      const isViewsLimitError =
        error?.details?.error_code === LIMIT_ERROR_CODE &&
        error?.details?.quota_code === EQuotaName.MODEL_VIEWS_LIMIT;
      const isViewPermissionError = error?.details?.error_code === MODEL_VIEW_ERROR;
      const isModelNotFoundError = error?.message === MODEL_NOT_FOUND;

      switch (true) {
        case isViewsLimitError:
          isEmbeddedModel
            ? dispatch(showEmbeddedModelPrompt({ promptContent: VIEWS_LIMIT_ALERT }))
            : openAlert(VIEWS_LIMIT_ALERT, 5, 'Oh no!');
          break;
        case isViewPermissionError && isEmbeddedModel:
          dispatch(showEmbeddedModelPrompt({ promptContent: RESTRICT_VIEWING }));
          break;
        case isViewPermissionError:
          isAuth
            ? openAlert(RESTRICT_VIEWING)
            : openNotification(ESnackbarStyle.HOLD_UP, NEED_TO_LOGIN);
          if (!isAuth) dispatch(showModal(<ModalLogin />));
          break;
        case isModelNotFoundError:
          isEmbeddedModel
            ? dispatch(showEmbeddedModelPrompt({ promptContent: NO_MODEL }))
            : history.push('/not-found');
          break;
        default:
          history.push('/not-found');
          openNotification(ESnackbarStyle.ERROR, error?.message);
      }
    },
    [history, isEmbeddedModel, dispatch, isAuth]
  );

  const fetchModel = useCallback(
    async (modelId: string, isArMode?: boolean ): Promise<void> => {
      dispatch(setTotalLoadingSteps(6));
      dispatch(setIsViewerLoading(true));
      setIsModelLoading(true);
      try {
        dispatch(setLoadingState(ELoadingState.LOADING_MODEL));
        const modelSs = getModelFromSessionStorage();
        const model =
          modelSs || !!isArMode
            ? (await getARModel(modelId, signal, isEmbeddedModel)).data
            : (await getModel(modelId, signal, isEmbeddedModel)).data;
        dispatch(setLoadingState(ELoadingState.SETUP_BRANDING));
        if (!!model.brandingId) {
          const activeBranding = (await getCustomBrand(model.brandingId, signal)).data;
          dispatch(setBranding(activeBranding));
          convertBrandColorsToCSSVariables(activeBranding.brandColors);
        }
        dispatch(setIsBrandingLoading(false));
        dispatch(setLoadingState(ELoadingState.SETUP_MODEL_SETTINGS));
        const modelSettings: ModelSettings = await getModelSettings(
          model,
          signal,
          isTransparentBackground
        );
        const modelFileType: E3DModelFileTypes = FILE_TYPES[model.metadata.format];
        const modelMtls: string[] = model.content.reduce(
          (acc: string[], item): string[] =>
            MTL_FORMAT_REGEXP.test(item.ext) ? [...acc, item.publicUrl] : acc,
          []
        );
        dispatch(setModelSettings(modelSettings));
        dispatch(setModelFileType(modelFileType));
        dispatch(setModelMtls(modelMtls));
        await dispatch(setModel({ model, signal }));
        setModelToSessionStorage(model);
      } catch (e) {
        checkError(e);
        dispatch(setIsViewerLoading(false));
      } finally {
        dispatch(setIsBrandingLoading(false));
        setIsModelLoading(false);
      }
    },
    [dispatch, signal, isTransparentBackground, checkError]
  );

  return {
    fetchModel,
    isModelLoading
  };
};

export default useModelData;
