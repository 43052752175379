import { useCallback, useEffect, useState } from 'react';
import * as Styled from './styles';
import { useAppDispatch, useAppSelector, useFeatureAccess } from 'shared/hooks';
import { showModal } from 'services/store/reducers/modalReducer';
import { EPremiumFeature, EModelType } from 'shared/types';
import { ModalFeatureSignedIn, CommentsBar } from 'shared/components';
import { setIsCommentsVisible, setIsCommentsBarActive } from 'services/store/reducers/commentsReducer';
import { RootState } from 'services/store';
import { CommentIcon, ChevronIcon } from 'assets/dynamic-icons';
import { FEATURES } from 'shared/constants/features';
import LockIcon from 'assets/images/lock-icon.svg';

const FeaturesBlock: React.FC<{ sharePanelHeight?: number }> = ({ sharePanelHeight }): JSX.Element => {
  const dispatch = useAppDispatch();
  const store = useAppSelector((store): RootState => store);
  const { model, modelType } = store.viewerData;
  const { plainComments, isCommentsBarActive, isCommentsVisible } = store.comments;
  const { checkCommentAccess } = useFeatureAccess(model!);
  const [hasCommentAccess, setHasCommentAccess] = useState<boolean>(true);
  
  const isModelOwner = modelType === EModelType.OWNER;

  useEffect(() => {
    const checkAccess = async () => {
      const hasAccess = await checkCommentAccess();
      setHasCommentAccess(hasAccess);
    };
    checkAccess();
  }, [checkCommentAccess]);

  const handleToggleComments = useCallback(async (): Promise<void> => {
    const hasAccess = await checkCommentAccess();
    setHasCommentAccess(hasAccess);

    if(!hasAccess && isModelOwner) {
      dispatch(setIsCommentsBarActive(false));
      dispatch(setIsCommentsVisible(false));
      dispatch(showModal(<ModalFeatureSignedIn feature={EPremiumFeature.COMMENTS} />));
      return;
    }

    dispatch(setIsCommentsBarActive(!isCommentsBarActive));
    dispatch(setIsCommentsVisible(hasAccess && !isCommentsBarActive));  
  }, [checkCommentAccess, dispatch, isCommentsBarActive, isModelOwner]);

  return (
    <Styled.FeaturesBlockContainer $sharePanelHeight={sharePanelHeight}>
      <Styled.FeatureItem 
        onClick={handleToggleComments} 
        $isLocked={isModelOwner && !hasCommentAccess}
      >
        {isModelOwner && !hasCommentAccess && (
          <Styled.Lock>
            <img src={LockIcon} alt='Lock' />
          </Styled.Lock>
        )}

        <Styled.FeatureIconContainer>
          <CommentIcon />
        </Styled.FeatureIconContainer>

        <span id='comments-button'>Comments</span>

        <span className='comments-count'>
          {(plainComments.length <= 99999 ? plainComments.length : '99999+')}
        </span>

        <Styled.OpenCommentsButton>
          <Styled.ChevronIconWrapper $isActive={isCommentsBarActive} $isLocked={isModelOwner && !hasCommentAccess}>
            <ChevronIcon />
          </Styled.ChevronIconWrapper>
        </Styled.OpenCommentsButton>
      </Styled.FeatureItem>

      <Styled.CommentsContainer $isVisible={isCommentsBarActive}>
        {model && isCommentsBarActive && isCommentsVisible ? (
          <CommentsBar modelId={model.id} />  // TODO: This is re-spawned in SidebarModel.tsx
        ) : isCommentsBarActive ? (
          <Styled.DisabledMessageModal>
            {FEATURES[EPremiumFeature.COMMENTS].lockedMessage}
          </Styled.DisabledMessageModal>
        ) : null}
      </Styled.CommentsContainer>
    </Styled.FeaturesBlockContainer>
  );
};

export default FeaturesBlock;
