export const COLORS = {
  white: '#ffffff',
  black: '#030303',
  deepBlack: '#101010',
  codGray: '#0A0A0A',
  mischka: '#d6d7e3',
  red: '#ff0202',
  turbo: '#FFE406',
  gorse: '#FFEC51',
  corn: '#DAC308',
  mineShaft: '#303030',
  doveGray: '#6E6E6E',
  codGrayGradient: 'linear-gradient(117.57deg, #0a0a0a -0.48%, #0a0a0a 114.27%)',
  galleryGradient: 'linear-gradient(180deg, #ECECEC 20.59%, #C9C9C9 85.35%)',
  mineShaftGradient:
    'linear-gradient(0deg, #333333, #333333), linear-gradient(0deg, #333333, #333333), linear-gradient(0deg, #333333, #333333), #333333',
  charcoalGradient: 'linear-gradient(0deg, #1B1A1A 0%, #464646 83%)'
};
