import styled from 'styled-components';
import { DEVICE } from 'shared/constants/deviceSizes';
import { COLORS } from 'shared/constants/colors';

export const CustomizationPreviewContainer = styled.div`
  position: relative;
  height: calc(100vw * 0.36);
  max-width: 1400px;

  @media ${DEVICE.desktopLarge} {
    height: calc(100vw * 0.4);
  }

  @media ${DEVICE.tabletLarge} {
    height: calc(100vw * 0.5);
  }

  @media ${DEVICE.tablet} {
    height: calc(100vw * 0.6);
  }

  @media ${DEVICE.mobile} {
    height: 600px;
  }
`;

export const CustomizationPreview = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 7px;
  overflow: hidden;
  position: absolute;
`;

export const UIElementsContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 1;
`;

export const PreviewHeader = styled.header`
  display: flex;
  justify-content: space-between;
  height: 46px;
  min-height: 46px;
  width: 100%;
  border-radius: 7px 7px 0 0;
`;

export const HeaderLeftSide = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 10px;
  width: 100%;
  max-width: 340px;
  word-break: break-word;

  @media ${DEVICE.mobile} {
    max-width: 60%;
  }
`;

export const HeaderLogo = styled.img`
  max-width: 40px;
  max-height: 27px;
  object-fit: contain;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 7px;
`;

export const HeaderTitle = styled.h1`
  margin: 0;
  font-weight: 700;
  font-size: 13px;
  line-height: 13px;

  @media ${DEVICE.mobile} {
    font-size: 10px;
    line-height: 10px;
  }
`;

export const HeaderSubtitle = styled.h6`
  margin: 2px 0 0;
  font-weight: 400;
  font-size: 7px;
  line-height: 8px;
`;

export const HeaderRightSide = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const HeaderButton = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 14px;
`;

export const ButtonIcon = styled.div<{$buttonsColor: string; $buttonTextColor: string}>`
  width: 23px;
  height: 23px;
  fill: ${({$buttonsColor}): string => $buttonsColor};
  color: ${({$buttonTextColor}): string => $buttonTextColor};
`;

export const ButtonText = styled.span`
  margin-left: 6px;
  font-weight: 400;
  font-size: 9px;
  line-height: 9px;

  @media ${DEVICE.tablet} {
    display: none;
  }
`;

export const QuickViewHint = styled.div<{ $textColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  padding-top: 2px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.51);
  font-size: 6px;
  color: ${({ $textColor }): string => $textColor || COLORS.white};
  text-decoration: none;
  filter: drop-shadow(0px 0px 1px rgb(0 0 0 / 0.9));

  @media ${DEVICE.tablet} {
    display: none;
  }
`;

export const PreviewSidebar = styled.aside`
  height: calc(100% - 62px);
  width: 30px;
`;

export const SidebarIconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const SidebarIconWrapper = styled.div<{ $activeColor: string; $color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8px;
  height: 8px;
  color: ${({ $color }): string => $color};
  filter: drop-shadow(1px 1px 1px rgb(0 0 0 / 0.7));

  &.home-icon {
    color: ${({ $activeColor }): string => $activeColor};
  }

  &:not(:first-child) {
    margin-top: 20px;
  }

  &:first-child {
    margin-top: 10px;
  }
`;

export const PreviewFooter = styled.footer`
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 34px;
  border-radius: 0 0 7px 7px;
`;

export const PreviewFooterYear = styled.span`
  font-weight: 400;
  font-size: 5px;
  line-height: 6px;
`;

export const PreviewFooterMenu = styled.div`
  display: flex;
`;

export const PreviewFooterMenuItem = styled.span`
  font-weight: 400;
  font-size: 5px;
  line-height: 6px;

  &:first-child {
    margin-right: 10px;
  }
`;
