import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { FileWithPath, useDropzone } from 'react-dropzone';
import * as Styled from './styles';
import { ModelsSecure } from 'shared/components';
import { useAppDispatch, useAppSelector, useUploadFile } from 'shared/hooks';
import { stopLoader } from 'services/store/reducers/loaderReducer';
import { ETeamRole, Model, TeamsState } from 'shared/types';
import Plus from 'assets/images/plus-icon.svg';
import SecureIcon from 'assets/images/ip-secure-icon.svg';
import { showModal } from 'services/store/reducers/modalReducer';
import { ModelList } from 'shared/components';

type Props = {
  updateModelList: () => Promise<void>;
  resetModelList: () => Promise<void>;
  modelsData: Model[];
  switchPage: () => void;
};

const TeamModelsTab: React.FC<Props> = ({
  updateModelList,
  resetModelList,
  modelsData,
  switchPage
}): React.ReactElement => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { uploadModelToSpace } = useUploadFile();
  const { activeTeam, teamRole, isTeamLoaded } = useAppSelector((state): TeamsState => state.teams);
  const [isModelListLoaded, setIsModelListLoaded] = useState(false);
  const isInitTabVisit = useRef<boolean>(true);

  useLayoutEffect((): void => {
    isInitTabVisit.current = true;
    setIsModelListLoaded(false);
  }, [location]);

  useLayoutEffect((): void => {
    (async (): Promise<void> => {
      if (isInitTabVisit.current) {
        isInitTabVisit.current = false;
        await resetModelList();
        setIsModelListLoaded(true);
      }
    })();
  }, [resetModelList]);

  useEffect(
    (): (() => void) => (): void => {
      dispatch(stopLoader());
    },
    [dispatch]
  );

  const fetchModelsList = async (): Promise<void> => {
    await updateModelList();
  };

  const handleSecureIconClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    dispatch(showModal(<ModelsSecure />));
  };

  const onDrop = async (files: FileWithPath[]): Promise<void> => {
    if (!!activeTeam) {
      await uploadModelToSpace(files, { spaceTitle: activeTeam.teamName, spaceId: activeTeam.id });
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Styled.PageContent>
      {isModelListLoaded && isTeamLoaded && (
        <>
          {!!modelsData.length ? (
            <ModelList
              models={modelsData}
              fetchModelsList={fetchModelsList}
              isInfinite={true}
              switchPage={switchPage}
              isTeamPage={true}
              isLoaded={isModelListLoaded && isTeamLoaded}
            />
          ) : (
            <>
              {teamRole === ETeamRole.VIEWER ? (
                <Styled.NoDataBlock>There are no models in this workspace.</Styled.NoDataBlock>
              ) : (
                <Styled.UploadModelAria {...getRootProps()} $isDragActive={isDragActive}>
                  <input {...getInputProps()} />
                  <Styled.UploadFieldContent>
                    <Styled.PlusIcon src={Plus} alt='Upload' draggable={false} />
                    <Styled.UploadDescription>
                      <span>Drag and drop .GLB, .GLTF, .FBX, .OBJ or .STL file to upload</span>
                      <span>or select a file to upload</span>
                    </Styled.UploadDescription>
                  </Styled.UploadFieldContent>
                  <Styled.SecureIcon
                    src={SecureIcon}
                    onClick={handleSecureIconClick}
                    draggable={false}
                  />
                </Styled.UploadModelAria>
              )}
            </>
          )}
        </>
      )}
    </Styled.PageContent>
  );
};

export default TeamModelsTab;
