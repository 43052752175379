import React from 'react';
import * as Styled from './styles';
import { ModalSubtitle, ModalTitle } from 'shared/styles';
import Lock from 'assets/images/lock-icon.svg';
import { SubscriptionInfo } from 'shared/components';
import { EPremiumFeature } from 'shared/types';
import { FEATURES } from 'shared/constants/features';

type Props = {
  feature: EPremiumFeature;
};

const ModalFeatureSignedIn: React.FC<Props> = ({ feature }): JSX.Element => {
  return (
    <Styled.ModalContainer>
      <Styled.ModelTitleContainer>
        <ModalTitle>
          <Styled.TitleText>{FEATURES[feature].title} - Upgrade to Unlock</Styled.TitleText>
          <Styled.Lock>
            <img src={Lock} alt='Lock' />
          </Styled.Lock>
        </ModalTitle>
      </Styled.ModelTitleContainer>
      <Styled.ModalContent>
        <Styled.LeftSide>
          <ModalSubtitle>{FEATURES[feature].description}</ModalSubtitle>
          <Styled.FeatureGifContainer>
            <img src={FEATURES[feature].preview} alt={FEATURES[feature].title} />
          </Styled.FeatureGifContainer>
        </Styled.LeftSide>
        <Styled.RightSide>
          <SubscriptionInfo isModal />
        </Styled.RightSide>
      </Styled.ModalContent>
    </Styled.ModalContainer>
  );
};

export default ModalFeatureSignedIn;
