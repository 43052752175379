import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';
import styled from 'styled-components';

export const HeaderContainer = styled.header`
  position: fixed;
  display: flex;
  flex-direction: column;
  min-height: 82px;
  width: 100%;
  z-index: 1040;

  @media ${DEVICE.tablet} {
    min-height: 38px;
  }
`;

export const HeaderContent = styled.div<{ $backgroundColor: string }>`
  height: 82px;
  width: 100%;
  background-color: var(--header-background-color);
  padding: 0 38px 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s;

  @media ${DEVICE.tablet} {
    padding: 0 10px;
    height: 38px;
    background-color: ${({ $backgroundColor }): string => $backgroundColor || COLORS.deepBlack};
  }
`;

export const ProjectLogoContainer = styled.div<{ $isAuth: boolean }>`
  min-width: max-content;
  padding-right: 40px;

  @media ${DEVICE.tabletLarge} {
    display: ${({ $isAuth }): string => ($isAuth ? 'none' : 'block')};
  }

  @media ${DEVICE.tablet} {
    display: none;
  }
`;

export const MenuButton = styled.div`
  margin: 0 30px 0 15px;
  width: 30px;
  height: 30px;
  display: none;
  color: var(--button-background-color);
  filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);

  @media ${DEVICE.tabletLarge} {
    display: block;
  }

  @media ${DEVICE.tablet} {
    margin: 0 20px 0 10px;
    width: 22px;
    height: 22px;
  }
`;

export const HeaderActions = styled.nav<{ $isAuth: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;

  @media ${DEVICE.tabletLarge} {
    gap: 2px;

    .desktop-upload-button {
      display: ${({ $isAuth }: { $isAuth: boolean }): string => ($isAuth ? 'block' : 'none')};
    }
    .tablet-upload-button {
      display: ${({ $isAuth }: { $isAuth: boolean }): string => ($isAuth ? 'none' : 'block')};
    }
  }

  @media not ${DEVICE.tabletLarge} {
    .desktop-upload-button {
      display: block;
    }
    .tablet-upload-button {
      display: none;
    }
  }
`;

export const LogoMobileView = styled.img<{ $isAuth: boolean }>`
  display: none;
  max-width: 35px;
  max-height: 24px;
  object-fit: contain;
  position: ${({ $isAuth }): string => (!$isAuth ? 'initial' : 'absolute')};
  left: 50%;
  transform: translateX(${({ $isAuth }): string => (!$isAuth ? '0' : '-50%')});
`;

export const ShareArViewButton = styled.button`
  width: 90px;
  height: 28px;
  background-color: var(--button-background-color);
  border-radius: 3px;
  color: var(--button-text-color);
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  transition: all 0.3s;
  opacity: 0.9;

  &:hover {
    opacity: 1;
  }
`;

export const GuideButton = styled.button`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--button-background-color);
  color: var(--button-text-color);
  border-radius: 50%;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);

  @media ${DEVICE.tablet} {
    width: 24px;
    height: 24px;
    font-size: 16px;
    line-height: 16px;
  }
`;
