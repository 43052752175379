import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useStripe } from '@stripe/react-stripe-js';
import { FileWithPath, useDropzone } from 'react-dropzone';
import * as Styled from './styles';
import IPSecureIcon from 'assets/images/ip-secure-icon.svg';
import Plus from 'assets/images/plus-icon-black.svg';
import { ContentHeader } from 'shared/styles';
import {
  BillingTab,
  CustomizationTab,
  MembersTab,
  SettingsTab,
  SubscriptionTab,
  TeamModelsTab,
  UsageTab
} from './tabs';
import {
  useAppDispatch,
  useAppSelector,
  useScreenDragEvent,
  useTeamModelsData,
  useUploadFile
} from 'shared/hooks';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { usePageTopPositionContext } from 'shared/providers';
import {
  clearActiveTeam,
  fetchTeam,
  fetchTeamData,
  fetchUserTeams,
  setActiveTeamCard,
  setIsTeamLoaded
} from 'services/store/reducers/teamsReducer';
import {
  EConfirmModalHeader,
  EFeatureId,
  ESnackbarStyle,
  ETeamRole,
  SubscriptionsState,
  TeamsState,
  TeamWorkspacePageTab
} from 'shared/types';
import { NUMBER_REGEXP } from 'shared/constants/regexps';
import { ModalTeamCheckout, ModelsSecure, SearchSelect, SelectTab } from 'shared/components';
import ExclamationCircleFilled from '@ant-design/icons/lib/icons/ExclamationCircleFilled';
import { showConfirmModal, showModal } from 'services/store/reducers/modalReducer';
import { openNotification } from 'utils/notification-utils';
import { getCurrentTeamCard, updateTeamCard } from 'services/api/subscriptionsService';
import { withElements } from 'shared/hocs/withElements';
import {
  BillingIcon,
  CustomizationIcon,
  MembersIcon,
  SettingsIcon,
  SubscriptionIcon,
  TeamIcon,
  UsageIcon
} from 'assets/dynamic-icons';

type QueryParams = {
  teamId: string;
};

type TabData = {
  id: TeamWorkspacePageTab;
  title: string;
  icon: React.ReactElement;
  className?: string;
  isAvailable: boolean;
};

const TeamPage = (): React.ReactElement => {
  const location = useLocation();
  const params = useMemo(
    (): URLSearchParams => new URLSearchParams(location.search),
    [location.search]
  );
  const dispatch = useAppDispatch();
  const history = useHistory();
  const stripe = useStripe();
  const { teamId } = useParams<QueryParams>();
  const { teamRole, teams, teamPlan, activeTeam } = useAppSelector(
    (store): TeamsState => store.teams
  );
  const { subscriptionPlans } = useAppSelector((store): SubscriptionsState => store.subscriptions);
  const [activeTab, setActiveTab] = useState<TeamWorkspacePageTab>(
    TeamWorkspacePageTab.TEAM_MODELS
  );
  const { isDragActive } = useScreenDragEvent();
  const { uploadDroppedModelsToSpace } = useUploadFile();
  const {
    fetchModel,
    modelsData,
    setSearch,
    isSearchMode,
    setIsSearchMode,
    handlePageChange,
    resetModelList,
    updateModelList,
    clearTeamModelDataState
  } = useTeamModelsData();

  const { pageTop } = usePageTopPositionContext();

  useEffect((): void => {
    pageTop?.current?.scrollIntoView({ behavior: 'auto' });
  }, [pageTop, activeTab]);

  useEffect((): void => {
    setActiveTab(TeamWorkspacePageTab.TEAM_MODELS);
    clearTeamModelDataState();
  }, [clearTeamModelDataState, teamId]);

  useEffect(
    (): (() => void) => (): void => {
      dispatch(stopLoader());
      dispatch(clearActiveTeam());
    },
    [dispatch, teamId]
  );

  const clearParams = useCallback(
    (paramItems: string[]): void => {
      paramItems.forEach((item): void => params.delete(item));
      history.replace({ search: params.toString() });
    },
    [history, params]
  );

  useEffect((): void => {
    const paymentRedirectStatus = params.get('redirect_status');
    const setupIntentClientSecret = params.get('setup_intent_client_secret');
    const isCheckout = params.get('checkout');
    const planId = params.get('plan');
    const isMonthlyType = !!+(params.get('monthly') || '');
    if (!!stripe) {
      if (!!paymentRedirectStatus) {
        clearParams([
          'redirect_status',
          'setup_intent',
          'setup_intent_client_secret',
          'checkout',
          'plan',
          'monthly'
        ]);
      }
      if (paymentRedirectStatus === 'failed') {
        openNotification(ESnackbarStyle.ERROR, 'Payment failed');
        return;
      }
      (async (): Promise<void> => {
        dispatch(startLoader());
        try {
          if (paymentRedirectStatus === 'succeeded' && !!setupIntentClientSecret) {
            const { setupIntent } = await stripe.retrieveSetupIntent(setupIntentClientSecret);
            if (!!setupIntent) await updateTeamCard(+teamId, setupIntent.id);
            const currentCard = (await getCurrentTeamCard(+teamId)).data;
            dispatch(setActiveTeamCard(currentCard));
            const plan = subscriptionPlans.find(({ id }): boolean => id === planId);
            if (isCheckout && !!plan) {
              setActiveTab(TeamWorkspacePageTab.SUBSCRIPTION);
              dispatch(
                showConfirmModal({
                  header: EConfirmModalHeader.CHECKOUT,
                  content: <ModalTeamCheckout plan={plan} isMonthlyType={isMonthlyType} />
                })
              );
            }
            openNotification(ESnackbarStyle.SUCCESS, 'Your card has been successfully added');
          }
        } catch (e) {
          openNotification(ESnackbarStyle.ERROR, e?.message);
        } finally {
          dispatch(stopLoader());
        }
      })();
    }
  }, [clearParams, dispatch, params, stripe, subscriptionPlans, teamId]);

  useLayoutEffect((): void => {
    const goToNotFoundPage = (): void => {
      history.push('/not-found');
    };
    const loadTeamData = async (): Promise<void> => {
      await dispatch(fetchTeam({ teamId: +teamId, goToNotFoundPage }));
      await dispatch(fetchTeamData({ teamId: +teamId }));
      if (!teams.length) {
        await dispatch(fetchUserTeams());
      }
      dispatch(setIsTeamLoaded(true));
    };
    NUMBER_REGEXP.test(teamId) ? loadTeamData() : goToNotFoundPage();
  }, [dispatch, history, teamId, teams]);

  const handleTabClick =
    (tabId: number): (() => void) =>
    (): void => {
      setActiveTab(tabId);
    };

  const handleSettingsTabClick = (tabId: TeamWorkspacePageTab): void => {
    setActiveTab(tabId);
  };

  const switchPage = async (): Promise<void> => {
    await handlePageChange();
  };

  const MAIN_TABS: TabData[] = [
    {
      id: TeamWorkspacePageTab.TEAM_MODELS,
      title: 'Models',
      icon: <TeamIcon />,
      className: 'models-tab',
      isAvailable: true
    },
    {
      id: TeamWorkspacePageTab.USAGE,
      title: 'Usage',
      icon: <UsageIcon />,
      className: 'usage-tab',
      isAvailable: teamRole !== ETeamRole.VIEWER
    },
    {
      id: TeamWorkspacePageTab.MEMBERS,
      title: 'Members',
      icon: <MembersIcon />,
      className: 'members-tab',
      isAvailable: true
    },
    {
      id: TeamWorkspacePageTab.CUSTOMIZATION,
      title: 'Customization',
      icon: <CustomizationIcon />,
      className: 'customization-tab',
      isAvailable: !!teamPlan?.metadata.product_features.find(
        (item): boolean => item.id === EFeatureId.CUSTOM_UI_BRANDING
      )?.enabled
    }
  ];

  const SETTINGS_TABS: TabData[] = [
    {
      id: TeamWorkspacePageTab.SETTINGS,
      title: 'General',
      icon: <SettingsIcon />,
      isAvailable: teamRole === ETeamRole.OWNER || teamRole === ETeamRole.ADMIN
    },
    {
      id: TeamWorkspacePageTab.BILLING,
      title: 'Billing',
      icon: <BillingIcon />,
      isAvailable: teamRole === ETeamRole.OWNER || teamRole === ETeamRole.ADMIN
    },
    {
      id: TeamWorkspacePageTab.SUBSCRIPTION,
      title: 'Subscription',
      icon: <SubscriptionIcon />,
      isAvailable:
        !teamPlan?.metadata.enterprise_product &&
        (teamRole === ETeamRole.OWNER || teamRole === ETeamRole.ADMIN)
    }
  ];

  const TAB_CONTENT: Record<TeamWorkspacePageTab, React.ReactElement> = {
    [TeamWorkspacePageTab.TEAM_MODELS]: (
      <TeamModelsTab
        updateModelList={updateModelList}
        resetModelList={resetModelList}
        modelsData={modelsData}
        switchPage={switchPage}
      />
    ),
    [TeamWorkspacePageTab.USAGE]: <UsageTab />,
    [TeamWorkspacePageTab.MEMBERS]: <MembersTab />,
    [TeamWorkspacePageTab.CUSTOMIZATION]: <CustomizationTab />,
    [TeamWorkspacePageTab.BILLING]: <BillingTab />,
    [TeamWorkspacePageTab.SETTINGS]: <SettingsTab />,
    [TeamWorkspacePageTab.SUBSCRIPTION]: <SubscriptionTab />
  };

  const AVAILABLE_TABS = MAIN_TABS.filter(({ isAvailable }): boolean => isAvailable);
  const AVAILABLE_SETTINGS_TABS = SETTINGS_TABS.filter(({ isAvailable }): boolean => isAvailable);

  const handleSearchInputChange = useCallback(
    (value: string): void => {
      setSearch(value);
    },
    [setSearch]
  );

  const handleSearchListItemClick = async (modelId: string): Promise<void> => {
    await fetchModel(modelId);
  };

  const handleSecureIconClick = (): void => {
    dispatch(showModal(<ModelsSecure />));
  };

  const onDrop = async (files: FileWithPath[]): Promise<void> => {
    await uploadDroppedModelsToSpace(files, {
      spaceTitle: activeTeam!.teamName,
      spaceId: activeTeam!.id
    });
    await resetModelList();
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop, noClick: true });

  return (
    <Styled.PageContainer>
      <Styled.UploadModelOverlay
        {...getRootProps()}
        $isDragActive={
          isDragActive && (activeTab !== TeamWorkspacePageTab.TEAM_MODELS || !!modelsData.length)
        }
      >
        <input {...getInputProps()} id='team-space-drag-drop-input' />
        <Styled.PlusIcon src={Plus} alt='Upload model' />
        <Styled.UploadDescription>
          Release the file to upload it to this workspace
        </Styled.UploadDescription>
      </Styled.UploadModelOverlay>
      <Styled.PageContent>
        <ContentHeader>
          <Styled.HeaderContent>
            <Styled.HeaderTopContent>
              <Styled.WorkspaceName>{activeTeam?.teamName}</Styled.WorkspaceName>
            </Styled.HeaderTopContent>
            <Styled.HeaderBottomContent>
              <Styled.TabsContainer>
                {AVAILABLE_TABS.map(
                  ({ id, icon, title, className }): React.ReactElement => (
                    <Styled.PageTab
                      className={className}
                      $isActiveTab={id === activeTab}
                      onClick={handleTabClick(id)}
                      key={id}
                    >
                      <Styled.TabIconContainer className={className}>
                        {icon}
                      </Styled.TabIconContainer>
                      <span>{title}</span>
                    </Styled.PageTab>
                  )
                )}
                {!!AVAILABLE_SETTINGS_TABS.length && (
                  <Styled.SelectTabContainer>
                    <SelectTab
                      activeOption={AVAILABLE_SETTINGS_TABS.find(
                        (tab): boolean => tab.id === activeTab
                      )}
                      onChangeAction={handleSettingsTabClick}
                      options={AVAILABLE_SETTINGS_TABS}
                    />
                  </Styled.SelectTabContainer>
                )}
              </Styled.TabsContainer>
              {activeTab === TeamWorkspacePageTab.TEAM_MODELS && (
                <Styled.SearchSelectContainer>
                  <SearchSelect
                    inputAction={handleSearchInputChange}
                    listAction={handleSearchListItemClick}
                    options={isSearchMode ? modelsData.slice(0, 5) : []}
                    setIsSearchMode={setIsSearchMode}
                  />
                </Styled.SearchSelectContainer>
              )}
            </Styled.HeaderBottomContent>
          </Styled.HeaderContent>
        </ContentHeader>
        {TAB_CONTENT[activeTab]}
      </Styled.PageContent>
      {teamRole !== ETeamRole.VIEWER &&
        (activeTab !== TeamWorkspacePageTab.TEAM_MODELS || !!modelsData.length) && (
          <Styled.PageFooter>
            <Styled.DragDropMessageContainer>
              <ExclamationCircleFilled className='info-icon' />
              <Styled.DragDropMessage>
                Drag and drop model files anywhere into the window to save it to this workspace
              </Styled.DragDropMessage>
              <Styled.SecureIcon
                src={IPSecureIcon}
                onClick={handleSecureIconClick}
                alt='Security'
              />
            </Styled.DragDropMessageContainer>
          </Styled.PageFooter>
        )}
    </Styled.PageContainer>
  );
};

export default withElements(TeamPage);
