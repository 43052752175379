import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { FONTS } from 'shared/constants/fonts';
import { DEVICE } from 'shared/constants/deviceSizes';

export const FeaturesBlockContainer = styled.div<{ $sharePanelHeight?: number }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1010;
  bottom: 0;
  right: 0;
  width: 213px;
  // The 130px below accounts for the height of the header and FeatureItem.
  max-height: ${({ $sharePanelHeight }) => 
    $sharePanelHeight ? `calc(100vh - ${$sharePanelHeight}px - 130px)` : 'calc(100vh - 600px)'};
  color: ${COLORS.white};
`;

export const FeatureItem = styled.div<{ $isLocked?: boolean }>`
  height: 36px;
  border-radius: 4px 4px 0 0;
  padding: 0 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: all ease-in-out 0.15s;
  border-radius: 4px 0 0 0;
  padding: 0 0 0 12px;
  background: ${COLORS.deepBlack};
  flex-shrink: 0;

  & > .comments-count {
    padding: 0 12px;
    margin-left: auto;
    color: ${({ $isLocked }) => ($isLocked ? COLORS.doveGray : COLORS.white)};
  }

  span {
    color: ${({ $isLocked }) => ($isLocked ? COLORS.doveGray : COLORS.white)};
    font-size: 12px;
    line-height: 14px;
    font-family: ${FONTS.interRegular};
    margin-left: 10px;
  }

  &:hover {
    background: ${({ $isLocked }) => ($isLocked ? COLORS.deepBlack : 'var(--button-background-color)')};

    span {
      color: ${({ $isLocked }) => ($isLocked ? COLORS.doveGray : 'var(--button-text-color)')};
    }
  }

  @media${DEVICE.tabletLarge} {
    margin: 5px 0;
    justify-content: center;
  }
`;

export const FeatureIconContainer = styled.div<{ $isLocked?: boolean }>`
  width: 20px;
  height: 18px;
  color: var(--theme-secondary-color);
`;

export const OpenCommentsButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  img {
    width: 16px;
    height: 16px;
  }
`;

export const ChevronIconWrapper = styled.div<{ $isActive: boolean; $isLocked?: boolean }>`
  height: 26px;
  width: 26px;
  transition: rotate 300ms;
  rotate: ${({ $isActive }): string => ($isActive ? '0deg' : '180deg')};
  color: ${({ $isLocked }) => ($isLocked ? COLORS.doveGray : COLORS.white)};

  ${FeatureItem}:hover & {
    color: ${({ $isLocked }) => ($isLocked ? COLORS.doveGray : 'var(--button-text-color)')};
  }
`;

export const Lock = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  top: -10px;
  left: -8px;
  background: ${COLORS.turbo};
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 16px;
    height: 16px;
  }
`;

export const CommentsContainer = styled.div<{ $isVisible: boolean }>`
  display: ${({ $isVisible }): string => ($isVisible ? 'flex' : 'none')};
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  background: ${COLORS.deepBlack};
  border-radius: 0 0 4px 4px;
  overflow: hidden;
`;

export const DisabledMessageModal = styled.div`
  padding: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  text-align: center;
  background: ${COLORS.deepBlack};
`;
