import React from 'react';

const SignUpIcon = (): React.ReactElement => {
  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%'
        }}
        viewBox='0 0 42 42'
        preserveAspectRatio='xMaxYMax meet'
      >
        <g>
          <circle cx='22' cy='21' r='18'></circle>
        </g>
        <path
          fill='currentColor'
          d='M19.5 21.5a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5zm2.5-3.75a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zm5 10C27 29 25.75 29 25.75 29h-12.5S12 29 12 27.75s1.25-5 7.5-5 7.5 3.75 7.5 5zm-1.25-.005c-.001-.308-.192-1.233-1.04-2.08C23.895 24.85 22.361 24 19.5 24c-2.863 0-4.395.85-5.21 1.665-.847.848-1.037 1.773-1.04 2.08h12.5z'
        ></path>
        <path
          fill='currentColor'
          fillRule='evenodd'
          d='M28.875 17.75a.624.624 0 01.625.625v1.875h1.875a.624.624 0 110 1.25H29.5v1.875a.624.624 0 11-1.25 0V21.5h-1.875a.624.624 0 110-1.25h1.875v-1.875a.624.624 0 01.625-.625z'
          clipRule='evenodd'
        ></path>
        <defs>
          <filter
            id='filter0_d_2389_693'
            width='44'
            height='44'
            x='0'
            y='0'
            colorInterpolationFilters='sRGB'
            filterUnits='userSpaceOnUse'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
            <feColorMatrix
              in='SourceAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            ></feColorMatrix>
            <feOffset dy='1'></feOffset>
            <feGaussianBlur stdDeviation='2'></feGaussianBlur>
            <feComposite operator='out'></feComposite>
            <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.6 0'></feColorMatrix>
            <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_2389_693'></feBlend>
            <feBlend in='SourceGraphic' in2='effect1_dropShadow_2389_693' result='shape'></feBlend>
          </filter>
        </defs>
      </svg>
    </div>
  );
};

export default SignUpIcon;
