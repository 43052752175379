import React, { useRef, useState } from 'react';
import { AuthState, TeamsState } from 'shared/types';
import * as Styled from './styles';
import ArrowDownIcon from 'assets/images/arrow-down.svg';
import {
  useAppSelector,
  useBrowserStore,
  useHandleClickOutside,
  useUploadFile
} from 'shared/hooks';
import { LoginForm } from '../login-form';

type SpaceOption = {
  spaceTitle: string;
  spaceId: number;
};

type Props = {
  expandUp?: boolean;
};

const SaveModelBlock: React.FC<Props> = ({ expandUp }): JSX.Element => {
  const { getFilesFromBrowserStore } = useBrowserStore();
  const { teams } = useAppSelector((store): TeamsState => store.teams);
  const { uploadModelToMultipleSpaces } = useUploadFile();
  const [isMenuActive, setIsMenuActive] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const MY_MODELS_SPACE_ID = -1;
  const myModelsSpace: SpaceOption = {
    spaceTitle: 'My Workspace',
    spaceId: MY_MODELS_SPACE_ID
  };
  const [activeOptions, setActiveOptions] = useState<SpaceOption[]>([myModelsSpace]);
  const [isLoginFormOpened, setLoginFormOpened] = useState<boolean>(false);

  const teamSpaces: SpaceOption[] = teams
    .filter((team): boolean => team.status === 'active')
    .map(
      ({ teamName, id }): SpaceOption => ({
        spaceTitle: teamName,
        spaceId: id
      })
    );

  const OPTIONS: SpaceOption[] = [myModelsSpace, ...teamSpaces];

  const handleCheckboxClick =
    (checkedOption: SpaceOption): (() => void) =>
    (): void => {
      const hasOption = activeOptions.some(
        (option): boolean => option.spaceId === checkedOption.spaceId
      );
      const result = hasOption
        ? activeOptions.filter((option): boolean => option.spaceId !== checkedOption.spaceId)
        : [...activeOptions, checkedOption];
      setActiveOptions(result);
    };

  const toggleMenuActivity = (): void => {
    setIsMenuActive((state): boolean => !state);
  };

  const handleSaveClick = async (): Promise<void> => {
    getFilesFromBrowserStore(async (files: File[] | null): Promise<void> => {
      if (!!files) {
        await uploadModelToMultipleSpaces(files, activeOptions);
      }
    });
  };

  const ref = useHandleClickOutside((): void => setIsMenuActive(false));

  const { isAuth } = useAppSelector((store): AuthState => store.auth);


  return (
    <Styled.ActionsContainer>
      {(!isAuth && isLoginFormOpened) && <LoginForm/>}
      <Styled.SelectContainer $isMenuActive={isMenuActive} ref={ref}>
        <Styled.MainField
          $expandUp={!!expandUp}
          $isMenuActive={isMenuActive}
          onClick={toggleMenuActivity}
        >
          <div>
            {activeOptions.length === 0
              ? 'Select workspaces'
              : activeOptions.length === 1
                ? `Save to "${activeOptions[0].spaceTitle}"`
                : `Save to ${activeOptions.length} workspaces`}
          </div>
          <div className='select-arrow'>
            <img src={ArrowDownIcon} alt='Arrow' />
          </div>
        </Styled.MainField>
        <Styled.SelectMenuContainer $expandUp={!!expandUp} $isMenuActive={isMenuActive}>
          <Styled.SelectMenu $expandUp={!!expandUp} $isMenuActive={isMenuActive} ref={dropdownRef}>
            {OPTIONS.map((option): JSX.Element => {
              const isActive = activeOptions.some((o): boolean => o.spaceId === option.spaceId);
              return (
                <Styled.OptionItem
                  $isActive={isActive}
                  onClick={handleCheckboxClick(option)}
                  key={option.spaceId}
                >
                  <span>{option.spaceTitle}</span>
                  <Styled.Checkbox $isActive={isActive} />
                </Styled.OptionItem>
              );
            })}
          </Styled.SelectMenu>
        </Styled.SelectMenuContainer>
      </Styled.SelectContainer>
      <Styled.SaveButton onClick={() => {
        if(isAuth) {
          handleSaveClick();
        } else {
          setLoginFormOpened(true);
        }
      }} disabled={!activeOptions.length}>
        Save Model
      </Styled.SaveButton>
    </Styled.ActionsContainer>
  );
};

export default SaveModelBlock;
